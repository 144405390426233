import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import moment from 'moment/moment'
import lightTheme from '../../styles/theme/lightTheme'
import * as React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { faEdit, faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import { useSelector } from 'react-redux'
import './tables.css'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const TablePanelBox = styled(TabPanel)(({ theme }) => ({
  height: 280,
  backgroundColor: 'rgb(227, 230, 255, 0.5)',
  '& .MuiBox-root': {
    padding: 0,
  },
}))

const HeaderTab = styled(TableHead)(({ theme }) => ({
  height: 50,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const TitleHead = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const BodyTable = styled(TableBody)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: '2px solid rgb(227, 230, 255, 0.5)',
  fontFamily: 'Century Gothic',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const ButtonIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.primary.b2}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const DeleteIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.error.dark}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.error.dark}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const OffreTable = ({
  offres,
  type,
  handleRemoveAction,
  handleEditAction,
  handleDetailAction,
}) => {
  const { t } = useTranslation()
  const userData = useSelector((state) => state.userAuth.user)
  return (
    <TablePanelBox>
      {offres && (
        <Table>
          <HeaderTab>
            <TableRow>
              {/* <TitleHead>Date de saisi</TitleHead> */}
              <TitleHead>{t('profile.date')}</TitleHead>
              <TitleHead>{t('profile.hour')}</TitleHead>
              <TitleHead>Recruteur</TitleHead>
              {type === 'entreprise' && <TitleHead>Contact</TitleHead>}
              <TitleHead>Action</TitleHead>
              <TitleHead>Commentaire</TitleHead>
              <TitleHead
                sx={{
                  width:
                    userData.data.role === 'ROLE_ADMIN' ||
                    userData.data.role === 'ROLE_SUPER_ADMIN'
                      ? '150px'
                      : '70px',
                }}
              ></TitleHead>
            </TableRow>
          </HeaderTab>
          {offres &&
            offres.map((offre, i) => (
              <BodyTable key={i}>
                <TableRow sx={{ cursor: 'pointer' }} className="table-row">
                {/* <StyledTableCell
                    onClick={(e) => {
                      handleDetailAction(offre)
                      //e.target.parentNode.parentNode.parentNode.remove()
                    }}
                  >
                    {moment(offre.date).tz('Europe/Paris').format('DD/MM/YYYY H:mm')}
                  </StyledTableCell> */}
                  <StyledTableCell
                    onClick={(e) => {
                      handleDetailAction(offre)
                      //e.target.parentNode.parentNode.parentNode.remove()
                    }}
                  >
                    {offre.dateRealisation ? moment(offre.dateRealisation).tz('Europe/Paris').format('DD/MM/YYYY') : '--/--/---'}
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={(e) => {
                      handleDetailAction(offre)
                      //e.target.parentNode.parentNode.parentNode.remove()
                    }}
                  >
                    {offre.dateRealisation ? moment(offre.dateRealisation).tz('Europe/Paris').format('H:mm') : '--:--'}
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={(e) => {
                      handleDetailAction(offre)
                      //e.target.parentNode.parentNode.parentNode.remove()
                    }}
                  >
                    {offre.commercial?.nom} {offre.commercial?.prenom}
                  </StyledTableCell>
                  {type === 'entreprise' && (
                    <StyledTableCell
                      onClick={(e) => {
                        handleDetailAction(offre)
                        //e.target.parentNode.parentNode.parentNode.remove()
                      }}
                    >
                      {offre.contact?.nom} {offre.contact?.prenom}
                    </StyledTableCell>
                  )}
                  <StyledTableCell
                    onClick={(e) => {
                      handleDetailAction(offre)
                      //e.target.parentNode.parentNode.parentNode.remove()
                    }}
                  >
                    {offre.action}
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={(e) => {
                      handleDetailAction(offre)
                      //e.target.parentNode.parentNode.parentNode.remove()
                    }}
                  >
                    {offre.commentaire}
                  </StyledTableCell>
                  <StyledTableCell>
                    {/* <ButtonIcon
                        onClick={(e) => {
                          //handleDetailBesoin(besoin)
                          //e.target.parentNode.parentNode.parentNode.remove()
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                          color={lightTheme.palette.primary.b2}
                          fontSize={16}
                        />
                      </ButtonIcon> */}
                    <ButtonIcon
                      onClick={(e) => {
                        handleEditAction(offre)
                        //e.target.parentNode.parentNode.parentNode.remove()
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEdit}
                        color={lightTheme.palette.primary.b2}
                        fontSize={16}
                      />
                    </ButtonIcon>
                    {(userData.data.role === 'ROLE_ADMIN' ||
                      userData.data.role === 'ROLE_SUPER_ADMIN') && (
                      <DeleteIcon
                        onClick={(e) => {
                          handleRemoveAction(offre.id)
                          //e.target.parentNode.parentNode.parentNode.remove()
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          color={lightTheme.palette.error.dark}
                          fontSize={16}
                        />
                      </DeleteIcon>
                    )}
                  </StyledTableCell>
                </TableRow>
              </BodyTable>
            ))}
        </Table>
      )}
    </TablePanelBox>
  )
}

export default OffreTable
