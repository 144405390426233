import React, { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Modal,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  CheckboxInput,
  DatePickerInput,
  SearchInputBasic,
  SelectInputObject,
} from '../inputs'
import { BorderButton, CustomButton } from '../buttons'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons'
import AppPagination from '../paginations'
import { ListTableProspects } from '../tables'
import { debounce } from '../../config'
import ListTableCvtheque from '../tables/ListTableCvtheque'

const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  height: 680,
  backgroundColor: '#FFFFFF',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '25px',

  [theme.breakpoints.up('sm')]: {
    width: 730,
    height: 680,
    rowGap: '35px',
  },
  [theme.breakpoints.up('lg')]: {
    width: 1020,
    height: 680,
  },
}))

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const CvthequeModal = ({ open, onClose, besoin, onSave }) => {
  /*const [value, setValue] = useState<Dayjs | null>(dayjs());*/

  const [candidats, setCandidats] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const [page, setPage] = useState(
    sessionStorage.getItem('pageProspectCandidat')
      ? parseInt(sessionStorage.getItem('pageProspectCandidat'))
      : 1
  )
  const [count, setCount] = useState(0)
  const [filter, setFilter] = useState([
    { id: 0, titre: 'Nom, Prenom, Email ou Téléphone', filtre: 'search' },
    { id: 1, titre: 'Poste', filtre: 'candidat.poste.titre' },
    { id: 1, titre: 'Compétences techniques', filtre: 'candidat.competences' },
    { id: 2, titre: 'Softskills', filtre: 'candidat.softskils' },
    { id: 3, titre: 'Valeurs', filtre: 'candidat.valeurs' },
  ])
  const [filterSelect, setFilterSelect] = useState(filter[0])

  const [candidatSelected, setCandidatSelected] = useState([])

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearchQuery(value)
  }, 800)

  useEffect(() => {
    setPage(1)
    getCandidate(1)

    if (besoin?.candidats.length > 0) {
      console.log(
        besoin.candidats.map((candidat) => `/api/utilisateurs/${candidat.id}`)
      )
      setCandidatSelected(
        besoin.candidats.map((candidat) => `/api/utilisateurs/${candidat.id}`)
      )
    }
  }, [besoin?.id, searchQuery])

  const handleFilterSelect = (event) => {
    const inputValue = event.target.value
    setFilterSelect(inputValue)
  }

  const handlePageChange = (event, page) => {
    setPage(page)
    getCandidate(page)
  }

  const getCandidate = async (page) => {
    setLoading(true)

    let payload = {
      search: searchQuery,
    }
    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    if (filterSelect.id != 0) {
      payload = {
        [filterSelect.filtre]: searchQuery,
      }
    }

    try {
      const result = await Api.user.listWithPaginationAndFilterAndOrderByDate({
        role: 'ROLE_CANDIDAT',
        page: page,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setCandidats(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
        /* const sort = res.data.sort(( a, b) => ( a.date < b.date ? 1 : -1)); */
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  const handleClickToProfil = (userId) => {
    window.open(
      `/admin/gestion-prospect/profil-prospect-candidat/${userId}`,
      '_blank'
    )
  }

  const handleSelect = (event, id) => {
    const isChecked = event.target.checked
    const url = `/api/utilisateurs/${id}`

    if (isChecked) {
      // Add URL to array
      setCandidatSelected((prev) => [...prev, url])
    } else {
      // Remove URL from array
      setCandidatSelected((prev) => prev.filter((item) => item !== url))
    }
  }

  const onEdit = async () => {
    console.log(candidatSelected)
    setLoadingSave(true)
    try {
      const result = await Api.besoinEntreprise.edit(besoin.id, {
        candidats: candidatSelected,
      })
      const handling = handlingErrors(result)

      if (handling.ok) {
        const res = handling.data
        setLoadingSave(false)
        onSave()
      } else {
        setLoadingSave(false)
      }
    } catch (error) {
      console.debug('erreur submit !')
    }
  }

  return (
    <div>
      <Modal open={open} onClose={onClose} maxWidth="lg" fullWidth={true}>
        <ModalContainer>
          <Typography
            variant={'subtitle2'}
            sx={{
              textTransform: 'uppercase',
              textAlign: 'center',
            }}
          >
            Liste des candidats
          </Typography>

          <WrapperButton sx={{ columnGap: '15px' }}>
            <Grid item>
              <SearchInputBasic onChange={handleSearchChange} />
            </Grid>
            <Grid item>
              <SelectInputObject
                items={filter}
                value={filterSelect}
                handleChange={handleFilterSelect}
              />
            </Grid>
          </WrapperButton>

          <Box
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <ListTableCvtheque
              type={'candidat'}
              items={candidats}
              loading={loading}
              handleClick={handleClickToProfil}
              handleSelect={handleSelect}
              candidatSelected={candidatSelected}
            />

            <AppPagination
              handlePageChange={handlePageChange}
              count={count}
              page={page}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              columnGap: '15px',
            }}
          >
            {loadingSave ? (
              <CircularProgress />
            ) : (
              <>
                <BorderButton
                  type={'basic'}
                  displayIconLeft={'none'}
                  displayIconRight={'none'}
                  width={{ xs: 140, sm: 200 }}
                  handleClick={onClose}
                >
                  Annuler
                </BorderButton>

                <CustomButton
                  displayIcon={'none'}
                  width={{ xs: 140, md: 200 }}
                  handleClick={onEdit}
                >
                  Matcher
                </CustomButton>
              </>
            )}
          </Box>
        </ModalContainer>
      </Modal>
    </div>
  )
}

export default CvthequeModal
