import ApiRequest from '../request/ApiRequest'

export default class Documentation {
  constructor() {
    this.ApiRequest = new ApiRequest()
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post('documentations/ajout', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async list() {
    try {
      const res = await this.ApiRequest.get('documentations')
      return res
    } catch (error) {
      return error.response
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`documentations/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`documentations/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`documentations/${id}`)
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get(
        'documentations.jsonld',
        data,
        'jsonld'
      )
      return res
    } catch (error) {
      return error.response
    }
  }
}
