import React, { useState } from 'react'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import AppPagination from '../paginations'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'

const HeaderTab = styled(TableHead)(({ theme }) => ({
  height: 60,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const TitleTab = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
}))

const BodyTab = styled(TableCell)(({ theme, type }) => ({
  borderBottom:
    type === 'entreprise'
      ? `1px solid ${lightTheme.palette.secondary.main}`
      : type === 'candidat'
      ? `1px solid ${lightTheme.palette.warning.dark}`
      : `1px solid ${lightTheme.palette.secondary.main}`,
}))

const BlockTabCircle = styled(TableCell)(({ theme }) => ({
  width: 25,
  height: 61,
  backgroundColor: lightTheme.palette.secondary.main,
  padding: 0,
  margin: 0,
  borderBottom: `1px solid ${lightTheme.palette.secondary.main}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '5px',

  '.firstCircle, .secondCircle, .thirdCircle, .fourthCircle, .fiveCircle': {
    width: 5,
    height: 5,
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
  },
}))

const DeleteIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.error.dark}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.error.dark}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const BlockTabSquare = styled(TableCell)(({ theme }) => ({
  width: 25,
  height: 61,
  backgroundColor: lightTheme.palette.warning.dark,
  padding: 0,
  margin: 0,
  borderBottom: `1px solid ${lightTheme.palette.warning.dark}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '5px',

  '.firstSquare, .secondSquare, .thirdSquare, .fourthSquare, .fiveSquare': {
    width: 5,
    height: 5,
    backgroundColor: '#FFFFFF',
  },
}))

const BlockTab = styled(TableCell)(({ theme, type }) => ({
  width: 15,
  height: 60,
  padding: 0,
  backgroundColor:
    type === 'entreprise'
      ? lightTheme.palette.secondary.main
      : type === 'candidat'
      ? lightTheme.palette.warning.dark
      : lightTheme.palette.secondary.main,
  borderBottom:
    type === 'entreprise'
      ? `1px solid ${lightTheme.palette.secondary.main}`
      : type === 'candidat'
      ? `1px solid ${lightTheme.palette.warning.dark}`
      : `1px solid ${lightTheme.palette.secondary.main}`,
}))

const ButtonIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.primary.b2}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const EmptyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic',
  fontSize: 14,
  lineHeight: '140%',
  color: lightTheme.palette.grey.main,
  textAlign: 'center',
  fontStyle: 'italic',
  padding: '15px 0',
  background: 'rgba(0, 0, 0, 0.04)',
}))

const ListTableProspects = ({
  type,
  items,
  setId,
  loading,
  handleClick,
  handleRemove,
  showDeleteButton = true,
}) => {
  const userData = useSelector((state) => state.userAuth.user)

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
          borderRadius: 0,
        }}
      >
        <Table>
          <HeaderTab>
            <TableRow>
              <TableCell width={25} sx={{ padding: 0 }} />
              {type === 'entreprise' ? (
                <>
                  <TitleTab>Entreprise</TitleTab>
                  <TitleTab sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    Mail
                  </TitleTab>
                  <TitleTab sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    Date
                  </TitleTab>
                </>
              ) : (
                <>
                  <TitleTab>Nom</TitleTab>
                  <TitleTab>Prénom</TitleTab>
                  <TitleTab>Poste</TitleTab>
                  <TitleTab sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    Mail
                  </TitleTab>
                  <TitleTab sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    Date
                  </TitleTab>
                </>
              )}

              <TableCell />
              <TableCell width={15} sx={{ padding: 0 }} />
            </TableRow>
          </HeaderTab>
          <TableBody>
            {items?.length > 0 && (
              <>
                {items.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ position: 'relative', cursor: 'pointer' }}
                    className="table-row"
                  >
                    {type === 'entreprise' ? (
                      <>
                        <BlockTabCircle>
                          <div className={'firstCircle'} />
                          <div className={'secondCircle'} />
                          <div className={'thirdCircle'} />
                          <div className={'fourthCircle'} />
                          <div className={'fiveCircle'} />
                        </BlockTabCircle>
                        <BodyTab type={type}>
                          {item.entreprise.raisonSocial}
                        </BodyTab>
                        {/* <BodyTab type={type}>{item.nom}</BodyTab>
                        <BodyTab type={type}>{item.prenom}</BodyTab> */}
                        <BodyTab
                          type={type}
                          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                        >
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {item.email.startsWith('email@') ? '' : item.email}
                          </Typography>
                        </BodyTab>
                        <BodyTab type={type}>
                          {moment(item.date)
                            .tz('Europe/Paris')
                            .format('DD/MM/YYYY') +
                            ' ' +
                            moment(item.date)
                              .tz('Europe/Paris')
                              .format('HH:mm')}
                        </BodyTab>
                      </>
                    ) : (
                      <>
                        <BlockTabSquare
                          onClick={() => {
                            setId(item.id)
                            handleClick(`${item.id}`)
                          }}
                        >
                          <div className={'firstSquare'} />
                          <div className={'secondSquare'} />
                          <div className={'thirdSquare'} />
                          <div className={'fourthSquare'} />
                          <div className={'fiveSquare'} />
                        </BlockTabSquare>
                        <BodyTab
                          type={type}
                          onClick={() => {
                            setId(item.id)
                            handleClick(`${item.id}`)
                          }}
                        >
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {/* <a
                              href={`/admin/gestion-prospect/profil-prospect-candidat/${item.id}`}
                            > */}
                              {item.nom}
                          {/*   </a> */}
                          </Typography>
                        </BodyTab>
                        <BodyTab
                          type={type}
                          onClick={() => {
                            setId(item.id)
                            handleClick(`${item.id}`)
                          }}
                        >
                         {/*  <a
                            href={`/admin/gestion-prospect/profil-prospect-candidat/${item.id}`}
                          > */}
                            {item.prenom}
                         {/*  </a> */}
                        </BodyTab>
                        <BodyTab
                          type={type}
                          onClick={() => {
                            setId(item.id)
                            handleClick(`${item.id}`)
                          }}
                        >
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {item.candidat.poste && (
                              <>{item.candidat.poste.titre}</>
                            )}
                          </Typography>
                        </BodyTab>
                        <BodyTab
                          type={type}
                          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                          onClick={() => {
                            setId(item.id)
                            handleClick(`${item.id}`)
                          }}
                        >
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {item.email}
                          </Typography>
                        </BodyTab>
                        <BodyTab
                          type={type}
                          onClick={() => {
                            setId(item.id)
                            handleClick(`${item.id}`)
                          }}
                        >
                          {moment(item.date)
                            .tz('Europe/Paris')
                            .format('DD/MM/YYYY') +
                            ' ' +
                            moment(item.date)
                              .tz('Europe/Paris')
                              .format('HH:mm')}
                        </BodyTab>
                      </>
                    )}

                    <BodyTab
                      type={type}
                      sx={{
                        padding: '10px',
                        width:
                          userData.data.role === 'ROLE_ADMIN' ||
                          userData.data.role === 'ROLE_SUPER_ADMIN'
                            ? '70px'
                            : '0',
                      }}
                    >
                      {/*  <ButtonIcon
                        onClick={() => {
                          setId(item.id)
                          handleClick(`${item.id}`)
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                          color={lightTheme.palette.primary.b2}
                          fontSize={16}
                        />
                      </ButtonIcon> */}
                      {showDeleteButton &&
                        (userData.data.role === 'ROLE_ADMIN' ||
                          userData.data.role === 'ROLE_SUPER_ADMIN') && (
                          <DeleteIcon
                            onClick={(e) => {
                              handleRemove(item.id)
                              //e.target.parentNode.parentNode.parentNode.remove()
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              color={lightTheme.palette.error.dark}
                              fontSize={16}
                            />
                          </DeleteIcon>
                        )}
                    </BodyTab>
                    <BlockTab type={type} />
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
        {!items?.length > 0 && (
          <EmptyText>
            pas {type === 'entreprise' ? " d'entreprises" : 'de candidats'}{' '}
            ici...
          </EmptyText>
        )}
      </TableContainer>

      {/*<AppPagination setProducts={(p) => setProducts(p)}/>*/}
    </>
  )
}

export default ListTableProspects
