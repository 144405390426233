import { AdminHeading } from '../../../../../components/headings'
import { Box, FormControlLabel, FormGroup, Switch } from '@mui/material'
import { SearchInputBasic } from '../../../../../components/inputs'
import { ListTableProspects } from '../../../../../components/tables'
import { SwitchButton } from '../../../../../components/buttons'
import React, { useEffect, useState } from 'react'
import Api from '../../../../../services/api.service'
import { handlingErrors } from '../../../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../../../styles/theme/lightTheme'
import Profile from '../../../../Profile'
import { useNavigate } from 'react-router-dom'
import { DeleteProfilModal } from '../../../../../components/modals'

const DemandeRdv = () => {
  let navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [rdvProspectCompanies, setRdvProspectCompanies] = useState([])
  const [rdvProspectCandidates, setRdvProspectCandidates] = useState([])
  const [id, setId] = useState(null)
  const [role, setRole] = useState(null)
  const [openCard, setOpenCard] = useState('')
  const [isCheckedCompanie, setIsCheckedCompanie] = useState(false)
  const [isCheckedCandidate, setIsCheckedCandidate] = useState(true)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [userId, setUserId] = useState()
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)
  const handleConfirmDeleteModal = () => confirmRemoveProfil()

  useEffect(() => {
    //switchToggleCompanie();
    //switchToggleCandidate();
  }, [])

  const switchToggleCandidate = () => {
    setIsCheckedCandidate(false)
    setIsCheckedCompanie(true)
  }

  const switchToggleCompanie = () => {
    setIsCheckedCompanie(false)
    setIsCheckedCandidate(true)
  }

  useEffect(() => {
    getRdvProspectCompanies()
  }, [id, role, searchQuery])

  const getRdvProspectCompanies = async () => {
    setLoading(true)
    setRole('ROLE_ENTREPRISE')

    let payload = {
      search: searchQuery,
    }
    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

    try {
      const result = await Api.user.listWithFilter({
        role: 'ROLE_ENTREPRISE',
        status: 'contact',
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setRdvProspectCompanies(res.data)

        //const sort = res.data.sort(( a, b) => ( a.date < b.date ? 1 : -1));
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  const confirmRemoveProfil = async () => {
    setLoadingDelete(true)
    try {
      const result = await Api.user.delete(userId)

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setOpenDeleteModal(false)
        getRdvProspectCompanies()
        setLoadingDelete(false)
      }

      if (res.status === 0) {
        getRdvProspectCompanies()
        setLoadingDelete(false)
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      getRdvProspectCompanies()
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
  }

  const handleRemoveProfil = (idUser) => {
    setOpenDeleteModal(true)
    setUserId(idUser)
  }

  useEffect(() => {
    getRdvProspectCandidate()
  }, [id, role, searchQuery])

  const getRdvProspectCandidate = async () => {
    setLoading(true)
    setRole('ROLE_CANDIDAT')

    let payload = {
      search: searchQuery,
    }
    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

    try {
      const result = await Api.user.listWithFilter({
        role: 'ROLE_CANDIDAT',
        status: 'contact',
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setRdvProspectCandidates(res.data)

        //const sort = res.data.sort(( a, b) => ( a.date < b.date ? 1 : -1));
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  const handleClickToProfil = (userId) => {
    navigate(`/admin/gestion-prospect/profil-prospect-candidat/${userId}`)
  }

  const handleClickToProfilCompany = (userId) => {
    navigate(`/admin/gestion-prospect/profil-entreprise/${userId}`)
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        {!openCard ? (
          <>
            <AdminHeading displayIconReturn={'none'}>
              Demandes de rendez-vous découverte
            </AdminHeading>
            <Box
              className={'BoxContainer'}
              sx={{
                padding: '30px',
                display: 'flex',
                flexDirection: 'column',
                rowGap: { xs: '30px', sm: '55px', lg: '70px' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <SearchInputBasic />

                <FormGroup sx={{ flexDirection: 'row' }}>
                  <SwitchButton
                    label={'Professionnel'}
                    defaultChecked={isCheckedCompanie}
                    onChange={switchToggleCompanie}
                  />
                  <SwitchButton
                    label={'Candidat'}
                    defaultChecked={isCheckedCandidate}
                    onChange={switchToggleCandidate}
                  />
                </FormGroup>
              </Box>

              {isCheckedCompanie && (
                <ListTableProspects
                  type={'entreprise'}
                  items={rdvProspectCompanies}
                  setId={setId}
                  loading={loading}
                  handleClick={handleClickToProfilCompany}
                  openCard={openCard}
                  handleRemove={handleRemoveProfil}
                />
              )}

              {isCheckedCandidate && (
                <ListTableProspects
                  type={'candidat'}
                  items={rdvProspectCandidates}
                  setId={setId}
                  loading={loading}
                  handleClick={handleClickToProfil}
                  openCard={openCard}
                  handleRemove={handleRemoveProfil}
                />
              )}
            </Box>
          </>
        ) : (
          <Profile id={id} role={role} />
        )}
      </div>
      <DeleteProfilModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteModal}
        loading={loadingDelete}
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default DemandeRdv
