import React, { useEffect, useState } from 'react'
import { AdminHeading } from '../../../components/headings'
import { Box, styled, Typography } from '@mui/material'
import { BorderButtonPurple } from '../../../components/buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons'
import {
  faCircleCheck,
  faCircleExclamation,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons'
import '../../../styles/components/_layouts.scss'
import { SearchInputBasic } from '../../../components/inputs'
import { ListAdmin } from '../../../components/tables'
import { useNavigate } from 'react-router-dom'
import {
  AddPoste,
  DeleteGeneralModal,
  DeleteProfilModal,
  EditProfilModal,
} from '../../../components/modals'
import ListePostes from '../../../components/tables/ListePostes'
import Api from '../../../services/api.service'
import { handlingErrors } from '../../../utils'
import { toast } from 'react-toastify'
import lightTheme from '../../../styles/theme/lightTheme'
import { debounce } from '../../../config'
import AppPagination from '../../../components/paginations'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const BlockButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '15px',
  alignItems: 'center',
}))

const GestionDatas = () => {
  let navigate = useNavigate()
  const [openAddModal, setOpenAddModal] = React.useState(false)
  const [openEditModal, setOpenEditModal] = React.useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [id, setId] = useState(null)
  const [poste, setPoste] = useState(null)
  const [postes, setPostes] = useState([])
  const [openCard, setOpenCard] = useState('')
  const handleOpenAddModal = () => setOpenAddModal(true)
  const handleCloseAddModal = () => {
    setOpenAddModal(false)
    setOpenEditModal(false)
  }
  const handleOpenEditModal = () => setOpenEditModal(true)
  const handleCloseEditModal = () => setOpenEditModal(false)
  const handleOpenDeleteModal = () => setOpenDeleteModal(true)
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)
  const handleConfirmDeleteModal = () => removePoste()
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [loadingDelete, setLoadingDelete] = useState(false)

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearchQuery(value)
  }, 800)

  useEffect(() => {
    getPostes(1)
  }, [searchQuery])

  const getPostes = async (page) => {
    let payload = {
      search: searchQuery,
    }
    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

    setLoading(true)

    try {
      const result = await Api.poste.listWithFilterAndPage({
        status: true,
        page: page,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        //setPostes(res.data)
        setPostes(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
        /* const sort = res.data.sort((a, b) => (a.date < b.date ? 1 : -1)) */
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  const handlePageChange = (event, page) => {
    setPage(page)
    getPostes(page)
  }

  const refresh = () => {
    getPostes(1)
  }

  const removePoste = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.poste.delete(id)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        setOpenDeleteModal(false)
        getPostes(page)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <AdminHeading handleClickIcon={() => navigate('/admin')}>
          Gestion des données (Postes)
        </AdminHeading>

        {/*<Box
          className={"BoxContainer"}
          sx={{
            height: 75,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px 30px"
          }}
        >
          <Typography variant={"subtitle2"}>QUELLE LISTE DE DONNÉES SOUHAITEZ VOUS VISUALISER ?</Typography>
          select input
        </Box>*/}

        <Box
          className={'BoxContainer'}
          sx={{
            padding: '30px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '40px', sm: '45px' },
            /*marginTop: "25px"*/
            marginTop: '95px',
          }}
        >
          <WrapperButton sx={{ columnGap: '15px' }}>
            <SearchInputBasic onChange={handleSearchChange} />
            <BlockButton>
              <BorderButtonPurple
                width={{ xs: 45, sm: 230, lg: 350 }}
                displayText={{ xs: 'none', sm: 'block' }}
                handleClick={() =>
                  navigate('/admin/gestion-donnees/environnements')
                }
              >
                Gestion des environnements techniques
              </BorderButtonPurple>
            </BlockButton>
            <BlockButton>
              <BorderButtonPurple
                width={{ xs: 45, sm: 230, lg: 265 }}
                displayText={{ xs: 'none', sm: 'block' }}
                icon={<FontAwesomeIcon icon={faCirclePlus} fontSize={15} />}
                handleClick={handleOpenAddModal}
              >
                Ajouter un poste
              </BorderButtonPurple>
            </BlockButton>
          </WrapperButton>
          <ListePostes
            items={postes}
            loading={loading}
            handleClick={handleOpenDeleteModal}
            handleEdit={handleOpenEditModal}
            setId={setId}
            setPoste={setPoste}
            openCard={openCard}
          />
          <AppPagination
            handlePageChange={handlePageChange}
            count={count}
            page={page}
            pageSize={30}
          />
        </Box>
      </div>

      {openAddModal && (
        <AddPoste
          open={openAddModal}
          onClose={handleCloseAddModal}
          refresh={refresh}
          poste={null}
        />
      )}

      {openEditModal && (
        <AddPoste
          open={openEditModal}
          onClose={handleCloseAddModal}
          refresh={refresh}
          poste={poste}
        />
      )}

      <DeleteGeneralModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteModal}
        loading={loadingDelete}
      />
    </>
  )
}

export default GestionDatas
