import React, { useEffect, useState } from 'react'
import {
  MasonryLayout,
  MasonryLayoutCompany,
} from '../../../../components/layouts'
import { Box, CircularProgress, styled, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMapMarker,
  faEnvelope,
  faPhone,
  faArrowLeft,
  faPen,
  faCircleExclamation,
  faHome,
  faCircleCheck,
} from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../../styles/theme/lightTheme'
import {
  ChoiceOfferModal,
  EditDocuments,
  EditEvent,
  EditInfos,
  EditList,
} from '../../../../components/modals'
import {
  BiBlockTable,
  GedTable,
  OffreTable,
} from '../../../../components/tables'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { Slide, ToastContainer, toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import moment from 'moment-timezone'
import EditAdresseEntreprise from '../../../../components/modals/EditAdresseEntreprise'
import DeleteGeneralModal from '../../../../components/modals/DeleteGeneralModal'
import EditCommentaire from '../../../../components/modals/EditCommentaire'
import { VALEURS, prioriteEntrepriselList } from '../../../../data'
import DetailAdresseEntreprise from '../../../../components/modals/DetailAdresseEntreprise'
import AdresseTable from '../../../../components/tables/AdresseTable'
import {
  faComment,
  faLevelUp,
  faPenAlt,
  faPenCircle,
} from '@fortawesome/pro-solid-svg-icons'
import DetailOfferModal from '../../../../components/modals/DetailOfferModal'
import ContactTable from '../../../../components/tables/ContactTable'
import EditContactEntreprise from '../../../../components/modals/EditContactEntreprise'
import EditInfosContact from '../../../../components/modals/EditInfosContact'
import BesoinTable from '../../../../components/tables/BesoinTable'
import EditBesoinEntreprise from '../../../../components/modals/EditBesoinEntreprise'
import DetailBesoinEntreprise from '../../../../components/modals/DetailBesoinEntreprise'

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const TitleSection = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.primary.b2,
  textTransform: 'uppercase',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  width: 80,
  height: 30,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    borderWidth: '2px',
  },

  '&:active': {
    borderWidth: '1px',
  },
}))

const ProfilContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '45px',
  justifyContent: 'space-between',
}))

const WrapperProfilText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: '5px',
}))

const BannerProfil = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: 40,
  backgroundColor: lightTheme.palette.primary.b6,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  textAlign: 'center',
}))

const WrapperBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const BannerProfilContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const CompanyProfil = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [openDoc, setOpenDoc] = useState(false)
  const [openAdresse, setOpenAdresse] = useState(false)
  const [openContact, setOpenContact] = useState(false)
  const [openDetailAdresse, setOpenDetailAdresse] = useState(false)
  const [openCommentaire, setOpenCommentaire] = useState(false)
  const [openValeurs, setOpenValeurs] = useState(false)
  const [loading, setLoading] = useState(true)
  const [dateRdv, setDateRdv] = useState([])
  const [snapShot, setSnapShot] = useState(null)
  const dispatch = useDispatch()
  const [content, setContent] = useState([])
  const [adresses, setAdresses] = useState([])
  const [contacts, setContacts] = useState([])
  const [
    contactsEnvironnementsTechniques,
    setContactsEnvironnementsTechniques,
  ] = useState([])
  const [adresse, setAdresse] = useState()
  const [contact, setContact] = useState()
  const [commentaire, setCommentaire] = useState([])
  const [role, setRole] = useState('')
  const [isFile, setIsFile] = useState([])
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    //fetchData()
  }
  const handleOpenDoc = () => setOpenDoc(true)
  const handleCloseDoc = () => {
    setOpenDoc(false)
    //fetchData()
  }
  const handleOpenValeurs = () => setOpenValeurs(true)
  const handleCloseValeurs = () => {
    setOpenValeurs(false)
    //fetchData()
  }
  const handleOpenCommentaire = () => setOpenCommentaire(true)
  const handleCloseCommentaire = () => {
    setOpenCommentaire(false)
    //fetchData()
  }
  const handleOpenAdresse = () => {
    setOpenAdresse(true)
    setAdresse('')
  }
  const handleOpenContact = () => {
    setOpenContact(true)
    setContact('')
  }
  const handleCloseAdresse = () => {
    setOpenAdresse(false)
    //fetchData()
  }
  const handleCloseContact = () => {
    setOpenContact(false)
    //fetchData()
  }
  const handleCloseDetailAdresse = () => {
    setOpenDetailAdresse(false)
    //fetchData()
  }
  const handleSaveAdresse = () => {
    setOpenAdresse(false)
    setOpenDetailAdresse(false)
    getAdressesCompany(snapShot.entreprise?.id, page)
  }
  const handleSaveContact = () => {
    setOpenContact(false)
    getContactsCompany(snapShot.entreprise?.id, page)
  }
  const params = useParams()
  const handleRemoveAdresse = (idAdresse) => {
    setOpenDeleteAdresseModal(true)
    setAdresseId(idAdresse)
  }
  const handleRemoveContact = (idContact) => {
    setOpenDeleteContactModal(true)
    setContactId(idContact)
  }
  const handlePageChange = (event, page) => {
    setPage(page)
    getAdressesCompany(snapShot.entreprise?.id, page)
  }

  const handlePageChangeContact = (event, page) => {
    setPageContact(page)
    getContactsCompany(snapShot.entreprise?.id, page)
  }

  const handlePageChangeBesoin = (event, page) => {
    setPageBesoin(page)
    getBesoinsCompany(snapShot.entreprise?.id, page)
  }
  const handleEditAdresse = (adresse) => {
    setOpenAdresse(true)
    setAdresse(adresse)
  }
  const handleEditContact = (contact) => {
    setOpenContact(true)
    setContact(contact)
  }
  const handleDetailAdresse = (adresse) => {
    setOpenDetailAdresse(true)
    setAdresse(adresse)
  }
  const handleRemove = (idFile) => {
    setOpenDeleteModal(true)
    setFileDelete(idFile)
  }
  const handleOpenOffer = () => setOpenOffre(true)
  const handleCloseOffer = () => {
    setOpenOffre(false)
    //fetchData()
  }

  const handleSave = () => {
    fetchData()
    setOpen(false)
    setOpenDoc(false)
    setOpenAdresse(false)
    setOpenCommentaire(false)
    setOpenValeurs(false)
    setOpenOffre(false)
    setOpenAction(false)
    setOpenDetailAction(false)
    setAction()
  }

  const [openAction, setOpenAction] = useState(false)
  const [action, setAction] = useState()
  const [openDeleteActionModal, setOpenDeleteActionModal] = useState(false)
  const [openDetailAction, setOpenDetailAction] = useState(false)
  const handleCloseDeleteActionModal = () => setOpenDeleteActionModal(false)
  const handleConfirmDeleteActionModal = () => removeAction()
  const handleEditAction = (action) => {
    setAction(action)
    setOpenAction(true)
  }
  const handleDetailAction = (action) => {
    setAction(action)
    setOpenDetailAction(true)
  }
  const handleRemoveAction = (action) => {
    setAction(action)
    setOpenDeleteActionModal(true)
  }
  const handleCloseAction = () => {
    setOpenDetailAction(false)
    setAction()
  }
  const handleCloseEditAction = () => {
    setOpenAction(false)
    //setAction()
  }

  const removeAction = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.action.delete(action)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        fetchData()
        setOpenDeleteActionModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
    setAdresseId('')
  }

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openDeleteAdresseModal, setOpenDeleteAdresseModal] = useState(false)
  const [openDeleteContactModal, setOpenDeleteContactModal] = useState(false)
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)
  const handleConfirmDeleteModal = () => removeFile()
  const handleCloseDeleteAdresseModal = () => setOpenDeleteAdresseModal(false)
  const handleConfirmDeleteAdresseModal = () => removeAdresse()
  const handleCloseDeleteContactModal = () => setOpenDeleteContactModal(false)
  const handleConfirmDeleteContactModal = () => removeContact()
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [fileDelete, setFileDelete] = useState()
  const [adresseId, setAdresseId] = useState()
  const [contactId, setContactId] = useState()

  const [openOffre, setOpenOffre] = useState(false)
  const [offres, setOffres] = useState([])
  const [offreSelect, setOffreSelect] = useState('')
  const [offresSelect, setOffresSelect] = useState([])
  const [loadingOffer, setLoadingOffer] = useState(true)
  const [loadingAdresse, setLoadingAdresse] = useState(true)
  const [loadingContact, setLoadingContact] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const { handleSubmit } = useForm({ mode: 'onBlur' })
  const [page, setPage] = useState(1)
  const [pageContact, setPageContact] = useState(1)
  const [pageBesoin, setPageBesoin] = useState(1)
  const [count, setCount] = useState(0)
  const [countContact, setCountContact] = useState(0)
  const [countBesoin, setCountBesoin] = useState(0)

  const [besoinDelete, setBesoinDelete] = useState()
  const [openBesoin, setOpenBesoin] = useState(false)
  const [openDetailBesoin, setOpenDetailBesoin] = useState(false)
  const [besoins, setBesoins] = useState([])
  const [besoin, setBesoin] = useState(null)
  const [loadingBesoin, setLoadingBesoin] = useState(true)

  const [openEvent, setOpenEvent] = useState(false)
  const [event, setEvent] = useState({})

  const handleOpenEvent = (event) => {
    setEvent(event)
    setOpenEvent(true)
  }
  const handleCloseEvent = () => {
    setOpenEvent(false)
    fetchData()
  }

  const [openDeleteBesoinModal, setOpenDeleteBesoinModal] = useState(false)
  const handleCloseDeleteBesoinModal = () => setOpenDeleteBesoinModal(false)
  const handleConfirmDeleteBesoinModal = () => removeBesoin()

  const handleOpenBesoin = () => setOpenBesoin(true)
  const handleCloseBesoin = () => {
    setOpenBesoin(false)
    setBesoin(null)
    //fetchData()
  }
  const handleCloseDetailBesoin = () => {
    setOpenDetailBesoin(false)
    //fetchData()
  }
  const handleEditBesoin = (besoin) => {
    setOpenBesoin(true)
    setBesoin(besoin)
  }
  const handleDetailBesoin = (besoin) => {
    setOpenDetailBesoin(true)
    setBesoin(besoin)
  }
  const handleRemoveBesoin = (idBesoin) => {
    setOpenDeleteBesoinModal(true)
    setBesoinDelete(idBesoin)
  }

  const handleSaveBesoin = () => {
    setOpenBesoin(false)
    getBesoinsCompany(snapShot.entreprise?.id, page)
  }

  const getAdressesCompany = async (idCompany, page) => {
    setLoadingAdresse(true)
    try {
      let result = await Api.adresse.listWithFilter({
        entreprise: idCompany,
        page: page,
      })

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingAdresse(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling
        setLoadingAdresse(false)
        setAdresses(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
      } else {
        setLoadingAdresse(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingAdresse(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const removeBesoin = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.besoinEntreprise.delete(besoinDelete)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const indexBesoin = besoins.findIndex((bs) => bs.id === besoinDelete)

        if (indexBesoin > -1) {
          besoins.splice(indexBesoin, 1)
        }
        setBesoins(besoins)
        setOpenDeleteBesoinModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
  }

  const getBesoinsCompany = async (idCompany, page) => {
    setLoadingBesoin(true)
    try {
      let result = await Api.besoinEntreprise.listWithFilter({
        entreprise: idCompany,
        page: page,
      })

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingBesoin(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling
        setLoadingBesoin(false)
        setBesoins(res.data['hydra:member'])
        setCountBesoin(res.data['hydra:totalItems'])
      } else {
        setLoadingBesoin(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingBesoin(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const getContactsCompany = async (idCompany, pageContact) => {
    setLoadingContact(true)
    try {
      let result = await Api.contact.listWithFilter({
        /* 'entite.entreprise': idCompany, */
        entreprise: idCompany,
        page: pageContact,
      })

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingContact(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling
        setLoadingContact(false)
        setContacts(res.data['hydra:member'])
        setCountContact(res.data['hydra:totalItems'])

        // Extract and flatten the environnementsTechniques arrays
        const allEnvironnementsTechniques = res.data['hydra:member']
          .map((contact) => contact.environnementsTechniques)
          .flat()

        // Remove duplicates using a Set
        const uniqueEnvironnementsTechniques = [
          ...new Set(allEnvironnementsTechniques),
        ]

        setContactsEnvironnementsTechniques(uniqueEnvironnementsTechniques)
      } else {
        setLoadingContact(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingContact(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    getOffers()
  }, [])

  const getOffers = async () => {
    try {
      let result = await Api.offer.listWithFilter({
        categorie: 'entreprise',
      })

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingOffer(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoadingOffer(false)
        setOffres(res)
      } else {
        setLoadingOffer(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingOffer(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const removeAdresse = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.adresse.delete(adresseId)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const indexAdresse = adresses.findIndex((bs) => bs.id === adresseId)

        if (indexAdresse > -1) {
          adresses.splice(indexAdresse, 1)
        }
        setAdresses(adresses)
        setOpenDeleteAdresseModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
    setAdresseId('')
  }

  const removeContact = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.contact.delete(contactId)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const indexContact = contacts.findIndex((bs) => bs.id === contactId)

        if (indexContact > -1) {
          contacts.splice(indexContact, 1)
        }
        setContacts(contacts)
        setOpenDeleteContactModal(false)
        fetchData()
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
    setContactId('')
  }

  const removeFile = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.document.delete(fileDelete)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const indexFile = isFile.findIndex((file) => file.id === fileDelete)

        if (indexFile > -1) {
          isFile.splice(indexFile, 1)
        }
        setIsFile(isFile)
        setOpenDeleteModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
  }
  const fetchData = async () => {
    setLoading(true)
    try {
      let result = await Api.user.detail(params.userId)

      setContent(result.data)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const res = handling.data
        getAdressesCompany(res.entreprise?.id)
        getContactsCompany(res.entreprise?.id)
        getBesoinsCompany(res.entreprise?.id, page)
        setSnapShot(res)
        setLoading(false)
        setRole(res.role)
        setDateRdv(res.entreprise?.calendriers.sort((a, b) => a.id - b.id))
        setCommentaire(res.commentaire)
        let userOffres = []
        if (res.userOffres) {
          res.userOffres.map((userOffre, i) => {
            userOffres.push({
              id: userOffre.id,
              offre: `/api/offres/${userOffre.offre.id}`,
              prix: userOffre.prix,
            })
          })
        }
        setOffresSelect(userOffres)

        let docs = []
        if (res.documents) {
          res.documents.map((doc, i) => {
            docs.push(doc)
          })
        }

        setIsFile(docs)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
  }
  useEffect(() => {
    if (params.userId) fetchData()
  }, [params.userId, dispatch])

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <div style={{ width: '100%' }}>
      <Button
        sx={{
          margin: '10px',
        }}
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          fontSize={15}
          color={lightTheme.palette.primary.b2}
        />{' '}
        <Typography
          variant={'body2'}
          color={lightTheme.palette.primary.b2}
          textAlign={'right'}
          marginLeft={1}
        >
          {'Retour'}
        </Typography>
      </Button>
      <MasonryLayoutCompany
        type={'entreprise'}
        handleOpenDoc={handleOpenDoc}
        handleOpenAdresse={handleOpenAdresse}
        handleOpenContact={handleOpenContact}
        handleOpenCommentaire={handleOpenCommentaire}
        handleOpenOffer={handleOpenOffer}
        handleOpenValeurs={handleOpenValeurs}
        handleOpenBesoin={handleOpenBesoin}
        profil={
          <ProfilContainer>
            <TitleContainer>
              <TitleSection></TitleSection>
              <IconContainer onClick={handleOpen}>
                <FontAwesomeIcon
                  icon={faPenCircle}
                  fontSize={40}
                  color={lightTheme.palette.primary.b2}
                />
              </IconContainer>
            </TitleContainer>
            <WrapperProfilText>
              {snapShot.logo && (
                <Typography
                  sx={{
                    fontFamily: 'Century Gothic Bold',
                    fontSize: { xs: 20 },
                    lineHeight: '120%',
                    color: lightTheme.palette.text.primary,
                    textAlign: 'center',
                    display: { xs: 'none', lg: 'block' },
                    textTransform: 'uppercase',
                  }}
                >
                  <img
                    src={snapShot.logo}
                    alt="Logo"
                    style={{ maxWidth: '100%', maxHeight: '100px' }}
                  />
                </Typography>
              )}
              <Typography
                sx={{
                  fontFamily: 'Century Gothic Bold',
                  fontSize: { xs: 20 },
                  lineHeight: '120%',
                  color: lightTheme.palette.text.primary,
                  textAlign: 'center',
                  display: { xs: 'none', lg: 'block' },
                  textTransform: 'uppercase',
                }}
              >
                {snapShot.entreprise?.raisonSocial}
              </Typography>
              <Typography
                variant={'subtitle2'}
                textAlign={'center'}
                textTransform={'capitalize'}
              >
                {snapShot.type}
              </Typography>
              <Typography
                variant={'body2'}
                color={lightTheme.palette.grey.grey}
                textAlign={'center'}
              >
                {snapShot.domainesActivites?.join(', ')}
              </Typography>
              {snapShot.entreprise?.siteweb && (
                <Typography
                  variant={'body2'}
                  color={lightTheme.palette.grey.grey}
                  textAlign={'center'}
                  sx={{ textDecoration: 'underline' }}
                >
                  <a href={snapShot.entreprise?.siteweb} target="_blank">
                    {snapShot.entreprise?.siteweb}
                  </a>
                </Typography>
              )}
              {snapShot.entreprise?.siteinfo && (
                <Typography
                  variant={'body2'}
                  color={lightTheme.palette.grey.grey}
                  textAlign={'center'}
                  sx={{ textDecoration: 'underline' }}
                >
                  <a href={snapShot.entreprise?.siteinfo} target="_blank">
                    {snapShot.entreprise?.siteinfo}
                  </a>
                </Typography>
              )}
              {snapShot.entreprise?.ca && (
                <Typography
                  variant={'body2'}
                  color={lightTheme.palette.secondary.dark}
                  textAlign={'center'}
                >
                  {snapShot.entreprise?.ca} €
                </Typography>
              )}
              {snapShot.priorite && snapShot.priorite !== '0' && (
                <>
                  <Typography
                    variant={'body2'}
                    color={
                      snapShot.priorite === '3'
                        ? lightTheme.palette.error.dark
                        : snapShot.priorite === '2'
                        ? lightTheme.palette.warning.dark
                        : lightTheme.palette.grey.grey
                    }
                    textAlign={'center'}
                  >
                    Priorité{' '}
                    {
                      prioriteEntrepriselList.find(
                        (item) =>
                          parseInt(item.id) === parseInt(snapShot.priorite)
                      ).titre
                    }
                  </Typography>
                </>
              )}

              {contactsEnvironnementsTechniques.length > 0 && (
                <Typography
                  variant={'body2'}
                  color={lightTheme.palette.grey.grey}
                  textAlign={'center'}
                >
                  {contactsEnvironnementsTechniques?.join(', ')}
                </Typography>
              )}
            </WrapperProfilText>
            <BannerProfilContainer>
              <WrapperBanner>
                {snapShot.entreprise?.presentation && (
                  <BannerProfil>
                    <Typography
                      textAlign={'right'}
                      title={snapShot.adresses}
                      style={{
                        width: '100%',
                        textAlign: 'justify',
                      }}
                    >
                      {snapShot.entreprise?.presentation}
                    </Typography>
                  </BannerProfil>
                )}
                {snapShot.entreprises.length > 0 && (
                  <BannerProfil>
                    Responsable
                    <Typography>
                      {snapShot.entreprises
                        .map((item) => item.nom + ' ' + item.prenom)
                        .join(' - ')}
                    </Typography>
                  </BannerProfil>
                )}
                {/* <BannerProfil>
                  <FontAwesomeIcon icon={faHome} />
                  <Typography>
                    {snapShot.codePostal} / {snapShot.ville} / {snapShot.pays}
                  </Typography>
                </BannerProfil> */}
                {/* {snapShot.email.startsWith('email@') ? (
                  ''
                ) : (
                  <BannerProfil>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <Typography
                      textAlign={'right'}
                      title={snapShot.email}
                      style={{
                        width: '90%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {snapShot.email}
                    </Typography>
                  </BannerProfil>
                )} */}
                {/* {snapShot.telephone && <BannerProfil>
                  <FontAwesomeIcon icon={faPhone} />
                  <Typography>{snapShot.telephone}</Typography>
                </BannerProfil>} */}
                {/* {snapShot.dateNaissance && (
                  <BannerProfil>
                    <FontAwesomeIcon icon={faCakeCandles} />
                    <Typography>{snapShot.dateNaissance}</Typography>
                  </BannerProfil>
                )} */}

                {/* {snapShot.statusMarital && (
                  <BannerProfil>
                    <FontAwesomeIcon icon={faRingsWedding} />
                    <Typography>{snapShot.statusMarital}</Typography>
                  </BannerProfil>
                )} */}
              </WrapperBanner>
              {/*<BorderButtonPurple>Voir le résumé</BorderButtonPurple>*/}
            </BannerProfilContainer>
          </ProfilContainer>
        }
        besoinClient={
          besoins && (
            <BesoinTable
              besoins={besoins}
              loading={loadingBesoin}
              handleRemove={handleRemoveBesoin}
              handleEdit={handleEditBesoin}
              handleDetailBesoin={handleDetailBesoin}
              handlePageChange={handlePageChangeBesoin}
              count={countBesoin}
              page={pageBesoin}
              role={'ROLE_ADMIN'}
              showContact={true}
            />
          )
        }
        adresses={
          adresses && (
            <AdresseTable
              adresses={adresses}
              loading={loadingAdresse}
              handleRemove={handleRemoveAdresse}
              handleEdit={handleEditAdresse}
              handleDetailAdresse={handleDetailAdresse}
              handlePageChange={handlePageChange}
              count={count}
              page={page}
              role={'ROLE_ADMIN'}
            />
          )
        }
        contacts={
          contacts && (
            <ContactTable
              contacts={contacts}
              loading={loadingContact}
              handleRemove={handleRemoveContact}
              handleEdit={handleEditContact}
              handlePageChange={handlePageChangeContact}
              count={countContact}
              page={pageContact}
              role={'ROLE_ADMIN'}
            />
          )
        }
        commentaireAdmin={
          <Typography variant={'body2'} color={lightTheme.palette.grey.grey}>
            {commentaire}
          </Typography>
        }
        offre={
          snapShot.actionsCandidat && (
            <OffreTable
              handleRemoveAction={handleRemoveAction}
              handleEditAction={handleEditAction}
              handleDetailAction={handleDetailAction}
              type={'entreprise'}
              offres={snapShot.actionsCandidat.sort((a, b) => b.id - a.id)}
              dateOffer={moment(snapShot.date)
                .tz('Europe/Paris')
                .format('DD/MM/YYYY H:mm')}
            />
          )
        }
        ged={
          isFile ? (
            <GedTable
              files={isFile}
              dateFile={moment(snapShot.date)
                .tz('Europe/Paris')
                .format('DD/MM/YYYY H:mm')}
              handleRemove={handleRemove}
            />
          ) : (
            <GedTable />
          )
        }
        rendezVous={
          <BiBlockTable
            rdvAVenir={dateRdv}
            heureAVenir={''}
            handleOpenEvent={handleOpenEvent}
            type={'entreprise'}
          />
        }
        feedback={dateRdv}
        displayCompetencesSection={'none'}
        competences={[]}
        displaySoftskilSection={'none'}
        softskils={[]}
        displayValeurSection={'display'}
        valeurs={snapShot.entreprise.valeurs}
      />

      {open && (
        <EditInfos
          open={open}
          onClose={handleClose}
          id={params.userId}
          onSave={handleSave}
          role={role}
          type={'ROLE_ENTREPRISE'}
        />
      )}

      {openCommentaire && (
        <EditCommentaire
          open={openCommentaire}
          onClose={handleCloseCommentaire}
          onSave={handleSave}
          id={params.userId}
        />
      )}

      {openValeurs && (
        <EditList
          open={openValeurs}
          onClose={handleCloseValeurs}
          onSave={handleSave}
          id={snapShot.entreprise?.id}
          type={'valeurs'}
          values={snapShot.entreprise?.valeurs}
          datas={VALEURS['fr']}
          role={'entreprise'}
        />
      )}

      {openAdresse && (
        <EditAdresseEntreprise
          open={openAdresse}
          onClose={handleCloseAdresse}
          onSave={handleSaveAdresse}
          id={snapShot.entreprise?.id}
          adresse={adresse}
        />
      )}

      {openContact && (
        <EditInfosContact
          open={openContact}
          onClose={handleCloseContact}
          onSave={handleSaveContact}
          id={contact?.id}
          idCompany={snapShot.entreprise?.id}
        />
      )}

      {openDetailAdresse && (
        <DetailAdresseEntreprise
          open={openDetailAdresse}
          onClose={handleCloseDetailAdresse}
          adresse={adresse}
          handleEdit={handleEditAdresse}
        />
      )}

      {openDoc && (
        <EditDocuments
          open={openDoc}
          onClose={handleCloseDoc}
          onSave={handleSave}
          id={params.userId}
          role={'ROLE_ENTREPRISE'}
        />
      )}

      {openOffre && (
        <ChoiceOfferModal
          open={openOffre}
          onClose={handleCloseOffer}
          onSave={handleSave}
          /* items={offres} */
          /* handleClick={handleSubmit()} */
          action={''}
          candidat={snapShot}
          loading={loadingButton}
          type={'entreprise'}
          idContact={null}
          contacts={contacts}
        />
      )}

      {openAction && (
        <ChoiceOfferModal
          open={openAction}
          onClose={handleCloseEditAction}
          onSave={handleSave}
          /* items={offres} */
          /* handleClick={handleSubmit()} */
          actionValue={action}
          candidat={snapShot}
          loading={loadingButton}
          type={'entreprise'}
          idContact={null}
          contacts={contacts}
        />
      )}

      {openDetailAction && (
        <DetailOfferModal
          open={openDetailAction}
          onClose={handleCloseAction}
          /* items={offres} */
          /* handleClick={handleSubmit()} */
          action={action}
          candidat={snapShot}
          loading={loadingButton}
          type={'action'}
          handleEdit={handleEditAction}
        />
      )}

      {openDeleteModal && (
        <DeleteGeneralModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          onConfirm={handleConfirmDeleteModal}
          loading={loadingDelete}
        />
      )}

      {openDeleteAdresseModal && (
        <DeleteGeneralModal
          open={openDeleteAdresseModal}
          onClose={handleCloseDeleteAdresseModal}
          onConfirm={handleConfirmDeleteAdresseModal}
          loading={loadingDelete}
        />
      )}

      {openDeleteContactModal && (
        <DeleteGeneralModal
          open={openDeleteContactModal}
          onClose={handleCloseDeleteContactModal}
          onConfirm={handleConfirmDeleteContactModal}
          loading={loadingDelete}
        />
      )}

      {openDeleteActionModal && (
        <DeleteGeneralModal
          open={openDeleteActionModal}
          onClose={handleCloseDeleteActionModal}
          onConfirm={handleConfirmDeleteActionModal}
          loading={loadingDelete}
        />
      )}

      {openEvent && (
        <EditEvent
          open={openEvent}
          onClose={handleCloseEvent}
          id={params.userId}
          role={role}
          event={event}
          onSave={handleCloseEvent}
          showParams={true}
        />
      )}

      {openBesoin && (
        <EditBesoinEntreprise
          open={openBesoin}
          onClose={handleCloseBesoin}
          onSave={handleSaveBesoin}
          id={snapShot.entreprise?.id}
          idContact={null}
          besoin={besoin}
          contacts={contacts}
        />
      )}

      {openDetailBesoin && (
        <DetailBesoinEntreprise
          open={openDetailBesoin}
          onClose={handleCloseDetailBesoin}
          besoin={besoin}
        />
      )}

      {openDeleteBesoinModal && (
        <DeleteGeneralModal
          open={openDeleteBesoinModal}
          onClose={handleCloseDeleteBesoinModal}
          onConfirm={handleConfirmDeleteBesoinModal}
          loading={loadingDelete}
        />
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </div>
  )
}

export default CompanyProfil
