import {
  Box,
  CircularProgress,
  LinearProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import moment from 'moment/moment'
import lightTheme from '../../styles/theme/lightTheme'
import * as React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import AppPagination from '../paginations'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { prioriteEntrepriselList, STATUS_CONTRAT } from '../../data'
import { useSelector } from 'react-redux'
import './tables.css'

const EmptyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic',
  fontSize: 14,
  lineHeight: '140%',
  color: lightTheme.palette.grey.main,
  textAlign: 'center',
  fontStyle: 'italic',
  padding: '15px 0',
  background: 'rgba(0, 0, 0, 0.04)',
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const DeleteIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.error.dark}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.error.dark}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const ButtonIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.primary.b2}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const TablePanelBox = styled(TabPanel)(({ theme }) => ({
  height: 'calc( 100% - 45px)',
  marginBottom: 10,
  backgroundColor: 'rgb(227, 230, 255, 0.5)',
  '& .MuiBox-root': {
    padding: 0,
  },
}))

const HeaderTab = styled(TableHead)(({ theme }) => ({
  height: 50,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const TitleHead = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const BodyTable = styled(TableBody)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: '2px solid rgb(227, 230, 255, 0.5)',
  fontFamily: 'Century Gothic',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const BesoinTable = ({
  besoins,
  loading,
  handleRemove,
  handleEdit,
  handleDetailBesoin,
  handlePageChange,
  count,
  page,
  role,
  showDetail = false,
  showContact = false,
  showEdit = true,
}) => {
  const { t } = useTranslation()
  const userData = useSelector((state) => state.userAuth.user)
  const navigate = useNavigate()
  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '40vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  return (
    <>
      {besoins.length > 0 ? (
        <TablePanelBox>
          <Table>
            <HeaderTab>
              <TableRow>
                {showDetail && <TitleHead>{t('header.company')}</TitleHead>}
                <TitleHead>{t('filter.poste')}</TitleHead>
                <TitleHead sx={{ textAlign: 'center' }}>
                  Date de démarrage
                </TitleHead>
                <TitleHead>{t('filter.place')}</TitleHead>
                {/* {showDetail && <TitleHead>{t('contact.country')}</TitleHead>} */}
                <TitleHead>TJM</TitleHead>
                {/* {showContact && <TitleHead>Contact</TitleHead>} */}
                <TitleHead>Scope</TitleHead>
                <TitleHead>Statut</TitleHead>
                <TitleHead sx={{ textAlign: 'center' }}>
                  {t('profile.matching')}
                </TitleHead>
                <TitleHead sx={{ textAlign: 'center' }}>Priorité</TitleHead>
                {showEdit && (
                  <TitleHead
                    sx={{
                      width:
                        userData.data.role === 'ROLE_ADMIN' ||
                        userData.data.role === 'ROLE_SUPER_ADMIN'
                          ? '150px'
                          : '70px',
                    }}
                  ></TitleHead>
                )}
              </TableRow>
            </HeaderTab>
            {besoins.length > 0 &&
              besoins.map((besoin, i) => (
                <BodyTable key={besoin.id}>
                  <TableRow sx={{ cursor: 'pointer' }} className="table-row">
                    {showDetail && (
                      <StyledTableCell>
                        <a
                          href={
                            '/admin/gestion-entreprise/profil-entreprise/' +
                            besoin.entreprise?.profile?.id
                          }
                        >
                          {besoin.entreprise.raisonSocial}
                        </a>
                      </StyledTableCell>
                    )}

                    <StyledTableCell
                      onClick={(e) => {
                        navigate('/admin/besoin-entreprise/detail/' + besoin.id)
                      }}
                    >
                      {besoin.poste.titre}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={(e) => {
                        //handleDetailBesoin(besoin)
                        navigate('/admin/besoin-entreprise/detail/' + besoin.id)
                      }}
                      sx={{ textAlign: 'center' }}
                    >
                      {moment(besoin.dateDebut)
                        .tz('Europe/Paris')
                        .format('DD/MM/YYYY')}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={(e) => {
                        navigate('/admin/besoin-entreprise/detail/' + besoin.id)
                      }}
                    >
                      {besoin.lieu}
                    </StyledTableCell>
                    {/* {showDetail && (
                      <StyledTableCell
                        onClick={(e) => {
                          navigate(
                            '/admin/besoin-entreprise/detail/' + besoin.id
                          )
                        }}
                      >
                        {besoin.pays}
                      </StyledTableCell>
                    )} */}
                    <StyledTableCell
                      onClick={(e) => {
                        navigate('/admin/besoin-entreprise/detail/' + besoin.id)
                      }}
                    >
                      {besoin.tjm} €
                    </StyledTableCell>
                    {/* {showContact && (
                      <StyledTableCell>
                        <a
                          href={
                            '/admin/gestion-entreprise/contact-entreprise/' +
                            besoin.contact?.id
                          }
                        >
                          {besoin.contact?.prenom} {besoin.contact?.nom}
                        </a>
                      </StyledTableCell>
                    )} */}
                     <StyledTableCell>
                        <a
                          href={
                            '/admin/gestion-entreprise/contact-entreprise/' +
                            besoin.contact?.id
                          }
                        >
                          {besoin.scope ? "Scope" : "Hors scope"}
                        </a>
                      </StyledTableCell>
                    <StyledTableCell
                      onClick={(e) => {
                        navigate('/admin/besoin-entreprise/detail/' + besoin.id)
                      }}
                    >
                      {STATUS_CONTRAT.find(
                        (item) => item.id === besoin.typeContrat
                      )?.titre2 || 'N/A'}

                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                          <LinearProgress
                            variant="determinate"
                            color={
                              STATUS_CONTRAT.find(
                                (item) => item.id === besoin.typeContrat
                              )?.status === 100
                                ? 'success'
                                : STATUS_CONTRAT.find(
                                    (item) => item.id === besoin.typeContrat
                                  )?.status > 20
                                ? 'secondary'
                                : 'warning'
                            }
                            value={
                              STATUS_CONTRAT.find(
                                (item) => item.id === besoin.typeContrat
                              )?.status || 0
                            }
                          />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                          <Typography variant="body2">{`${Math.round(
                            STATUS_CONTRAT.find(
                              (item) => item.id === besoin.typeContrat
                            )?.status || 0
                          )}%`}</Typography>
                        </Box>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={(e) => {
                        navigate('/admin/besoin-entreprise/detail/' + besoin.id)
                      }}
                      sx={{ textAlign: 'center' }}
                    >
                      {besoin.totalMatch}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={(e) => {
                        navigate('/admin/besoin-entreprise/detail/' + besoin.id)
                      }}
                      sx={{ textAlign: 'center' }}
                    >
                      <Typography
                        variant={'body2'}
                        color={
                          besoin.priorite === '3'
                            ? lightTheme.palette.error.dark
                            : besoin.priorite === '2'
                            ? lightTheme.palette.warning.dark
                            : lightTheme.palette.grey.grey
                        }
                        textAlign={'center'}
                      >
                        {besoin.priorite
                          ? prioriteEntrepriselList.find(
                              (prio) =>
                                parseInt(prio.id) === parseInt(besoin.priorite)
                            ).titre
                          : prioriteEntrepriselList.find(
                              (prio) => parseInt(prio.id) === 0
                            ).titre}
                      </Typography>
                    </StyledTableCell>
                    {showEdit && (
                      <StyledTableCell>
                        <ButtonIcon
                          onClick={(e) => {
                            handleEdit(besoin)
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEdit}
                            color={lightTheme.palette.primary.b2}
                            fontSize={16}
                          />
                        </ButtonIcon>
                        {(userData.data.role === 'ROLE_ADMIN' ||
                          userData.data.role === 'ROLE_SUPER_ADMIN') && (
                          <DeleteIcon
                            onClick={(e) => {
                              handleRemove(besoin.id)
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              color={lightTheme.palette.error.dark}
                              fontSize={16}
                            />
                          </DeleteIcon>
                        )}
                      </StyledTableCell>
                    )}
                  </TableRow>
                </BodyTable>
              ))}
          </Table>
        </TablePanelBox>
      ) : (
        <EmptyText>Aucune fiche de poste...</EmptyText>
      )}
      <AppPagination
        handlePageChange={handlePageChange}
        count={count}
        page={page}
        pageSize={10}
      />
    </>
  )
}

export default BesoinTable
