import {
  Box,
  CircularProgress,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import moment from 'moment/moment'
import lightTheme from '../../styles/theme/lightTheme'
import * as React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import AppPagination from '../paginations'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import './tables.css'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const DeleteIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.error.dark}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.error.dark}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const ButtonIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.primary.b2}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const TablePanelBox = styled(TabPanel)(({ theme }) => ({
  height: 'calc( 100% - 45px)',
  marginBottom: 10,
  backgroundColor: 'rgb(227, 230, 255, 0.5)',
  '& .MuiBox-root': {
    padding: 0,
  },
}))

const HeaderTab = styled(TableHead)(({ theme }) => ({
  height: 50,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const TitleHead = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const BodyTable = styled(TableBody)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: '2px solid rgb(227, 230, 255, 0.5)',
  fontFamily: 'Century Gothic',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const AdresseTable = ({
  adresses,
  loading,
  handleRemove,
  handleEdit,
  handleDetailAdresse,
  handlePageChange,
  count,
  page,
  role,
  showDetail = false,
}) => {
  const { t } = useTranslation()
  const userData = useSelector((state) => state.userAuth.user)
  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '40vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  return (
    <>
      <TablePanelBox>
        {adresses && (
          <Table>
            <HeaderTab>
              <TableRow>
                <TitleHead>Nom du site</TitleHead>
                <TitleHead>Adresse</TitleHead>
                <TitleHead
                      sx={{textAlign: 'center'}}>Contacts</TitleHead>
                <TitleHead
                      sx={{textAlign: 'center'}}>Besoin</TitleHead>
                <TitleHead
                  sx={{
                    width:
                      userData.data.role === 'ROLE_ADMIN' ||
                      userData.data.role === 'ROLE_SUPER_ADMIN'
                        ? '150px'
                        : '70px',
                  }}
                ></TitleHead>
              </TableRow>
            </HeaderTab>
            {adresses &&
              adresses.map((adresse, i) => (
                <BodyTable key={adresse.id}>
                  <TableRow sx={{ cursor: 'pointer' }} className="table-row">
                    <StyledTableCell
                      onClick={(e) => {
                        handleDetailAdresse(adresse)
                        //e.target.parentNode.parentNode.parentNode.remove()
                      }}
                    >
                      {adresse.nomSite}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={(e) => {
                        handleDetailAdresse(adresse)
                        //e.target.parentNode.parentNode.parentNode.remove()
                      }}
                    >
                      {adresse.adresse}
                      <br></br>
                      {adresse.codePostal} - {adresse.ville}, {adresse.pays}
                    </StyledTableCell>

                    <StyledTableCell
                      onClick={(e) => {
                        handleDetailAdresse(adresse)
                        //e.target.parentNode.parentNode.parentNode.remove()
                      }}
                      sx={{textAlign: 'center'}}
                    >
                      {adresse.contacts.length}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={(e) => {
                        handleDetailAdresse(adresse)
                        //e.target.parentNode.parentNode.parentNode.remove()
                      }}
                      sx={{textAlign: 'center'}}
                    >
                      {adresse.contacts.reduce((total, contact) => {
                        return total + contact.besoinEntreprises.length
                      }, 0)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {/* <ButtonIcon
                        onClick={(e) => {
                          handleDetailAdresse(adresse)
                          //e.target.parentNode.parentNode.parentNode.remove()
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                          color={lightTheme.palette.primary.b2}
                          fontSize={16}
                        />
                      </ButtonIcon> */}
                      <ButtonIcon
                        onClick={(e) => {
                          handleEdit(adresse)
                          //e.target.parentNode.parentNode.parentNode.remove()
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          color={lightTheme.palette.primary.b2}
                          fontSize={16}
                        />
                      </ButtonIcon>
                      {(userData.data.role === 'ROLE_ADMIN' ||
                        userData.data.role === 'ROLE_SUPER_ADMIN') && (
                        <DeleteIcon
                          onClick={(e) => {
                            handleRemove(adresse.id)
                            //e.target.parentNode.parentNode.parentNode.remove()
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            color={lightTheme.palette.error.dark}
                            fontSize={16}
                          />
                        </DeleteIcon>
                      )}
                    </StyledTableCell>
                  </TableRow>
                </BodyTable>
              ))}
          </Table>
        )}
      </TablePanelBox>
      <AppPagination
        handlePageChange={handlePageChange}
        count={count}
        page={page}
        pageSize={10}
      />
    </>
  )
}

export default AdresseTable
