import React, { useEffect, useState } from 'react'
import { Box, styled } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { SidebarData } from './SidebarData'
import Submenu from './Submenu'
import logo from '../../assets/images/logos/hcube-logo.png'
import { DarkButton } from '../buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGear,
  faArrowRightFromBracket,
} from '@fortawesome/pro-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'

const SidebarNav = styled('nav')(({ theme }) => ({
  background: lightTheme.palette.admin.adminMenu,
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  overflow: 'auto',
  position: 'fixed',
  padding: '35px 25px',
  left: 0,

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: 260,
    width: 260,
    height: '100vh',
    flexGrow: 1,
    zIndex: 2,
  },

  [theme.breakpoints.up('xl')]: {
    maxWidth: 300,
    width: 300,
  },
}))

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
}))

const SidebarWrap = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '100px',
}))

const Sidebar = () => {
  let navigate = useNavigate()
  const [countCandidat, setCountCandidat] = useState(0)
  const [countCollaborateur, setCountCollaborateur] = useState(0)
  const [countCollaborateurScope, setCountCollaborateurScope] = useState(0)
  const [countCollaborateurHorsScope, setCountCollaborateurHorsScope] =
    useState(0)
  const [countProspect, setCountProspect] = useState(0)
  const [countPartenaire, setCountPartenaire] = useState(0)
  const [countEsn, setCountEsn] = useState(0)
  const [countEntreprise, setCountEntreprise] = useState(0)
  const [countBesoinEncours, setCountBesoinEncours] = useState(0)
  const [countBesoinAvenir, setCountBesoinAvenir] = useState(0)
  const [countBesoinSigner, setCountBesoinSigner] = useState(0)
  const [countBesoinPerdu, setCountBesoinPerdu] = useState(0)

  const getDashbOardCunter = async () => {
    try {
      const result = await Api.user.dashboardCount()

      const res = handlingErrors(result)

      if (res.ok) {
        setCountCandidat(res.data.candidatCount)
        setCountCollaborateurScope(res.data.collaborateurCount)
        setCountCollaborateurHorsScope(res.data.collaborateurHorsScopeCount)

        setCountProspect(res.data.prospectCount)
        setCountEntreprise(res.data.clientCount)
        setCountPartenaire(res.data.partenaireCount)
        setCountEsn(res.data.esnCount)

        setCountBesoinEncours(res.data.besoinEncoursCount)
        setCountBesoinAvenir(res.data.besoinAvenirCount)
        setCountBesoinSigner(res.data.besoinSignerCount)
        setCountBesoinPerdu(res.data.besoinPerduCount)
      }
    } catch (error) {
      console.debug(error)
    }
  }

  useEffect(() => {
    getDashbOardCunter()
  }, [])

  const [subnav, setSubnav] = useState(false)
  const [subnavKey, setSubnavKey] = useState()
  const showSubnav = (e, keyIndex) => {
    console.log(e, keyIndex)
    setSubnavKey(keyIndex)
    setSubnav(true)
  }

  return (
    <SidebarNav>
      <LogoContainer>
        <img
          src={logo}
          alt={'Hcub3 Cvtheque'}
          width={100}
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/admin/dashboard')}
        />
      </LogoContainer>
      <SidebarWrap>
        {SidebarData.map((item, index) => {
          return (
            <Submenu
              item={item}
              keyIndex={index}
              countCandidat={countCandidat}
              countCollaborateur={countCollaborateur}
              countCollaborateurScope={countCollaborateurScope}
              countCollaborateurHorsScope={countCollaborateurHorsScope}
              countProspect={countProspect}
              countPartenaire={countPartenaire}
              countEsn={countEsn}
              countEntreprise={countEntreprise}
              countBesoinEncours={countBesoinEncours}
              countBesoinAvenir={countBesoinAvenir}
              countBesoinSigner={countBesoinSigner}
              countBesoinPerdu={countBesoinPerdu}
              showSubnav={showSubnav}
              subnav={subnav}
              subnavKey={subnavKey}
            />
          )
        })}
      </SidebarWrap>
      <Box
        sx={{
          width: { xs: '115%', sm: '105%' },
          height: 85,
          display: { xs: 'flex', lg: 'none' },
          justifyContent: { xs: 'center', sm: 'flex-start' },
          alignItems: 'center',
          columnGap: { xs: '15px', sm: '20px' },
          margin: '0 -25px -35px -25px',
          padding: '20px 25px',
          borderTop: `3px solid ${lightTheme.palette.admin.adminSearch}`,
        }}
      >
        <DarkButton
          icon={<FontAwesomeIcon icon={faGear} style={{ fontSize: 15 }} />}
          handleClick={() => navigate('/admin')}
        >
          Paramètres
        </DarkButton>
        <DarkButton
          icon={
            <FontAwesomeIcon
              icon={faArrowRightFromBracket}
              style={{ fontSize: 15 }}
            />
          }
        >
          Déconnexion
        </DarkButton>
      </Box>
    </SidebarNav>
  )
}

export default Sidebar
