import ApiRequest from '../request/ApiRequest'

export default class besoinEntreprise {
  constructor() {
    this.ApiRequest = new ApiRequest()
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post('besoin_entreprises', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`besoin_entreprises/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`besoin_entreprises/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }
  async list() {
    try {
      const res = await this.ApiRequest.get('besoin_entreprises')
      return res
    } catch (error) {
      return error.response
    }
  }
  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`besoin_entreprises/${id}`)
      return res
    } catch (error) {
      return error.response
    }
  }
  async matchingCandidatesByOffre(id) {
    try {
      const res = await this.ApiRequest.get(`matcher/candidat/${id}`)
      return res
    } catch (error) {
      return error.response
    }
  }
  async matchingoffresByCandidat(id) {
    try {
      const res = await this.ApiRequest.get(`matcher/besoin/candidat/${id}`)
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get(
        'besoin_entreprises.jsonld',
        data,
        'jsonld'
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithPaginationAndFilterAndOrderBy(data, order, orderType = "desc") {
    try {
      const res = await this.ApiRequest.get(
        `besoin_entreprises.jsonld?order[${order}]=${orderType}`,
        data,
        'jsonld'
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithPaginationAndFilterAndOrderByPriorite(data) {
    try {
      const res = await this.ApiRequest.get(
        'besoin_entreprises.jsonld?order[priorite]=desc',
        data,
        'jsonld'
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  async downloadBc(id, datas) {
    const params = new URLSearchParams(datas)

    try {
      const response = await fetch(
        this.ApiRequest.getApiUrl() + 'besoin/fiche/' + id + `?${params}`,
        {
          method: 'GET',
          headers: {
            Authorization:
              'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
            Accept: 'application/pdf', // Expect a PDF response
          },
          datas,
        }
      )
      return response
    } catch (error) {
      return error.response
    }
  }
}
