import React, { useEffect, useState } from 'react'
import { AdminHeading } from '../../../../components/headings'
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import '../../../../styles/components/_layouts.scss'
import '../../../../styles/components/_grids.scss'
import '../../../../styles/components/_box.scss'
import '../../../../styles/components/_inputs.scss'
import { TextInputClassic } from '../../../../components/inputs'
import {
  BorderButtonPurple,
  CustomButton,
} from '../../../../components/buttons'
import { useNavigate, useParams } from 'react-router-dom'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
  faDeleteLeft,
  faFileUpload,
  faRemove,
} from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../../styles/theme/lightTheme'
import { Controller, useForm } from 'react-hook-form'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import TextareaInputClassic from '../../../../components/inputs/TextareaInputClassic'
import { useSelector } from 'react-redux'
import {
  domainesActivites,
  prioriteEntrepriselList,
  typeEntrepriselList,
} from '../../../../data'
import ButtonLogoUpload from '../../../../components/inputs/ButtonLogoUpload'

const InputContainer = styled(FormControl)(({ theme }) => ({
  rowGap: '5px',
}))

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  /*  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8), */
}))

const GroupItems = styled('ul')({
  padding: 0,
})

const schema = yup.object({
  raisonSocial: yup
    .string('Ce champ doit être une chaîne de caractères.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  /* adresse: yup
    .string('Ce champ doit être une chaîne de caractères.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'), */
  /*fonction: yup
    .string("Ce champ doit être une chaîne de caractères.")
    .required("Ce champ est obligatoire. Veuillez le renseigner."),*/
  /*  commentaire: yup
     .string('Ce champ doit être une chaîne de caractères.')
     .required('Ce champ est obligatoire. Veuillez le renseigner.'), */
  /*  siteweb: yup
     .string('Ce champ doit être une chaîne de caractères.')
     .required('Ce champ est obligatoire. Veuillez le renseigner.'), */
  /* email: yup
    .string('Ce champ doit être une chaîne de caractères.')
    .email("Veuillez respecter le format de l'email: johnDoe@gmail.com")
    .required('Ce champ est obligatoire. Veuillez le renseigner.'), */
  /* nom: yup
    .string('Ce champ doit être une chaîne de caractères.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  telephone: yup
    .string('Ce champ doit être une chaîne de caractères.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  codePostal: yup
    .number()
    .typeError('Veuillez respecter le format.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'), */
})

const AddCompany = () => {
  let navigate = useNavigate()
  const userData = useSelector((state) => state.userAuth.user)
  const [loadingAddAdmin, setLoadingAddAdmin] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorFonction, setErrorFonction] = useState(false)
  const [fonctions, setFonctions] = useState([])
  const [commercials, setCommercials] = useState([])
  const [fonctionSelect, setFonctionSelect] = useState('')
  const [file, setFile] = useState('')
  const [errorFiles, setErrorFiles] = useState(false)
  const [commercialSelect, setCommercialSelect] = useState([])
  const [selectedCountry, setSelectedCountry] = useState({
    label: 'France',
  })
  const [cities, setCities] = useState([])
  const [selectedCity, setSelectedCity] = useState('Paris')
  const [typeSelect, setTypeSelect] = useState(typeEntrepriselList[0])
  const [prioriteSelect, setPrioriteSelect] = useState(
    prioriteEntrepriselList[0]
  )
  const [domaineActivites, setDomaineActivites] = useState()
  const [phoneValue, setPhoneValue] = useState()
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const params = useParams()

  useEffect(() => {
    handleCountryChange(selectedCountry)
  }, ['selectedCountry'])

  const [previewUrl, setPreviewUrl] = useState(null)
  const handleFileChange = (event) => {
    const files = event.target.files
    setFile(files)
    setValue('files', Array.from(files))
    // Create preview URL for the first file
    if (files.length > 0) {
      const fileUrl = URL.createObjectURL(files[0])
      setPreviewUrl(fileUrl)
    } else {
      setPreviewUrl(null)
    }
  }

  // Add this function to clear the file
  const handleClearFile = () => {
    setFile([])
    setValue('files', [])
    setPreviewUrl(null)
  }

  const handleCountryChange = async (country) => {
    setSelectedCountry(country)

    try {
      const result = await Api.countriesCities.getCitiesByCountry(country.label)

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setCities(res.data.data)
        //setSelectedCity(res.data.data[0])
      }
    } catch (error) {
      console.bebug(error)
    }
  }

  useEffect(() => {
    //getFonctions()
    getCommerciaux()
  }, [])

  const handleFonctionSelect = (event) => {
    const inputValue = event.target.value
    setFonctionSelect(inputValue)
  }

  const getFonctions = async () => {
    try {
      let result = await Api.fonction.listWithFilter({
        status: true,
      })
      setFonctions(result)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoading(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoading(false)
        setFonctions(res)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const getCommerciaux = async () => {
    setLoading(true)
    try {
      let result = await Api.user.listWithFilter({
        role: [
          'ROLE_COMMERCIAL',
          'ROLE_RECRUTEUR',
          'ROLE_ADMIN',
          'ROLE_SUPER_ADMIN',
        ],
        status: 'active',
      })

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoading(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setCommercials(res)
        setCommercialSelect([res.find((user) => user.id === userData.data.id)])
        setLoading(false)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const addCompany = async (data) => {
    setLoadingAddAdmin(true)
    try {
      const result = await Api.user.addPlusLogo(data)
      console.log(result)
      const res = handlingErrors(result)
      console.log(res)
      if (res.ok) {
        toast.success('La création du client à été réalisé avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })

        setTimeout(() => {
          navigate('/admin/gestion-entreprise/liste/' + typeSelect.id)
        }, 1000)
      }

      if (res.status === 422) {
        toast.error("L'email est déjà utilisé pour un autre compte !", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (res.status === 401) {
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
      if (res.status === 302) {
        toast.error('Société déjà existante', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingAddAdmin(false)
  }

  const onSubmit = (data) => {
    /* console.log(commercialSelect.map(obj => '/api/utilisateurs/' + obj.id))
    return */
    /* if (fonctionSelect === '') {
      setErrorFonction(true)
      return
    } */

    /* if (data.email === '') data.email = 'email@' + data.raisonSocial
    const payload = {
      ...data,
      roles: ['ROLE_ENTREPRISE'],
      password: 'userPass',
      status: params.type === 'client' ? 'active' : 'classic',
      /* pays: selectedCountry.label,
      ville: selectedCity,
      commentaire: data.commentaire, 
      entreprises:
        userData.data.role === 'ROLE_COMMERCIAL'
          ? ['/api/utilisateurs/' + userData.data.id]
          : commercialSelect.map((obj) => '/api/utilisateurs/' + obj.id),
      entreprise: {
        siteweb: data.siteweb,
        raisonSocial: data.raisonSocial,
        /* fonctions: `/api/fonctions/${fonctionSelect}`, 
      },
    } */

    const formData = new FormData()
    if (domaineActivites && domaineActivites.length > 0)
      formData.append(
        'domainesActivites',
        domaineActivites.map((item) => item.domaine)
      )
    formData.append('commentaire', data.commentaire)
    /* formData.append('adresse', data.adresse) */
    formData.append('raisonSocial', data.raisonSocial)
    formData.append('status', 'active')
    formData.append('roles', ['ROLE_ENTREPRISE'])
    formData.append('password', 'userPass')
    formData.append('type', typeSelect.id)
    formData.append('priorite', prioriteSelect.id)
    /* formData.append('telephone', data.telephone) */
    formData.append('email', 'email@' + data.raisonSocial)
    /* formData.append('cv', file ? file : null) */
    if (file.length > 0)
      Array.from(file).map((doc) => {
        formData.append('logo[]', doc)
      })

    formData.append('entreprise.siteweb', data.siteweb)
    formData.append('entreprise.siteinfo', data.siteinfo)
    formData.append('entreprise.raisonSocial', data.raisonSocial)
    formData.append('entreprise.ca', data.ca)
    formData.append('entreprise.presentation', data.presentation)

    formData.append(
      'entreprises',
      userData.data.role === 'ROLE_COMMERCIAL' ||
        userData.data.role === 'ROLE_RECRUTEUR'
        ? [userData.data.id]
        : commercialSelect.map((obj) => obj.id)
    )

    addCompany(formData)
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <AdminHeading handleClickIcon={() => navigate(-1)}>
          Ajouter une entreprise
        </AdminHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            padding: '50px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          <form>
            <Grid container spacing={2} className={'gridContainer'}>
              <Grid item xs={12} sm={4} lg={4}></Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel
                    className={'labelForm'}
                    sx={{ textAlign: 'center' }}
                  >
                    Logo
                  </FormLabel>

                  {previewUrl ? (
                    <Box mt={2} sx={{ textAlign: 'center' }}>
                      <img
                        src={previewUrl}
                        alt="Logo preview"
                        style={{ maxWidth: '100%', maxHeight: '50px' }}
                      />
                      <br></br>
                      <Button
                        onClick={handleClearFile}
                        startIcon={
                          <FontAwesomeIcon
                            icon={faRemove}
                            fontSize={25}
                            color={lightTheme.palette.success.main}
                          />
                        }
                      >
                        Supprimer
                      </Button>
                    </Box>
                  ) : (
                    <ButtonLogoUpload onChange={handleFileChange} />
                  )}
                </InputContainer>

                {errorFiles && (
                  <Typography className={'error-message'}>
                    {'Merci de télécharger au moins un CV.'}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={4} lg={4}></Grid>
              {userData.data.role !== 'ROLE_COMMERCIAL' &&
                userData.data.role !== 'ROLE_RECRUTEUR' && (
                  <Grid item xs={12} sm={12} lg={12}>
                    <InputContainer fullWidth>
                      <FormLabel className={'labelForm'}>
                        Commercial(s)
                      </FormLabel>
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <Autocomplete
                            sx={{ width: '100%', backgroundColor: '#fff' }}
                            multiple
                            defaultValue={commercialSelect}
                            options={commercials}
                            getOptionLabel={(option) =>
                              option.nom +
                              ' ' +
                              option.prenom +
                              ' (' +
                              option.email +
                              ')'
                            }
                            onChange={(event, newValue) => {
                              setCommercialSelect(newValue)
                            }}
                            renderTags={(commercials, getTagProps) =>
                              commercials.map((commercial, index) => (
                                <Chip
                                  variant="outlined"
                                  label={
                                    commercial.nom + ' ' + commercial.prenom
                                  }
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField {...params} placeholder={' '} />
                            )}
                          />
                        </>
                      )}
                    </InputContainer>
                  </Grid>
                )}
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Nom entreprise
                    <span
                      style={{
                        color: lightTheme.palette.error.main,
                      }}
                    >
                      *
                    </span>
                  </FormLabel>
                  <TextInputClassic
                    placeholder={'Nom entreprise'}
                    width={'100%'}
                    register={{ ...register('raisonSocial') }}
                  />
                  {errors.raisonSocial && (
                    <Typography className={'error-message'}>
                      {errors.raisonSocial.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Priorité</FormLabel>
                  <Autocomplete
                    disableClearable
                    value={prioriteSelect}
                    id="statusSelect"
                    sx={{ width: '100%', minHeight: '45px' }}
                    onChange={(event, newValue) => {
                      setPrioriteSelect(newValue)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) => option.titre}
                    options={prioriteEntrepriselList}
                    renderInput={(params) => (
                      <TextField
                        id="text-disponibilitesSelect"
                        {...params}
                        sx={{ backgroundColor: '#fff' }}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Type</FormLabel>
                  <Autocomplete
                    disableClearable
                    value={typeSelect ? typeSelect : typeEntrepriselList[0]}
                    id="statusSelect"
                    sx={{ width: '100%', minHeight: '45px' }}
                    onChange={(event, newValue) => {
                      setTypeSelect(newValue)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) => option.titre}
                    options={typeEntrepriselList}
                    renderInput={(params) => (
                      <TextField
                        id="text-disponibilitesSelect"
                        {...params}
                        sx={{ backgroundColor: '#fff' }}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>CA</FormLabel>
                  <TextInputClassic
                    placeholder={"Chiffre d'affaire"}
                    type={'text'}
                    register={{
                      ...register('ca'),
                    }}
                  />
                </InputContainer>
              </Grid>
              {/* <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Numéro de téléphone
                  </FormLabel>
                  <Controller
                    name="phone-input"
                    control={control}
                    rules={{
                      validate: (value) => isValidPhoneNumber(value),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        international
                        value={phoneValue}
                        onChange={setPhoneValue}
                        defaultCountry="FR"
                        id="phone-input"
                        {...register('telephone')}
                        containerStyle={{
                          border: '10px solid black',
                          background: 'pink',
                        }}
                        inputStyle={{
                          background: 'lightblue',
                        }}
                      />
                    )}
                  />

                  {errors.telephone && (
                    <Typography className={'error-message'}>
                      {errors.telephone.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid> */}
              <Grid item xs={12} sm={6} lg={3}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Site web</FormLabel>
                  <TextInputClassic
                    placeholder={'Site web'}
                    width={'100%'}
                    register={{ ...register('siteweb') }}
                  />
                  {errors.siteweb && (
                    <Typography className={'error-message'}>
                      {errors.siteweb.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Societe.com</FormLabel>
                  <TextInputClassic
                    placeholder={'Societe.com'}
                    width={'100%'}
                    register={{ ...register('siteinfo') }}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Domaine d'activité
                  </FormLabel>
                  <Autocomplete
                    sx={{ width: '100%' }}
                    multiple
                    disableCloseOnSelect
                    options={domainesActivites}
                    defaultValue={domaineActivites}
                    groupBy={(option) => option.title} // Grouper par "titre"
                    getOptionLabel={(option) => option.domaine}
                    onChange={(event, newValue) => {
                      setDomaineActivites(newValue)
                    }}
                    renderTags={(competences, getTagProps) =>
                      competences.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.domaine}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        sx={{ backgroundColor: '#fff' }}
                        {...params}
                        placeholder="Ajouter les domaines d'activité"
                      />
                    )}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                  />
                </InputContainer>
              </Grid>
              {/* <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Mail</FormLabel>
                  <TextInputClassic
                    placeholder={'Mail'}
                    width={'100%'}
                    type={'email'}
                    register={{ ...register('email') }}
                  />
                  {errors.email && (
                    <Typography className={'error-message'}>
                      {errors.email.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Adresse</FormLabel>
                  <TextInputClassic
                    placeholder={"Adresse de l'entreprise"}
                    width={'100%'}
                    register={{ ...register('adresse') }}
                  />
                  {errors.adresse && (
                    <Typography className={'error-message'}>
                      {errors.adresse.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid> */}
              {/* <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Prénom</FormLabel>
                  <TextInputClassic
                    placeholder={'Prénom'}
                    width={'100%'}
                    register={{ ...register('prenom') }}
                  />
                  {errors.prenom && (
                    <Typography className={'error-message'}>
                      {errors.prenom.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Nom</FormLabel>
                  <TextInputClassic
                    placeholder={'Nom'}
                    width={'100%'}
                    register={{ ...register('nom') }}
                  />
                  {errors.nom && (
                    <Typography className={'error-message'}>
                      {errors.nom.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              
              <Grid item xs={12} sm={6} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Adresse</FormLabel>
                  <TextInputClassic
                    placeholder={"Adresse de l'entreprise"}
                    width={'100%'}
                    register={{ ...register('adresse') }}
                  />
                  {errors.adresse && (
                    <Typography className={'error-message'}>
                      {errors.adresse.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Pays</FormLabel>
                  <Autocomplete
                    disableClearable
                    sx={{
                      width: '100%',
                      backgroundColor: '#fff',
                    }}
                    value={selectedCountry ? selectedCountry : {}}
                    onChange={(event, newValue) => {
                      handleCountryChange(newValue)
                    }}
                    id="country-select"
                    options={countries}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Ville</FormLabel>
                  <Autocomplete
                    disableClearable
                    sx={{
                      width: '100%',
                      backgroundColor: '#fff',
                    }}
                    value={selectedCity ? selectedCity : {}}
                    onChange={(event, newValue) => {
                      setSelectedCity(newValue)
                    }}
                    id="country-select"
                    options={cities}
                    autoHighlight
                    //getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </InputContainer>
              </Grid> */}
              <Grid item xs={12} sm={12} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Présentation de l'entreprise
                  </FormLabel>
                  <TextareaInputClassic
                    placeholder={"Présentation de l'entreprise"}
                    register={{ ...register('presentation') }}
                    type={'border'}
                  />
                  {/*  {errors.entreprise.presentation && (
                    <Typography className={'error-message'}>
                      {errors.entreprise.presentation.message}
                    </Typography>
                  )} */}
                </InputContainer>
              </Grid>
            </Grid>
          </form>

          <Box
            className={'wrapperButtons'}
            sx={{
              justifyContent: 'flex-end',
            }}
          >
            <Box className={'blockButtons'}>
              {loadingAddAdmin ? (
                <CircularProgress />
              ) : (
                <>
                  <BorderButtonPurple
                    width={{ xs: '50%', sm: 160, lg: 200 }}
                    handleClick={() =>
                      navigate('/admin/gestion-entreprise/liste/' + params.type)
                    }
                  >
                    Annuler
                  </BorderButtonPurple>
                  <CustomButton
                    displayIcon={'none'}
                    width={{ xs: '50%', sm: 160, lg: 200 }}
                    handleClick={handleSubmit(onSubmit)}
                  >
                    Ajouter
                  </CustomButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default AddCompany
