import ApiRequest from "../request/ApiRequest";

export default class Environnements {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post("environnements", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async list() {
    try {
      const res = await this.ApiRequest.get("environnements");
      return res;
    } catch (error) {
      return error.response;
    }
  }


  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`environnements/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`environnements/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`environnements/${id}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get("environnements.json", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listWithFilterAndPage(data) {
    try {
      const res = await this.ApiRequest.get("environnements.jsonld", data, "jsonld");
      return res;
    } catch (error) {
      return error.response;
    }
  }

}