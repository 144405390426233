import React, { useEffect, useState } from 'react'
import { AdminHeading } from '../../../../../components/headings'
import { Box, FormLabel, Grid, styled } from '@mui/material'
import '../../../../../styles/components/_box.scss'
import {
  SearchInputBasic,
  SelectInputObject,
} from '../../../../../components/inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faCirclePlus,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons'
import { ListTableProspects } from '../../../../../components/tables'
import Api from '../../../../../services/api.service'
import { handlingErrors } from '../../../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import lightTheme from '../../../../../styles/theme/lightTheme'
import { useNavigate, useParams } from 'react-router-dom'
import { BorderButtonPurple } from '../../../../../components/buttons'
import AppPagination from '../../../../../components/paginations'
import { DeleteProfilModal } from '../../../../../components/modals'
import { debounce } from '../../../../../config'
import { useSelector } from 'react-redux'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const BlockButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '15px',
  alignItems: 'center',
}))

const ListProspectCandidate = () => {
  let navigate = useNavigate()
  const params = useParams()
  //const userData = useSelector((state) => state.userAuth.user)
  const [prospectsCandidate, setProspectsCandidate] = useState([])
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState(null)
  const [openCard, setOpenCard] = useState('')
  //const [role, setRole] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [userId, setUserId] = useState()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)
  const handleConfirmDeleteModal = () => confirmRemoveProfil()
  const [page, setPage] = useState(
    sessionStorage.getItem('pageProspectCandidat')
      ? parseInt(sessionStorage.getItem('pageProspectCandidat'))
      : 1
  )
  const [count, setCount] = useState(0)
  const [filter, setFilter] = useState([
    { id: 0, titre: 'Nom, Prenom, Email ou Téléphone', filtre: 'search' },
    { id: 1, titre: 'Poste', filtre: 'candidat.poste.titre' },
    { id: 1, titre: 'Compétences techniques', filtre: 'candidat.competences' },
    { id: 2, titre: 'Softskills', filtre: 'candidat.softskils' },
    { id: 3, titre: 'Valeurs', filtre: 'candidat.valeurs' },
  ])
  const [filterSelect, setFilterSelect] = useState(filter[0])

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearchQuery(value)
  }, 800)

  useEffect(() => {
    setPage(1)
    getProspectCandidate(1)
  }, [params.scope])

  useEffect(() => {
    getProspectCandidate(page)
  }, [searchQuery])

  const handleFilterSelect = (event) => {
    const inputValue = event.target.value
    setFilterSelect(inputValue)
  }

  const handleRemoveProfil = (idUser) => {
    setOpenDeleteModal(true)
    setUserId(idUser)
  }

  const confirmRemoveProfil = async () => {
    setLoadingDelete(true)
    try {
      const result = await Api.user.delete(userId)

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setOpenDeleteModal(false)
        const indexCandidate = prospectsCandidate.findIndex(
          (candidate) => candidate.id === userId
        )
        if (indexCandidate > -1) {
          prospectsCandidate.splice(indexCandidate, 1)
        }
        setProspectsCandidate(prospectsCandidate)
        setLoadingDelete(false)
      }

      if (res.status === 0) {
        getProspectCandidate(page)
        setLoadingDelete(false)
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      getProspectCandidate(page)
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
  }

  const handlePageChange = (event, page) => {
    setPage(page)
    getProspectCandidate(page)
    sessionStorage.setItem('pageProspectCandidat', page)
  }
  const getProspectCandidate = async (page) => {
    setLoading(true)
    //setRole('ROLE_CANDIDAT')

    let payload = {
      search: searchQuery,
    }
    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    if (filterSelect.id != 0) {
      payload = {
        [filterSelect.filtre]: searchQuery,
      }
    }

    try {
      const result = await Api.user.listWithPaginationAndFilterAndOrderByDate({
        role: 'ROLE_CANDIDAT',
        status: 'classic',
        //entreprises: (userData.data.role === 'ROLE_COMMERCIAL') ? userData.data.id : '',
        page: page,
        'candidat.scope' : params.scope === "scope" ? true : false,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setProspectsCandidate(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
        /* const sort = res.data.sort(( a, b) => ( a.date < b.date ? 1 : -1)); */
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  const handleClickToProfil = (userId) => {
    navigate(`/admin/gestion-prospect/profil-prospect-candidat/${userId}`)
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <AdminHeading displayIconReturn={'none'}>
          Liste des candidats ({params.scope})
        </AdminHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          <WrapperButton sx={{ columnGap: '15px' }}>
            <Grid item>
              <SearchInputBasic onChange={handleSearchChange} />
            </Grid>
            <Grid item>
              <SelectInputObject
                items={filter}
                value={filterSelect}
                handleChange={handleFilterSelect}
              />
            </Grid>
            <Grid item>
              <BlockButton>
                <BorderButtonPurple
                  width={{ xs: 45, sm: 230, lg: 265 }}
                  displayText={{ xs: 'none', sm: 'block' }}
                  icon={<FontAwesomeIcon icon={faCirclePlus} fontSize={15} />}
                  handleClick={() =>
                    navigate('/admin/gestion-client/ajouter-candidat/prospect')
                  }
                >
                  Ajouter un candidat
                </BorderButtonPurple>
              </BlockButton>
            </Grid>
          </WrapperButton>

          <ListTableProspects
            type={'candidat'}
            items={prospectsCandidate}
            setId={setId}
            loading={loading}
            handleClick={handleClickToProfil}
            handleRemove={handleRemoveProfil}
            openCard={openCard}
          />

          <AppPagination
            handlePageChange={handlePageChange}
            count={count}
            page={page}
          />
        </Box>
      </div>

      <DeleteProfilModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteModal}
        loading={loadingDelete}
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default ListProspectCandidate
