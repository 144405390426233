import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  Modal,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import '../../styles/components/_errors.scss'
import { SelectInputBasic, SelectInputObject } from '../inputs'
import { useTranslation } from 'react-i18next'
import { TYPE_ACTIONS } from '../../data'
import TextareaInputClassic from '../inputs/TextareaInputClassic'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import Api from '../../services/api.service'
import { useSelector } from 'react-redux'
import { handlingErrors } from '../../utils'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers'
import moment from 'moment'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  minHeight: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    minHeight: 500,
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    minHeight: 300,
  },
}))

const ChoiceOfferModal = ({
  open,
  onClose,
  candidat,
  onSave,
  type,
  contact,
  actionValue,
  idContact,
  contacts = [],
}) => {
  const currentUser = useSelector((state) => state.userAuth.user)

  const [dateValue, setDateValue] = useState(dayjs(new Date()))
  const parSchema = yup.object({
    /* action: yup
      .string()
      .required(
        type === 'tache'
          ? 'Merci de selectionner la tâche'
          : "Merci de selectionner l'action commercial."
      ), */
  })
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(parSchema),
  })

  const { t, i18n } = useTranslation()
  const [loadingButton, setLoadingButton] = useState(false)
  const [selectValue, setSelectValue] = useState(
    actionValue && type === 'tache'
      ? actionValue.tache
      : actionValue && type !== 'tache'
      ? actionValue.action
      : ''
  )

  const [contactSelect, setContactSelect] = useState(
    actionValue && actionValue.contact
      ? actionValue.contact
      : { id: null, nom: 'Contact a qualifier' }
  )
  const [contactOpen, setContactOpen] = useState(false)

  const onSubmit = async (data) => {
    setLoadingButton(true)

    if (type === 'contact') {
      data.candidat = '/api/utilisateurs/' + candidat
      data.contact = '/api/contacts/' + contact.id
      data.commercial = '/api/utilisateurs/' + currentUser.data.id
    } else {
      data.candidat = '/api/utilisateurs/' + candidat.id
      if (contactSelect && contactSelect.id !== null)
        data.contact = '/api/contacts/' + contactSelect.id
      data.commercial = '/api/utilisateurs/' + currentUser.data.id
    }
    /* alert(data.action)
    /* return */

    data.dateRealisation = moment(new Date(dateValue))
      .tz('Europe/Paris')
      .format('YYYY-MM-DDTHH:mm:ss')
    data.action = selectValue

    try {
      const result = await Api.action.add(data)

      const handling = handlingErrors(result)

      if (handling.ok) {
        const res = handling.data
        onSave()
        //setSnapShot(res);
        /* setOpenOffre(false) */
        setLoadingButton(false)
        /* fetchData()
        toast.success('La proposition a bien été envoyée!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        }) */
      }
    } catch (error) {
      setLoadingButton(false)
    }
    setLoadingButton(false)
  }
  const onSubmitTache = async (data) => {
    setLoadingButton(true)

    data.contact = '/api/contacts/' + contact.id
    data.commercial = '/api/utilisateurs/' + currentUser.data.id
    data.dateRealisation = moment(new Date(dateValue))
      .tz('Europe/Paris')
      .format('YYYY-MM-DDTHH:mm:ss')
    data.tache = selectValue
    /* console.log(data)
    return */

    try {
      const result = await Api.tache.add(data)

      const handling = handlingErrors(result)

      if (handling.ok) {
        const res = handling.data
        onSave()
        //setSnapShot(res);
        /* setOpenOffre(false) */
        setLoadingButton(false)
        /* fetchData()
        toast.success('La proposition a bien été envoyée!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        }) */
      }
    } catch (error) {
      setLoadingButton(false)
    }
    setLoadingButton(false)
  }
  const onEdit = async (data) => {
    setLoadingButton(true)

    //console.log(moment(new Date(dateValue)).format('YYYY-MM-DDTHH:mm:ss'))

    if (type !== 'contact' && contactSelect && contactSelect.id !== null)
      data.contact = '/api/contacts/' + contactSelect.id

    if (type !== 'contact' && contactSelect && contactSelect.id === null)
      data.contact = null

    data.dateRealisation = moment(new Date(dateValue)).format(
      'YYYY-MM-DDTHH:mm:ss'
    )
    data.action = selectValue

    try {
      const result = await Api.action.edit(actionValue.id, data)

      const handling = handlingErrors(result)

      if (handling.ok) {
        const res = handling.data
        onSave()
        //setSnapShot(res);
        /* setOpenOffre(false) */
        setLoadingButton(false)
        /* fetchData()
        toast.success('La proposition a bien été envoyée!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        }) */
      }
    } catch (error) {
      setLoadingButton(false)
    }
    setLoadingButton(false)
  }
  const onEditTache = async (data) => {
    setLoadingButton(true)

    //console.log(dateValue, moment(new Date(dateValue)).format('YYYY-MM-DDTHH:mm:ss'))

    //return

    data.dateRealisation = moment(new Date(dateValue))
      .tz('Europe/Paris')
      .format('YYYY-MM-DDTHH:mm:ss')
    data.tache = selectValue

    //console.log(data)

    try {
      const result = await Api.tache.edit(actionValue.id, data)

      const handling = handlingErrors(result)

      if (handling.ok) {
        const res = handling.data
        onSave()
        //setSnapShot(res);
        /* setOpenOffre(false) */
        setLoadingButton(false)
        /* fetchData()
        toast.success('La proposition a bien été envoyée!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        }) */
      }
    } catch (error) {
      setLoadingButton(false)
    }
    setLoadingButton(false)
  }
  const handleChangeSelect = (evt) => {
    const inputValue = evt.target.value
    setSelectValue(inputValue)
  }
  const changeDate = (date) => {
    setDateValue(date)
  }

  useEffect(() => {
    if (actionValue) {
      setValue('action', actionValue.action)
      setDateValue(
        moment(new Date(actionValue.dateRealisation))
          .tz('Europe/Paris')
          .format('YYYY-MM-DDTHH:mm:ss')
      )
    }
  }, [actionValue])

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {actionValue && type === 'tache'
            ? 'Modifier la tâche'
            : actionValue && type !== 'tache'
            ? "Modifier l'action"
            : !actionValue && type === 'tache'
            ? 'Choix de la tâche'
            : "Choix de l'action"}
        </Typography>
        <BoxContainer>
          <form>
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              {type === 'entreprise' && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Contact</FormLabel>
                  <Autocomplete
                    value={
                      contactSelect
                        ? contactSelect
                        : { id: null, nom: '', prenom: '' }
                    }
                    id="asynchronous-contacts"
                    sx={{ width: '100%', minHeight: '45px' }}
                    open={contactOpen}
                    onChange={(event, newValue) => {
                      setContactSelect(newValue)
                    }}
                    /* onKeyDown={(event, newInputValue) => {
                      clearTimeout(typingTimer)
                      if (event.target.value) {
                        typingTimer = setTimeout(function () {
                          getPostes(event.target.value)
                        }, doneTypingInterval)
                      }
                    }} */
                    onOpen={() => {
                      setContactOpen(true)
                    }}
                    onClose={() => {
                      setContactOpen(false)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) =>
                      option.id !== null
                        ? option?.nom + ' ' + option?.prenom
                        : option?.nom
                    }
                    options={[
                      { id: null, nom: 'Contact a qualifier' },
                      ...contacts,
                    ]}
                    renderInput={(params) => (
                      <TextField
                        id="text-asynchronous-contacts"
                        {...params}
                        sx={{ backgroundColor: '#fff' }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
              >
                <FormLabel className={'labelForm'}>
                  {type === 'tache'
                    ? 'Sélectionner une tâche'
                    : t('box.selectOffer')}
                </FormLabel>
                {/*  <SelectInputObject
                  items={items}
                  ref={register}
                  name={"func"}
                  handleChange={handleChange}
                  value={value}
                /> */}
                <SelectInputBasic
                  items={TYPE_ACTIONS[type]}
                  value={selectValue}
                  handleChange={handleChangeSelect}
                  type={'border'}
                  /* register={{ ...register('action') }} */
                />

                {errors.action && (
                  <Typography className={'error-message'}>
                    {errors.action.message}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
              >
                <FormLabel className={'labelForm'}>Commentaire</FormLabel>
                <TextareaInputClassic
                  placeholder={'Commentaire'}
                  width={'100%'}
                  register={{ ...register('commentaire') }}
                />
              </Grid>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={'fr'}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>
                    {type === 'tache' ? 'Rappel' : 'Date'}
                  </FormLabel>
                  <DatePicker
                    onChange={changeDate}
                    value={dateValue}
                    inputFormat={'DD/MM/YYYY'}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          height: 45,
                          backgroundColor: lightTheme.palette.primary.b2,
                          borderRadius: '5px',

                          '& .css-1laqsz7-MuiInputAdornment-root, & .MuiInputAdornment-root':
                            {
                              height: '100%',
                              width: '50px',
                              margin: 0,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',

                              '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root, & .MuiButtonBase-root.MuiIconButton-root':
                                {
                                  margin: 0,
                                  color: '#FFFFFF',
                                },
                            },
                        }}
                      >
                        <input
                          ref={inputRef}
                          {...inputProps}
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '5px 0 0 5px',
                            border: '1px solid #9E9E9E',
                            padding: '10px 15px',
                            fontFamily: 'Century Gothic',
                            fontSize: 14,
                            lineHeight: '140%',
                            letterSpacing: '0.2px',
                            color: lightTheme.palette.text.primary,
                            backgroundColor: '#FFFFFF',
                          }}
                        />
                        {InputProps?.endAdornment}
                      </Box>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Heure</FormLabel>
                  <TimePicker
                    onChange={changeDate}
                    value={dateValue}
                    views={['hours', 'minutes']}
                    inputFormat={'HH:mm'}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          height: 45,
                          backgroundColor: lightTheme.palette.primary.b2,
                          borderRadius: '5px',

                          '& .css-1laqsz7-MuiInputAdornment-root, & .MuiInputAdornment-root':
                            {
                              height: '100%',
                              width: '50px',
                              margin: 0,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',

                              '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root, & .MuiButtonBase-root.MuiIconButton-root':
                                {
                                  margin: 0,
                                  color: '#FFFFFF',
                                },
                            },
                        }}
                      >
                        <input
                          ref={inputRef}
                          {...inputProps}
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '5px 0 0 5px',
                            border: '1px solid #9E9E9E',
                            padding: '10px 15px',
                            fontFamily: 'Century Gothic',
                            fontSize: 14,
                            lineHeight: '140%',
                            letterSpacing: '0.2px',
                            color: lightTheme.palette.text.primary,
                            backgroundColor: '#FFFFFF',
                          }}
                        />
                        {InputProps?.endAdornment}
                      </Box>
                    )}
                  />
                </Grid>
              </LocalizationProvider>
            </Grid>
          </form>
        </BoxContainer>
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          {loadingButton ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={handleSubmit(
                actionValue && type === 'tache'
                  ? onEditTache
                  : actionValue && type !== 'tache'
                  ? onEdit
                  : !actionValue && type === 'tache'
                  ? onSubmitTache
                  : onSubmit
              )}
            >
              {actionValue
                ? 'Modifier'
                : type === 'tache'
                ? 'Ajouter la tâche'
                : t('button.addOffer')}
            </CustomButton>
          )}
        </Box>
      </ModalContainer>
    </Modal>
  )
}

export default ChoiceOfferModal
