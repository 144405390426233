import ApiRequest from '../request/ApiRequest'

export default class Historique {
  constructor() {
    this.ApiRequest = new ApiRequest()
  }

  async list() {
    try {
      const res = await this.ApiRequest.get('historiques')
      return res
    } catch (error) {
      return error.response
    }
  }
  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`historiques/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`historiques/${id}`)
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get(
        'historiques.jsonld',
        data,
        'jsonld'
      )
      return res
    } catch (error) {
      return error.response
    }
  }
}
