import React, { useState } from 'react'
import {
  Box,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
  Tabs,
  Tab,
  Divider,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import moment from 'moment-timezone'
import { faClose, faEye, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { truncate } from '../../config'
import { useNavigate } from 'react-router-dom'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const IconBox = styled(Box)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 auto',

  '&:hover': {
    borderWidth: '2px',
  },
  '&:active': {
    borderWidth: '1px',
  },
}))

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const DetailAdresseEntreprise = ({ open, onClose, adresse, handleEdit }) => {
  let navigate = useNavigate()
  const [contacts, setContacts] = useState(
    adresse.contacts ? adresse.contacts : []
  )
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
        overflow: 'auto',
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          Détail de l'adresse
          <Box
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
          </Box>
        </Typography>
        <Box sx={{ width: '100%' }}>
          {/* <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{
                  color: '#2b9eaa',
                }}
                label="Entité"
                {...a11yProps(0)}
              />
              <Tab
                sx={{
                  color: '#2b9eaa',
                }}
                label="Contacts"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box> */}
          <BoxContainer>
            {/* <CustomTabPanel value={value} index={0}> */}
            <Grid
              container
              spacing={2}
              sx={{
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel>
                  Nom du site : <b>{adresse?.nomSite}</b>
                </FormLabel>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel>
                  Adresse :{' '}
                  <b>
                    {adresse.adresse}, {adresse.ville}, {adresse.codePostal}{' '}
                    {adresse?.pays}
                  </b>
                </FormLabel>
              </Grid>
              {adresse.telephone1 && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel>
                    Numéro de téléphone 1 : <b>{adresse.telephone1}</b>
                  </FormLabel>
                </Grid>
              )}
              {adresse.telephone2 && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel>
                    Numéro de téléphone 2 : <b>{adresse.telephone2}</b>
                  </FormLabel>
                </Grid>
              )}
              {adresse.telephone3 && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel>
                    Numéro de téléphone 3 : <b>{adresse.telephone3}</b>
                  </FormLabel>
                </Grid>
              )}
            </Grid>
            {/* </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  {contacts.map((contct, index) => (
                    <>
                      {' '}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexBasis: 'max-content!important',
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          sx={{
                            padding: 1,
                          }}
                        >
                          {contacts[index].nom} {contacts[index].prenom}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          sx={{
                            padding: 1,
                          }}
                        >
                          {contacts[index].email}<br></br>
                          {contacts[index].telephone}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={1}
                          sx={{
                            padding: 1,
                            alignContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          <IconBox
                            onClick={(e) => {
                              navigate(
                                `/admin/gestion-entreprise/contact-entreprise/${contacts[index].id}`
                              )
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              color={lightTheme.palette.primary.b2}
                            />
                          </IconBox>
                        </Grid>
                      </Grid>
                      <Divider />
                    </>
                  ))}
                </Grid>
              </Grid>
            </CustomTabPanel> */}
          </BoxContainer>
        </Box>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          <CustomButton
            displayIcon={'none'}
            handleClick={() => handleEdit(adresse)}
          >
            Modifier
          </CustomButton>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            Fermer
          </BorderButtonPurple>
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default DetailAdresseEntreprise
