import React, { useState } from 'react'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  Box,
  CircularProgress,
  Typography,
  FormControlLabel,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { CheckboxInput } from '../inputs'

const HeaderTab = styled(TableHead)(({ theme }) => ({
  height: 60,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const TitleTab = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
}))

const BodyTab = styled(TableCell)(({ theme, type }) => ({
  borderBottom:
    type === 'entreprise'
      ? `1px solid ${lightTheme.palette.secondary.main}`
      : type === 'candidat'
      ? `1px solid ${lightTheme.palette.warning.dark}`
      : `1px solid ${lightTheme.palette.secondary.main}`,
}))

const BlockTabSquare = styled(TableCell)(({ theme }) => ({
  width: 50,
  height: 61,
  backgroundColor: lightTheme.palette.warning.dark,
  padding: 0,
  margin: 0,
  paddingLeft: 23,
  borderBottom: `1px solid ${lightTheme.palette.warning.dark}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '5px',

  '.firstSquare, .secondSquare, .thirdSquare, .fourthSquare, .fiveSquare': {
    width: 5,
    height: 5,
    backgroundColor: '#FFFFFF',
  },
}))

const EmptyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic',
  fontSize: 14,
  lineHeight: '140%',
  color: lightTheme.palette.grey.main,
  textAlign: 'center',
  fontStyle: 'italic',
  padding: '15px 0',
  background: 'rgba(0, 0, 0, 0.04)',
}))

const ListTableCvtheque = ({
  type,
  items,
  loading,
  handleClick,
  handleSelect,
  candidatSelected,
}) => {
  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '370px',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
          borderRadius: 0,
          height: '370px',
          overflow: 'hidden',
        }}
      >
        <Table>
          <HeaderTab>
            <TableRow>
              <TableCell width={25} sx={{ padding: 0 }} />

              <TitleTab>Nom</TitleTab>
              <TitleTab>Prénom</TitleTab>
              <TitleTab>Poste</TitleTab>
              <TitleTab sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                Mail
              </TitleTab>
              <TitleTab
                width={150}
                sx={{ display: { xs: 'none', sm: 'table-cell' } }}
              >
                Date
              </TitleTab>
            </TableRow>
          </HeaderTab>
        </Table>
        {/* Scrollable TableBody */}
        <div
          style={{
            maxHeight: '300px', // Set a fixed height for scrolling
            overflowY: 'auto',
          }}
        >
          <Table>
            <TableBody>
              {items?.length > 0 && (
                <>
                  {items.map((item, index) => (
                    <TableRow key={index} sx={{ cursor: 'pointer' }} className="table-row">
                      <BlockTabSquare>
                        <FormControlLabel
                          control={
                            <CheckboxInput
                              value={item.id}
                              onChange={(e) => handleSelect(e, item.id)}
                              checked={candidatSelected.includes(`/api/utilisateurs/${item.id}`)}
                            />
                          }
                        />
                      </BlockTabSquare>
                      <BodyTab
                        type={type}
                        onClick={() => {
                          //setId(item.id)
                          handleClick(`${item.id}`)
                        }}
                      >
                        <Typography
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {item.nom}
                        </Typography>
                      </BodyTab>
                      <BodyTab
                        type={type}
                        onClick={() => {
                          //setId(item.id)
                          handleClick(`${item.id}`)
                        }}
                      >
                        {item.prenom}
                      </BodyTab>
                      <BodyTab
                        type={type}
                        onClick={() => {
                          //setId(item.id)
                          handleClick(`${item.id}`)
                        }}
                      >
                        <Typography
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {item.candidat.poste && (
                            <>{item.candidat.poste.titre}</>
                          )}
                        </Typography>
                      </BodyTab>
                      <BodyTab
                        type={type}
                        sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                        onClick={() => {
                          //setId(item.id)
                          handleClick(`${item.id}`)
                        }}
                      >
                        <Typography
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {item.email}
                        </Typography>
                      </BodyTab>
                      <BodyTab
                        type={type}
                        onClick={() => {
                          //setId(item.id)
                          handleClick(`${item.id}`)
                        }}
                      >
                        {moment(item.date)
                          .tz('Europe/Paris')
                          .format('DD/MM/YYYY') +
                          ' ' +
                          moment(item.date).tz('Europe/Paris').format('HH:mm')}
                      </BodyTab>
                      {/*                     <BodyTab
                      type={type}
                      sx={{
                        padding: '10px',
                        width:
                          userData.data.role === 'ROLE_ADMIN' ||
                          userData.data.role === 'ROLE_SUPER_ADMIN'
                            ? '70px'
                            : '0',
                      }}
                    >
                      <ButtonIcon
                        onClick={() => {
                          setId(item.id)
                          handleClick(`${item.id}`)
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          color={lightTheme.palette.primary.b2}
                          fontSize={16}
                        />
                      </ButtonIcon>
                    </BodyTab> */}
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </div>
        {!items?.length > 0 && (
          <EmptyText>
            pas {type === 'entreprise' ? " d'entreprises" : 'de candidats'}{' '}
            ici...
          </EmptyText>
        )}
      </TableContainer>

      {/*<AppPagination setProducts={(p) => setProducts(p)}/>*/}
    </>
  )
}

export default ListTableCvtheque
