import React, { useEffect, useState } from 'react'
import {
  Box,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
  Autocomplete,
  TextField,
  Tabs,
  Tab,
  Divider,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import { SelectInputBasic, TextInputClassic } from '../inputs'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { userLogout } from '../../store/slices/userAuthReducer'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import TextareaInputClassic from '../inputs/TextareaInputClassic'
import {
  LANGUES,
  SOFTSKILLS,
  TYPE_CONTRAT,
  TYPE_DEVISE,
  VALEURS,
  niveauLanguesList,
} from '../../data'
import { countries } from '../../data'
import {
  faClose,
  faSquarePlus,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
//import isoCountries from "i18n-iso-countries";

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const EditContactEntreprise = ({ open, onClose, onSave, id, contact, role }) => {
  const { t, i18n } = useTranslation()

  const proSchema = yup.object({
    nomSite: yup.string().required('Ce champ est obligatoire.'),
    contact: yup.string().required('Ce champ est obligatoire.'),
    codePostal: yup
      .number()
      .required(t('errorMessage.zipCodeValid'))
      .typeError(t('errorMessage.zipCodeValid')),
  })

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [refresh, setRefresh] = useState(1)

  const [selectedCountry, setSelectedCountry] = useState(
    contact ? { label: contact.pays } : { label: 'France' }
  )
  const [cities, setCities] = useState([])
  const [contacts, setContacts] = useState(contact.contacts ? contact.contacts : [])
  const [selectedCity, setSelectedCity] = useState(
    contact ? contact.ville : 'Paris'
  )
  let typingTimer //timer identifier
  let doneTypingInterval = 800 //time in ms
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      contact: contact ? contact.contact : '',
      ville: contact ? contact.ville : '',
      pays: contact ? contact.pays : '',
      nomSite: contact ? contact.nomSite : '',
      codePostal: contact ? contact.codePostal : '',
    },
    resolver: yupResolver(proSchema),
  })

  useEffect(() => {
    handleCountryChange(selectedCountry, contact)
  }, ['selectedCountry'])

  const handleCountryChange = async (country, contact = null) => {
    setSelectedCountry(country)

    try {
      const result = await Api.countriesCities.getCitiesByCountry(country.label)

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setCities(res.data.data)
        //if (!contact) setSelectedCity(res.data.data[0])
      }
    } catch (error) {
      console.bebug(error)
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)
    let datas = {
      entreprise: '/api/entreprises/' + id,
      pays: selectedCountry.label,
      ville: selectedCity,
      ...data,
    }

    datas.ville = selectedCity
    datas.pays = selectedCountry.label

    if (contacts.length > 0) datas.contacts = contacts

    /*  console.log(datas)
    return */

    //return
    if (contact) {
      try {
        const result = await Api.contact.edit(contact.id, datas)
        const handling = handlingErrors(result)
        if (handling.status === 401) {
          dispatch(userLogout())
          return
        }

        if (handling.ok) {
          const res = handling.data
          setLoading(false)
          onSave()
        } else {
          setLoading(false)
        }
      } catch (error) {
        console.debug('erreur submit !')
      }
    } else {
      try {
        const result = await Api.contact.add(datas, {})
        const handling = handlingErrors(result)
        if (handling.status === 401) {
          dispatch(userLogout())
          return
        }

        if (handling.ok) {
          const res = handling.data
          setLoading(false)
          onSave()
        } else {
          setLoading(false)
        }
      } catch (error) {
        console.debug('erreur submit !')
      }
    }
  }

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleAddContact = () => {
    contacts.push({ nom: '', prenom: '', email: '', telephone: '' })
    setContacts(contacts)
    setRefresh(Math.random())
  }

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
        overflow: 'auto',
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {contact ? "Modifier le contact" : 'Ajouter un contact'}

          <Box
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
          </Box>
        </Typography>
        <Box sx={{ width: '100%' }}>
          {/* <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{
                  color: '#2b9eaa',
                }}
                label="Entité"
                {...a11yProps(0)}
              />
              <Tab
                sx={{
                  color: '#2b9eaa',
                }}
                label="Contacts"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box> */}
          <BoxContainer>
            {/* <CustomTabPanel value={value} index={0}> */}
              <form>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <FormLabel className={'labelForm'}>Nom</FormLabel>
                    <TextInputClassic
                      placeholder={'Nom du site'}
                      type={'text'}
                      register={{ ...register('nomSite') }}
                    />
                    {errors.nomSite && (
                      <Typography className={'error-message'}>
                        {errors.nomSite.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <FormLabel className={'labelForm'}>
                      Pays
                      <span
                        style={{
                          color: lightTheme.palette.error.main,
                        }}
                      >
                        *
                      </span>
                    </FormLabel>
                    <Autocomplete
                      sx={{
                        width: '100%',
                        backgroundColor: '#fff',
                      }}
                      value={selectedCountry ? selectedCountry : {}}
                      onChange={(event, newValue) => {
                        handleCountryChange(newValue)
                      }}
                      id="country-select"
                      options={countries}
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.label} ({option.code})
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <FormLabel className={'labelForm'}>
                      Ville
                      <span
                        style={{
                          color: lightTheme.palette.error.main,
                        }}
                      >
                        *
                      </span>
                    </FormLabel>

                    <Autocomplete
                      sx={{
                        width: '100%',
                        backgroundColor: '#fff',
                      }}
                      value={selectedCity ? selectedCity : {}}
                      onChange={(event, newValue) => {
                        setSelectedCity(newValue)
                      }}
                      id="country-select"
                      options={cities}
                      autoHighlight
                      //getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <FormLabel className={'labelForm'}>
                      {t('contact.zipCode')}
                    </FormLabel>
                    <TextInputClassic
                      placeholder={t('contact.zipCode')}
                      width={'100%'}
                      register={{ ...register('codePostal') }}
                    />
                    {errors.codePostal && (
                      <Typography className={'error-message'}>
                        {errors.codePostal.message}
                      </Typography>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <FormLabel className={'labelForm'}>Adresse</FormLabel>
                    <TextareaInputClassic
                      placeholder={'Adresse'}
                      width={'100%'}
                      register={{ ...register('contact') }}
                    />
                    {errors.contact && (
                      <Typography className={'error-message'}>
                        {errors.contact.message}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </form>
            {/* </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                    minHeight: '400px',
                  }}
                >
                  {contacts.map((contct, index) => (
                    <>
                      {' '}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexBasis: 'max-content!important',
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          sx={{
                            padding: 1,
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            label="Nom"
                            size="small"
                            value={contacts[index].nom}
                            onChange={(event) => {
                              contacts[index].nom = event.target.value
                              setContacts(contacts)
                              setRefresh(Math.random())
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={7}
                          sx={{
                            padding: 1,
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            label="Prénom"
                            size="small"
                            value={contacts[index].prenom}
                            onChange={(event) => {
                              contacts[index].prenom = event.target.value
                              setContacts(contacts)
                              setRefresh(Math.random())
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={1}
                          sx={{
                            padding: 1,
                            alignContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            fontSize={20}
                            style={{ marginTop: '10px', cursor: 'pointer' }}
                            onClick={(e) => {
                              e.preventDefault()
                              contacts.splice(index, 1)
                              setContacts(contacts)
                              setRefresh(Math.random())
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexBasis: 'max-content!important',
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          sx={{
                            padding: 1,
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            label="Email"
                            size="small"
                            value={contacts[index].email}
                            onChange={(event) => {
                              contacts[index].email = event.target.value
                              setContacts(contacts)
                              setRefresh(Math.random())
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={7}
                          sx={{
                            padding: 1,
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            label="Téléphone"
                            size="small"
                            value={contacts[index].telephone}
                            onChange={(event) => {
                              console.log(event.target.value)
                              contacts[index].telephone = event.target.value
                              setContacts(contacts)
                              setRefresh(Math.random())
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={1}
                          sx={{
                            padding: 1,
                            alignContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            cursor: 'pointer',
                          }}
                        ></Grid>
                      </Grid>
                      <Divider />
                    </>
                  ))}
                  <Box
                    width={'100%'}
                    border={'1px dotted #666'}
                    padding={1}
                    borderRadius={1}
                    sx={{ cursor: 'pointer' }}
                    onClick={handleAddContact}
                  >
                    <FontAwesomeIcon
                      icon={faSquarePlus}
                      fontSize={20}
                      color={'#666'}
                      style={{ margin: '0 5px -2px 0' }}
                    />
                    Ajouter un contact
                  </Box>
                </Grid>
              </Grid>
            </CustomTabPanel> */}
          </BoxContainer>
        </Box>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={handleSubmit(onSubmit)}
            >
              {t('button.save')}
            </CustomButton>
          )}
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default EditContactEntreprise
