import Auth from './routes/auth'
import Candidate from './routes/candidate'
import Company from './routes/company'
import Admin from './routes/admin'
import User from './routes/user'
import Offres from './routes/offres'
import Postes from './routes/postes'
import ForgotPassword from './routes/forgotPassword'
import SiteVitrine from './routes/siteVitrine'
import FinalisationInscription from './routes/finalisationInscription'
import Calendar from './routes/calendrier'
import Fonctions from './routes/fonctions'
import Documents from './routes/documents'
import OffresUtilisateurs from './routes/offresUtilisateurs'
import besoinEntreprise from './routes/besoinEntreprise'
import countriesCities from './routes/countriesCities'
import Rate from './routes/rates'
import Favoris from './routes/favoris'
import adresse from './routes/adresse'
import Actions from './routes/actions'
import Contact from './routes/contact'
import Taches from './routes/taches'
import Environnements from './routes/environnements'
import Documentation from './routes/documentation'
import Historique from './routes/historique'

const Api = {
  auth: new Auth(),
  candidate: new Candidate(),
  company: new Company(),
  admin: new Admin(),
  user: new User(),
  contact: new Contact(),
  offer: new Offres(),
  action: new Actions(),
  tache: new Taches(),
  offerUtilisateur: new OffresUtilisateurs(),
  poste: new Postes(),
  environnement: new Environnements(),
  forgotPassword: new ForgotPassword(),
  siteVitrine: new SiteVitrine(),
  finalisationInscription: new FinalisationInscription(),
  calendar: new Calendar(),
  fonction: new Fonctions(),
  document: new Documents(),
  documentation: new Documentation(),
  historique: new Historique(),
  besoinEntreprise: new besoinEntreprise(),
  adresse: new adresse(),
  countriesCities: new countriesCities(),
  rate: new Rate(),
  favoris: new Favoris(),
}

export default Api
