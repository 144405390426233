import ApiRequest from "../request/ApiRequest";

export default class Taches {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post("taches", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async list() {
    try {
      const res = await this.ApiRequest.get("taches");
      return res;
    } catch (error) {
      return error.response;
    }
  }


  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`taches/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`taches/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`taches/${id}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get("taches.json", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }


}