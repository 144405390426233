import * as React from 'react'
import { styled } from '@mui/material/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons'
import lightTheme from '../../styles/theme/lightTheme'
import GedTable from './GedTable'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <FontAwesomeIcon
        icon={faArrowDownToLine}
        color={lightTheme.palette.primary.b2}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export default function GedTabs({ files, dateFile, handleRemove }) {
  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <div>
      {/* <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>CVs</Typography>
        </AccordionSummary>
        <AccordionDetails> */}
          {files.filter((obj) => obj.type === 'Cv' || obj.type === 'cv')
            .length > 0 ? (
            <GedTable
              files={files.filter(
                (obj) => obj.type === 'Cv' || obj.type === 'cv'
              )}
              dateFile={dateFile}
              handleRemove={handleRemove}
            />
          ) : (
            <Typography variant={'caption'}>Aucun fichier</Typography>
          )}
        {/* </AccordionDetails>
      </Accordion> */}
      {/* <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Diplômes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {files.filter((obj) => obj.type === 'Diplômes').length > 0 ? (
            <GedTable
              files={files.filter((obj) => obj.type === 'Diplômes')}
              dateFile={dateFile}
              handleRemove={handleRemove}
            />
          ) : (
            <Typography variant={'caption'}>Aucun fichier</Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Certifications Techniques</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {files.filter((obj) => obj.type === 'Certifications Techniques')
            .length > 0 ? (
            <GedTable
              files={files.filter(
                (obj) => obj.type === 'Certifications Techniques'
              )}
              dateFile={dateFile}
              handleRemove={handleRemove}
            />
          ) : (
            <Typography variant={'caption'}>Aucun fichier</Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>Certificat de travail</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {files.filter((obj) => obj.type === 'Certificat de travail').length >
          0 ? (
            <GedTable
              files={files.filter(
                (obj) => obj.type === 'Certificat de travail'
              )}
              dateFile={dateFile}
              handleRemove={handleRemove}
            />
          ) : (
            <Typography variant={'caption'}>Aucun fichier</Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel6'}
        onChange={handleChange('panel6')}
      >
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography>Lettre de motivation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {files.filter((obj) => obj.type === 'Lettre de motivation').length > 0 ? (
            <GedTable
              files={files.filter((obj) => obj.type === 'Lettre de motivation')}
              dateFile={dateFile}
              handleRemove={handleRemove}
            />
          ) : (
            <Typography variant={'caption'}>Aucun fichier</Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography>Autres</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {files.filter(
            (obj) =>
              obj.type === 'Recommandations' ||
              obj.type === 'References' ||
              obj.type === 'document' ||
              obj.type === 'Autres'
          ).length > 0 ? (
            <GedTable
              files={files.filter(
                (obj) =>
                  obj.type === 'Recommandations' ||
                  obj.type === 'References' ||
                  obj.type === 'document' ||
                  obj.type === 'Autres'
              )}
              dateFile={dateFile}
              handleRemove={handleRemove}
            />
          ) : (
            <Typography variant={'caption'}>Aucun fichier</Typography>
          )}
        </AccordionDetails>
      </Accordion> */}
    </div>
  )
}
