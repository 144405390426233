import * as React from 'react'
import {
  TableCell,
  tableCellClasses,
  styled,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  IconButton,
  CircularProgress,
  Box,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/pro-solid-svg-icons'
import { useSelector } from 'react-redux'
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${lightTheme.palette.primary.b2}`,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: lightTheme.palette.primary.b6,
    color: lightTheme.palette.text.primary,
    fontFamily: 'Century Gothic Bold',
    fontSize: 10,
    lineHeight: '140%',
    letterSpacing: '0.2px',
    textTransform: 'uppercase',
    padding: '20px 5px',
    borderBottom: 'none',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '20px 5px',
  },

  [theme.breakpoints.up('sm')]: {
    [`&.${tableCellClasses.head}`]: {
      fontSize: 12,
    },
  },

  [theme.breakpoints.up('lg')]: {
    [`&.${tableCellClasses.head}`]: {
      fontSize: 14,
    },
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: `1px solid ${lightTheme.palette.primary.b2}`,
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const BlockColor = styled(TableCell)(({ theme }) => ({
  width: 20,
  height: 60,
  backgroundColor: lightTheme.palette.primary.b2,
  padding: 0,
  borderBottom: `1px solid ${lightTheme.palette.primary.b2}`,
}))

const DeleteIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.error.dark}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.error.dark}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const DocumentationTable = ({
  handleClick,
  loading,
  items,
  setId,
  handleRemove,
}) => {
  const userData = useSelector((state) => state.userAuth.user)
  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '40vh',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
          borderRadius: 0,
        }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell align="left">Titre</StyledTableCell>
              <StyledTableCell align="left">Fichier</StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{ display: { xs: 'none', sm: 'table-cell' } }}
              >
                Ajouter par
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{ display: { xs: 'none', sm: 'table-cell' } }}
              >
                Date
              </StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => {
              return (
                <StyledTableRow key={index}>
                  <BlockColor />
                  <StyledTableCell sx={{ textTransform: 'capitalize' }}>
                    {item.titre}
                  </StyledTableCell>
                  <StyledTableCell sx={{ textTransform: 'capitalize' }}>
                    {item.nom}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                  >
                    {item.utilisateur.nom} {item.utilisateur.prenom}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                  >
                    {moment(item.date).tz('Europe/Paris').format('DD/MM/YYYY')}{' '}{moment(item.date).tz('Europe/Paris').format('HH:mm')}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton
                      sx={{
                        border: `1px solid ${lightTheme.palette.primary.b2}`,
                        borderRadius: '3px',
                        backgroundColor: '#FFFFFF',
                      }}
                      onClick={() => {
                        //setId(item.id)
                        /*handleClick(`${item.nom} ${item.prenom}`);*/
                        handleClick(item.fichier)
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{
                          fontSize: 15,
                          color: lightTheme.palette.primary.b2,
                        }}
                      />
                    </IconButton>
                    {(userData.data.role === 'ROLE_ADMIN' ||
                      userData.data.role === 'ROLE_SUPER_ADMIN') && (
                      <IconButton
                        sx={{
                          border: `1px solid ${lightTheme.palette.error.dark}`,
                          borderRadius: '3px',
                          cursor: 'pointer',
                          margin: '0 5px',
                          '&:hover': {
                            border: `1px solid ${lightTheme.palette.error.dark}`,
                          },
                          '&:active': {
                            border: `1px solid ${lightTheme.palette.primary.b2}`,
                          },
                        }}
                        onClick={() => {
                          /*handleClick(`${item.nom} ${item.prenom}`);*/
                          handleRemove(item.id)
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          style={{
                            fontSize: 15,
                            color: lightTheme.palette.error.dark,
                          }}
                        />
                      </IconButton>
                    )}
                  </StyledTableCell>
                  <BlockColor />
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/*<AppPagination setProducts={(p) => setProducts(p)} />*/}
    </>
  )
}

export default DocumentationTable
