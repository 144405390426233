import ApiRequest from '../request/ApiRequest'

export default class Contact {
  constructor() {
    this.ApiRequest = new ApiRequest()
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post("contacts", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`contacts/${id}.json`)
      return res
    } catch (error) {
      return error.response
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`contacts/${id}.json`, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`contacts/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get("contacts.jsonld", data, "jsonld");
      return res;
    } catch (error) {
      return error.response;
    }
  }
}
