export function handlingErrors(res) {
  if (res.status === 401) {
    return {
      status: 401,
      ok: false,
      message: res.data.message,
    }
  } else if (res.status === 200 || res.status === 201 || res.status === 204) {
    return { ok: true, data: res.data, status: 200 }
  } else if (res.status === 422) {
    return {
      ok: false,
      detail: res.data.detail,
      status: 422,
      message: res.data.detail,
    }
  } else if (res.status === 204) {
    return { ok: true, data: {}, status: 204 }
  } else if (res.status === 302) {
    return {
      ok: false,
      status: 302,
      message: res.data,
    }
  } else {
    return {
      status: 0,
      ok: false,
      message: 'Une erreur est survenue, veuillez réessayez plus tard.',
    }
  }
}
