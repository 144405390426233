import React, { useCallback, useEffect, useState } from 'react'
import { AdminHeading } from '../../../components/headings'
import { Box, Grid, styled } from '@mui/material'
import { BorderButtonPurple } from '../../../components/buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import '../../../styles/components/_layouts.scss'
import { SearchInputBasic, SelectInputObject } from '../../../components/inputs'
import { useNavigate, useParams } from 'react-router-dom'
import { handlingErrors } from '../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import lightTheme from '../../../styles/theme/lightTheme'
import Api from '../../../services/api.service'
import { debounce } from '../../../config'
import { useSelector } from 'react-redux'
import { DeleteGeneralModal } from '../../../components/modals'
import AddDocumentation from '../../../components/modals/AddDocumentation'
import DocumentationTable from '../../../components/tables/DocumentationTable'
import AppPagination from '../../../components/paginations'
import HistoriqueTable from '../../../components/tables/HistoriqueTable'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const BlockButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '15px',
  alignItems: 'center',
}))

const ListHistorique = () => {
  let navigate = useNavigate()
  const params = useParams()
  const [id, setId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [historiques, setHistoriques] = useState([])
  const [openDoc, setOpenDoc] = useState(false)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [filter, setFilter] = useState([
    { id: 0, titre: 'Entreprise', filtre: 'utilisateur.entreprise.raisonSocial' },
    { id: 1, titre: 'Poste', filtre: 'poste.titre' },
    { id: 2, titre: 'Lieu', filtre: 'search' },
  ])
  const [filterSelect, setFilterSelect] = useState(filter[0])

  const handleFilterSelect = (event) => {
    const inputValue = event.target.value
    setFilterSelect(inputValue)
  }

  const handleOpenDoc = useCallback(() => setOpenDoc(true), [])

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearch(value)
  }, 800)

  useEffect(() => {
    getDocumentations(page)
  }, [id, search])

  useEffect(() => {
    setPage(1)
    setCount(0)
    getDocumentations(1)
  }, [params.type])

  const getDocumentations = async (page) => {
    let payload = {
      search: search,
    }
    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

   /*  if (filterSelect.id != 2) {
      payload = {
        [filterSelect.filtre]: search,
      }
    } */

    setLoading(true)

    try {
      const result = await Api.historique.listWithFilter({
        type: params.type,
        page: page,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setHistoriques(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }

    setLoading(false)
  }

  const handleClickToProfil = (documentUrl) => {
    window.open(documentUrl, '_blank', 'noopener,noreferrer')
  }

  const handlePageChange = (event, page) => {
    setPage(page)
    getDocumentations(page)
  }

  const [userId, setUserId] = useState()
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)
  const handleConfirmDeleteModal = () => confirmRemoveProfil()

  const handleRemoveProfil = (idUser) => {
    setOpenDeleteModal(true)
    setUserId(idUser)
  }

  const confirmRemoveProfil = async () => {
    setLoadingDelete(true)
    try {
      const result = await Api.documentation.delete(userId)

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setTimeout(() => {
          setOpenDeleteModal(false)
          setLoadingDelete(false)
          getDocumentations()
        }, 1000)
      }

      if (res.status === 0) {
        getDocumentations()
        setLoadingDelete(false)
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      getDocumentations()
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
  }

  /*if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );*/

  const userData = useSelector((state) => state.userAuth.user)
  if (
    userData.data.role !== 'ROLE_ADMIN' &&
    userData.data.role !== 'ROLE_SUPER_ADMIN'
  )
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '40vh',
        }}
      >
        Désolé, vous n'êtes pas autorisé à accéder à cette page
      </Box>
    )

  return (
    <>
      <div style={{ width: '100%' }}>
        {/*{!openCard ? (
          <>*/}
        <AdminHeading handleClickIcon={() => navigate('/admin')}>
          Historique
        </AdminHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            padding: '30px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '40px', sm: '45px' },
          }}
        >
          {/* <WrapperButton sx={{ columnGap: '15px' }}>
            <Grid item>
              <SearchInputBasic onChange={handleSearchChange} />
            </Grid>

            <Grid item>
              <SelectInputObject
                items={filter}
                value={filterSelect}
                handleChange={handleFilterSelect}
              />
            </Grid>
          </WrapperButton> */}

          <HistoriqueTable
            items={historiques}
            setId={setId}
            loading={loading}
            handleClick={handleClickToProfil}
            handleRemove={handleRemoveProfil}
          />
          <AppPagination
            handlePageChange={handlePageChange}
            count={count}
            page={page}
          />
        </Box>
        {/*</>
        ) : (
          <Profile id={id} />
          )}*/}
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default ListHistorique
