import axios from "axios";
import { BASE_URL_UP } from "../../config";
import qs from "qs";


export default class ApiRequest {
  constructor() {
    this.client = null;
    this.api_url = BASE_URL_UP;
    this.api_token = null;
  }
  init = (params, typeHeader) => {
    this.api_token = JSON.parse(localStorage.getItem("user"));
    let headers = {
      Accept: (typeHeader && typeHeader === 'jsonld') ? "*/*" : "application/json" ,
      // "Access-Control-Allow-Origin": "*",
    };
    if (this.api_token && this.api_token.token) {
      headers["content-type"] = "application/json; charset=utf-8";
      headers["Authorization"] = `Bearer ${this.api_token.token}`;
    }
    if(this.api_token && this.api_token.token && typeHeader && typeHeader === 'jsonld'){
      headers["content-type"] = "*/*; charset=utf-8";
      headers["Authorization"] = `Bearer ${this.api_token.token}`;
    }

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
      params,
      paramsSerializer: (params) => qs.stringify(params),
    });
    return this.client;
  };

  getApiUrl() {
    return this.api_url;
  }

  async get(endPoint, params, typeHeader) {
    const res = await this.init(params ? params : {}, typeHeader).get(endPoint);
    return res;
  }

  async post(endPoint, data) {
    const res = await this.init().post(endPoint, data);
    return res;
  }

  async put(endPoint, data) {
    const res = await this.init().put(endPoint, data);
    return res;
  }

  async delete(endPoint) {
    const res = await this.init().delete(endPoint);
    return res;
  }
}
