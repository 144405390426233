import React from 'react'
import { Box, FormLabel, Grid, Modal, styled, Typography } from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import '../../styles/components/_errors.scss'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    height: 500,
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    height: 300,
  },
}))

const DetailOfferModal = ({
  open,
  onClose,
  type = 'action',
  action,
  handleEdit,
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {type === 'action' ? "Détail de l'action" : 'Détail de le tâche'}
          <Box
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
          </Box>
        </Typography>
        <BoxContainer>
          <Grid
            container
            spacing={2}
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              {type === 'action' ? (
                <FormLabel>
                  Action : <b>{action?.action}</b>
                </FormLabel>
              ) : (
                <FormLabel>
                  Tâche : <b>{action?.tache}</b>
                </FormLabel>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                Date de saisie :{' '}
                <b>
                  {moment(action?.date)
                    .tz('Europe/Paris')
                    .format('DD/MM/YYYY HH:mm')}
                </b>
              </FormLabel>
            </Grid>
            {action?.commercial?.id !== 1 && (
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel>
                  Recruteur :{' '}
                  <b>
                    {action?.commercial?.nom} {action?.commercial?.prenom}
                  </b>
                </FormLabel>
              </Grid>
            )}
            {action?.contact?.id && (
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel>
                  Contact :{' '}
                  <b>
                    {action?.contact?.nom} {action?.contact?.prenom}
                  </b>
                </FormLabel>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                Commentaire : <b>{action?.commentaire}</b>
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {type === 'action' ? 'Date de l’action' : 'Date de la tâche'} :{' '}
                <b>
                  {moment(action?.dateRealisation)
                    .tz('Europe/Paris')
                    .format('DD/MM/YYYY HH:mm')}
                </b>
              </FormLabel>
            </Grid>
          </Grid>
        </BoxContainer>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          <CustomButton
            displayIcon={'none'}
            handleClick={() => handleEdit(action)}
          >
            Modifier
          </CustomButton>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.close')}
          </BorderButtonPurple>
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default DetailOfferModal
