import React, { useEffect, useState } from 'react'
import { Box, Pagination } from '@mui/material'

const AppPagination = ({ handlePageChange, count, page, pageSize = 10 }) => {
  useEffect(() => {}, [])

  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
      {Math.ceil(count / pageSize) > 1 && (
        <Pagination
          defaultPage={page}
          siblingCount={2}
          color={'primary'}
          count={Math.ceil(count / pageSize)}
          onChange={handlePageChange}
          boundaryCount={1}
          shape="rounded"
        />
      )}
    </Box>
  )
}

export default AppPagination
