import React, { useEffect, useState } from 'react'
import {
  MasonryLayout,
  MasonryLayoutCompany,
} from '../../../../components/layouts'
import { Box, CircularProgress, styled, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMapMarker,
  faEnvelope,
  faPhone,
  faArrowLeft,
  faPen,
  faCircleExclamation,
  faHome,
  faCircleCheck,
} from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../../styles/theme/lightTheme'
import {
  ChoiceOfferModal,
  EditDocuments,
  EditEvent,
  EditInfos,
  EditList,
} from '../../../../components/modals'
import {
  BiBlockTable,
  GedTable,
  OffreTable,
} from '../../../../components/tables'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { Slide, ToastContainer, toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import moment from 'moment-timezone'
import EditAdresseEntreprise from '../../../../components/modals/EditAdresseEntreprise'
import DeleteGeneralModal from '../../../../components/modals/DeleteGeneralModal'
import EditCommentaire from '../../../../components/modals/EditCommentaire'
import BesoinTable from '../../../../components/tables/BesoinTable'
import { VALEURS } from '../../../../data'
import DetailBesoinEntreprise from '../../../../components/modals/DetailBesoinEntreprise'
import AdresseTable from '../../../../components/tables/AdresseTable'
import MasonryLayoutCompanyContact from '../../../../components/layouts/MasonryLayoutCompanyContact'
import EditInfosContact from '../../../../components/modals/EditInfosContact'
import EditBesoinEntreprise from '../../../../components/modals/EditBesoinEntreprise'
import TacheTable from '../../../../components/tables/TacheTable'
import {
  faMobile,
  faMobileAlt,
  faPenAlt,
  faPenCircle,
  faPhoneOffice,
} from '@fortawesome/pro-solid-svg-icons'
import DetailOfferModal from '../../../../components/modals/DetailOfferModal'

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const TitleSection = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.primary.b2,
  textTransform: 'uppercase',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  width: 80,
  height: 30,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    borderWidth: '2px',
  },

  '&:active': {
    borderWidth: '1px',
  },
}))

const ProfilContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '45px',
  justifyContent: 'space-between',
}))

const WrapperProfilText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: '5px',
}))

const BannerProfil = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 40,
  backgroundColor: lightTheme.palette.primary.b6,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
}))

const WrapperBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const BannerProfilContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const ContactCompanyProfil = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [snapShot, setSnapShot] = useState(null)
  const [entrepriseId, setEntrepriseId] = useState(null)
  const [openBesoin, setOpenBesoin] = useState(false)
  const [openDetailBesoin, setOpenDetailBesoin] = useState(false)
  const [besoins, setBesoins] = useState([])
  const [besoin, setBesoin] = useState(null)
  const [loadingBesoin, setLoadingBesoin] = useState(true)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [besoinDelete, setBesoinDelete] = useState()
  const [loadingDelete, setLoadingDelete] = useState(false)

  const [openDeleteBesoinModal, setOpenDeleteBesoinModal] = useState(false)
  const handleCloseDeleteBesoinModal = () => setOpenDeleteBesoinModal(false)
  const handleConfirmDeleteBesoinModal = () => removeBesoin()

  const handleEditBesoin = (besoin) => {
    setOpenBesoin(true)
    setBesoin(besoin)
  }
  const handleDetailBesoin = (besoin) => {
    setOpenDetailBesoin(true)
    setBesoin(besoin)
  }
  const handleRemoveBesoin = (idBesoin) => {
    setOpenDeleteBesoinModal(true)
    setBesoinDelete(idBesoin)
  }
  const handlePageChange = (event, page) => {
    setPage(page)
    getBesoinsCompany(snapShot.entreprise?.id, page)
  }

  const dispatch = useDispatch()
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    //fetchData()
  }
  const params = useParams()
  const [openAction, setOpenAction] = useState(false)
  const [openTache, setOpenTache] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)

  const handleOpenBesoin = () => setOpenBesoin(true)
  const handleCloseBesoin = () => {
    setOpenBesoin(false)
    setBesoin(null)
    //fetchData()
  }
  const handleCloseDetailBesoin = () => {
    setOpenDetailBesoin(false)
    //fetchData()
  }

  const handleOpenAction = () => setOpenAction(true)
  /*  const handleCloseAction = () => {
    setOpenAction(false)
    //fetchData()
  } */

  const handleOpenTache = () => setOpenTache(true)
  /*  const handleCloseTache = () => {
    setOpenTache(false)
    //fetchData()
  } */

  const handleSave = () => {
    fetchData()
    setOpen(false)
    setOpenBesoin(false)
    setOpenTache(false)
    setOpenDetailTache(false)
    setOpenAction(false)
    setOpenDetailAction(false)
    setAction()
    setTache()
  }

  const handleSaveBesoin = () => {
    setOpenBesoin(false)
    getBesoinsCompany(snapShot.entreprise.id, snapShot.id, page)
  }

  const getBesoinsCompany = async (idCompany, idContact, page) => {
    console.log(idCompany, idContact, page)
    setLoadingBesoin(true)
    try {
      let result = await Api.besoinEntreprise.listWithFilter({
        entreprise: idCompany,
        contact: idContact,
        page: page,
      })

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingBesoin(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling
        setLoadingBesoin(false)
        setBesoins(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
      } else {
        setLoadingBesoin(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      console.debug(error)
      setLoadingBesoin(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }
  const removeBesoin = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.besoinEntreprise.delete(besoinDelete)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const indexBesoin = besoins.findIndex((bs) => bs.id === besoinDelete)

        if (indexBesoin > -1) {
          besoins.splice(indexBesoin, 1)
        }
        setBesoins(besoins)
        setOpenDeleteBesoinModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      console.debug(error)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      let result = await Api.contact.detail(params.userId)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        getBesoinsCompany(res.entreprise.id, res.id, page)
        setEntrepriseId(res.entreprise.profile.id)
        setLoading(false)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      console.debug(error)
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
  }
  useEffect(() => {
    if (params.userId) fetchData()
  }, [params.userId, dispatch])

  const [action, setAction] = useState()
  const [openDeleteActionModal, setOpenDeleteActionModal] = useState(false)
  const [openDetailAction, setOpenDetailAction] = useState(false)
  const handleCloseDeleteActionModal = () => setOpenDeleteActionModal(false)
  const handleConfirmDeleteActionModal = () => removeAction()
  const handleEditAction = (action) => {
    setAction(action)
    setOpenAction(true)
  }
  const handleDetailAction = (action) => {
    setAction(action)
    setOpenDetailAction(true)
  }
  const handleRemoveAction = (action) => {
    setAction(action)
    setOpenDeleteActionModal(true)
  }
  const handleCloseAction = () => {
    setOpenDetailAction(false)
    setAction()
  }
  const handleCloseEditAction = () => {
    setOpenAction(false)
    setOpenTache(false)
    setAction()
  }

  const removeAction = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.action.delete(action)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        fetchData()
        setOpenDeleteActionModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
    setAction('')
  }

  const [tache, setTache] = useState()
  const [openDeleteTacheModal, setOpenDeleteTacheModal] = useState(false)
  const [openDetailTache, setOpenDetailTache] = useState(false)
  const handleCloseDeleteTacheModal = () => setOpenDeleteTacheModal(false)
  const handleConfirmDeleteTacheModal = () => removeTache()
  const handleEditTache = (tache) => {
    setTache(tache)
    setOpenTache(true)
  }
  const handleDetailTache = (tache) => {
    setTache(tache)
    setOpenDetailTache(true)
  }
  const handleRemoveTache = (tache) => {
    setTache(tache)
    setOpenDeleteTacheModal(true)
  }
  const handleCloseTache = () => {
    setOpenDetailTache(false)
    setOpenTache(false)
    setTache()
  }
  const handleCloseEditTache = () => {
    setOpenAction(false)
    setOpenTache(false)
    setTache()
  }

  const removeTache = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.tache.delete(tache)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        fetchData()
        setOpenDeleteTacheModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
    setTache('')
  }

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <div style={{ width: '100%' }}>
      <Button
        sx={{
          margin: '10px',
        }}
        onClick={() =>
          navigate(
            '/admin/gestion-entreprise/profil-entreprise/' + entrepriseId
          )
        }
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          fontSize={15}
          color={lightTheme.palette.primary.b2}
        />{' '}
        <Typography
          variant={'body2'}
          color={lightTheme.palette.primary.b2}
          textAlign={'right'}
          marginLeft={1}
        >
          {'Retour'}
        </Typography>
      </Button>
      <MasonryLayoutCompanyContact
        handleOpenAction={handleOpenAction}
        handleOpenBesoin={handleOpenBesoin}
        handleOpenTache={handleOpenTache}
        profil={
          <ProfilContainer>
            <TitleContainer>
              <TitleSection></TitleSection>
              <IconContainer onClick={handleOpen}>
                <FontAwesomeIcon
                  icon={faPenCircle}
                  fontSize={40}
                  color={lightTheme.palette.primary.b2}
                />
              </IconContainer>
            </TitleContainer>
            <WrapperProfilText>
              <Typography
                sx={{
                  fontFamily: 'Century Gothic Bold',
                  fontSize: { xs: 20 },
                  lineHeight: '120%',
                  color: lightTheme.palette.text.primary,
                  textAlign: 'center',
                  textTransform: 'uppercase',
                }}
              >
                {snapShot.prenom} {snapShot.nom}
              </Typography>
              <Typography
                variant={'subtitle2'}
                textAlign={'center'}
                textTransform={'capitalize'}
              >
                {snapShot.poste?.titre}
              </Typography>
              <Typography
                variant={'subtitle2'}
                textAlign={'center'}
                textTransform={'capitalize'}
                cursor={'pointer'}
              >
                <a
                  href={`/admin/gestion-entreprise/profil-entreprise/${snapShot.entreprise?.profile?.id}`}
                >
                  {snapShot.entreprise?.raisonSocial}
                </a>
              </Typography>
              <Typography
                variant={'body2'}
                color={lightTheme.palette.grey.grey}
                textAlign={'center'}
              >
                {snapShot.environnementsTechniques?.join(', ')}
              </Typography>
            </WrapperProfilText>
            <BannerProfilContainer>
              <WrapperBanner>
                {!snapShot.email || snapShot.email.startsWith('email@') ? (
                  ''
                ) : (
                  <BannerProfil>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <Typography
                      textAlign={'right'}
                      title={snapShot.email}
                      style={{
                        width: '90%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {snapShot.email}
                    </Typography>
                  </BannerProfil>
                )}
                {snapShot.telephone && (
                  <BannerProfil>
                    <FontAwesomeIcon icon={faMobileAlt} />
                    <Typography>{snapShot.telephone}</Typography>
                  </BannerProfil>
                )}
                {snapShot.fixe && (
                  <BannerProfil>
                    <FontAwesomeIcon icon={faPhone} />
                    <Typography>{snapShot.fixe}</Typography>
                  </BannerProfil>
                )}
              </WrapperBanner>
            </BannerProfilContainer>
          </ProfilContainer>
        }
        actions={
          snapShot.actions && (
            <OffreTable
              handleRemoveAction={handleRemoveAction}
              handleEditAction={handleEditAction}
              handleDetailAction={handleDetailAction}
              type={'contact'}
              offres={snapShot.actions.sort((a, b) => b.id - a.id)}
              dateOffer={moment(snapShot.date)
                .tz('Europe/Paris')
                .format('DD/MM/YYYY H:mm')}
            />
          )
        }
        taches={
          snapShot.taches && (
            <TacheTable
              handleRemoveTache={handleRemoveTache}
              handleEditTache={handleEditTache}
              handleDetailTache={handleDetailTache}
              type={'contact'}
              taches={snapShot.taches.sort((a, b) => b.id - a.id)}
              dateOffer={moment(snapShot.date)
                .tz('Europe/Paris')
                .format('DD/MM/YYYY H:mm')}
            />
          )
        }
        besoinClient={
          besoins && (
            <BesoinTable
              besoins={besoins}
              loading={loadingBesoin}
              handleRemove={handleRemoveBesoin}
              handleEdit={handleEditBesoin}
              handleDetailBesoin={handleDetailBesoin}
              handlePageChange={handlePageChange}
              count={count}
              page={page}
              role={'ROLE_ADMIN'}
            />
          )
        }
      />

      {open && (
        <EditInfosContact
          open={open}
          onClose={handleClose}
          id={params.userId}
          onSave={handleSave}
          role={'contact'}
          type={'contact'}
        />
      )}

      {openAction && (
        <ChoiceOfferModal
          open={openAction}
          onClose={handleCloseEditAction}
          onSave={handleSave}
          /* items={offres} */
          /* handleClick={handleSubmit()} */
          candidat={entrepriseId}
          actionValue={action}
          contact={snapShot}
          loading={loadingButton}
          type={'contact'}
          idContact={snapShot.id}
          contacts={[]}
        />
      )}

      {openDetailAction && (
        <DetailOfferModal
          open={openDetailAction}
          onClose={handleCloseAction}
          /* items={offres} */
          /* handleClick={handleSubmit()} */
          action={action}
          candidat={snapShot}
          loading={loadingButton}
          type={'action'}
          handleEdit={handleEditAction}
        />
      )}

      {openDetailTache && (
        <DetailOfferModal
          open={openDetailTache}
          onClose={handleCloseTache}
          /* items={offres} */
          /* handleClick={handleSubmit()} */
          action={tache}
          candidat={snapShot}
          loading={loadingButton}
          type={'tache'}
          handleEdit={handleEditTache}
        />
      )}

      {openDeleteActionModal && (
        <DeleteGeneralModal
          open={openDeleteActionModal}
          onClose={handleCloseDeleteActionModal}
          onConfirm={handleConfirmDeleteActionModal}
          loading={loadingDelete}
        />
      )}

      {openDeleteTacheModal && (
        <DeleteGeneralModal
          open={openDeleteTacheModal}
          onClose={handleCloseDeleteTacheModal}
          onConfirm={handleConfirmDeleteTacheModal}
          loading={loadingDelete}
        />
      )}

      {/*    {openDeleteModal && (
        <DeleteGeneralModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          onConfirm={handleConfirmDeleteModal}
          loading={loadingDelete}
        />
      )} */}

      {openTache && (
        <ChoiceOfferModal
          open={openTache}
          onClose={handleCloseEditTache}
          onSave={handleSave}
          /* items={offres} */
          /* handleClick={handleSubmit()} */
          /* value={offreSelect} */
          actionValue={tache}
          candidat={entrepriseId}
          contact={snapShot}
          loading={loadingButton}
          type={'tache'}
          idContact={snapShot.id}
          contacts={[]}
        />
      )}

      {openBesoin && (
        <EditBesoinEntreprise
          open={openBesoin}
          onClose={handleCloseBesoin}
          onSave={handleSaveBesoin}
          id={snapShot.entreprise.id}
          idContact={snapShot.id}
          besoin={besoin}
        />
      )}

      {openDetailBesoin && (
        <DetailBesoinEntreprise
          open={openDetailBesoin}
          onClose={handleCloseDetailBesoin}
          besoin={besoin}
        />
      )}

      {openDeleteBesoinModal && (
        <DeleteGeneralModal
          open={openDeleteBesoinModal}
          onClose={handleCloseDeleteBesoinModal}
          onConfirm={handleConfirmDeleteBesoinModal}
          loading={loadingDelete}
        />
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </div>
  )
}

export default ContactCompanyProfil
