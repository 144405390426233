import React from 'react'
import { Box, Container, Grid, Paper, styled, Typography } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import { CustomButton } from '../buttons'
import { faCirclePlus, faFilePlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  height: 435,
  borderRadius: 0,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: '20px',
}))

const BannerCircle = styled(Box)(({ theme }) => ({
  backgroundColor: lightTheme.palette.secondary.main,
  height: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '30px',

  '.firstCircle, .secondCircle, .thirdCircle, .fourthCircle, .fiveCircle': {
    width: 10,
    height: 10,
    backgroundColor: lightTheme.palette.secondary.main,
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
  },
}))

const TitleBox = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '30px',
  letterSpacing: '0.4px',
  color: lightTheme.palette.primary.b2,
  textTransform: 'uppercase',
  minHeight: '30px',
}))

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  width: 80,
  height: 50,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    borderWidth: '2px',
  },

  '&:active': {
    borderWidth: '1px',
  },
}))

const WrapperIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
}))

const MasonryLayoutCompanyContact = ({
  profil,
  actions,
  taches,
  besoinClient,
  handleOpenAction,
  handleOpenBesoin,
  handleOpenTache,
}) => {
  return (
    <Container
      maxWidth={'lg'}
      sx={{ paddingTop: '25px', paddingBottom: '25px' }}
    >
      <Grid container spacing={4}>
        {/* Section profil */}
        <Grid item xs={12} sm={12} lg={12} order={1}>
          <BannerCircle
            sx={{
              display: { xs: 'none', lg: 'flex' },
            }}
          >
            <TitleBox style={{ color: '#fff' }}>Contact</TitleBox>
          </BannerCircle>

          <Item
            sx={{
              height: { lg: 'calc(100% - 50px)' },
            }}
          >
            {profil}
          </Item>
        </Grid>

        {/* Section besoin client */}
        <Grid item xs={12} sm={12} lg={12} order={2}>
          <Item
            sx={{
              height: '100%',
            }}
          >
            <TitleContainer>
              <TitleBox>
                <>Besoins client</>
              </TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenBesoin}>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    fontSize={50}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>

            <Box>{besoinClient}</Box>
          </Item>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          order={3}
          position={'relative'}
        >
          <Item
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '20px',
              justifyContent: 'space-between',
            }}
            className={actions ? '' : ''}
          >
            <TitleContainer>
              <TitleBox>Historique des actions</TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenAction}>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    fontSize={50}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>

            <Box overflow={'auto'} width={'100%'} height={'100%'}>
              {actions}
            </Box>
            {/* <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '15px',
              }}
            >
              <CustomButton displayIcon={'none'} handleClick={handleOpenAction}>
                Ajouter une action
              </CustomButton>
            </Box> */}
          </Item>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          order={4}
          position={'relative'}
        >
          <Item
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '20px',
              justifyContent: 'space-between',
            }}
            className={actions ? '' : ''}
          >
            <TitleContainer>
              <TitleBox>Tâches</TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenTache}>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    fontSize={50}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>

            <Box overflow={'auto'} width={'100%'} height={'100%'}>
              {taches}
            </Box>
            {/* <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '15px',
              }}
            >
              <CustomButton displayIcon={'none'} handleClick={handleOpenTache}>
                Ajouter une tâche
              </CustomButton>
            </Box> */}
          </Item>
        </Grid>
      </Grid>
    </Container>
  )
}

export default MasonryLayoutCompanyContact
