import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Grid,
  Modal,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { useDispatch } from 'react-redux'
import { userLogout } from '../../store/slices/userAuthReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  /*  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8), */
}))

const GroupItems = styled('ul')({
  padding: 0,
})

const EditList = ({
  open,
  onClose,
  onSave,
  values,
  datas,
  id,
  role = 'candidat',
  type,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [dataValue, setDataValue] = useState(values)
  const [message, setMessage] = useState('')
  const [title, setTitle] = useState('')
  const dispatch = useDispatch()
  const [competenceOpen, setCompetenceOpen] = useState(false)
  const [loadingCompetence, setLoadingCompetence] = useState(false)
  const [environnements, setEnvironnements] = useState([])

  useEffect(() => {
    switch (type) {
      case 'competences':
        setTitle('Compétences Techniques Principales (5 maximum)')
        getEnvironnements()
        break
      case 'competencesAnnexe':
        setTitle('Compétences Techniques Secondaires')
        getEnvironnements()
        break
      case 'Environnements Techniques':
        setTitle('Environnements techniques')
        getEnvironnements()
        break
      case 'softskils':
        setTitle('SoftSkills')
        break

      default:
        break
    }
  }, [type])

  const onSubmit = async () => {
    setLoading(true)
    try {
      const result = await Api.candidate.edit(id, {
        [type]:
          type === 'softskils'
            ? dataValue
            : dataValue.map((item) => item.technology),
      })
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        dispatch(userLogout())
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoading(false)
        onSave()
      } else {
        console.log('erreur')
        setLoading(false)
      }
    } catch (error) {
      console.log('erreur submit !')
    }
  }

  const onSubmitCompany = async () => {
    setLoading(true)
    try {
      const result = await Api.company.edit(id, { [type]: values })
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        dispatch(userLogout())
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoading(false)
        onSave()
      } else {
        console.log('erreur')
        setLoading(false)
      }
    } catch (error) {
      console.log('erreur submit !')
    }
  }

  const getEnvironnements = async (searchQuery) => {
    let payload = {
      search: searchQuery,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoadingCompetence(true)

    try {
      const result = await Api.environnement.listWithFilter({
        status: true,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.ok) {
        setEnvironnements(res.data)
        const sort = res.data.sort((a, b) => (a.date < b.date ? 1 : -1))
        
        if (!searchQuery || searchQuery === '') {
          setDataValue(
            values
              ? res.data.filter((item) => values.includes(item.technology))
              : []
          )
        }
      }

      if (res.status === 0) {
        console.debug(t('successMessage.somethingWrong'))
      }
    } catch (error) {
      console.debug(error)
    }
    setLoadingCompetence(false)
  }
  let typingTimer //timer identifier
  let doneTypingInterval = 800 //time in ms (5 seconds)

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {t('box.title.editList')} {title}
          <Box
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
          </Box>
        </Typography>
        <BoxContainer>
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            {role === 'ROLE_ENTREPRISE' && <></>}

            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
            >
              {type !== 'softskils' ? (
                <Autocomplete
                  disableClearable
                  multiple
                  value={dataValue ? dataValue : {}}
                  id="asynchronous-environnements"
                  sx={{ width: '100%', minHeight: '45px' }}
                  open={competenceOpen}
                  onChange={(event, newValue) => {
                    setDataValue(newValue)
                  }}
                  onKeyDown={(event, newInputValue) => {
                    clearTimeout(typingTimer)
                    if (event.target.value) {
                      typingTimer = setTimeout(function () {
                        getEnvironnements(event.target.value)
                      }, doneTypingInterval)
                    }
                  }}
                  onOpen={() => {
                    setCompetenceOpen(true)
                  }}
                  onClose={() => {
                    setCompetenceOpen(false)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.technology === value.technology
                  }
                  groupBy={(option) => option.title} // Grouper par "titre"
                  getOptionLabel={(option) => option.technology}
                  options={environnements}
                  loading={loadingCompetence}
                  renderTags={(environnements, getTagProps) =>
                    environnements.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option?.technology}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      id="text-asynchronous-environnements"
                      {...params}
                      sx={{ backgroundColor: '#fff' }}
                      placeholder={t('box.addthe') + title}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingCompetence ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              ) : (
                <Autocomplete
                  sx={{ width: '100%', backgroundColor: '#fff' }}
                  multiple
                  defaultValue={dataValue}
                  options={datas}
                  onChange={(event, newValue) => {
                    setDataValue(newValue)
                  }}
                  freeSolo={
                    type === 'competences' || type === 'competencesAnnexe'
                      ? true
                      : false
                  }
                  renderTags={(dataValue, getTagProps) =>
                    dataValue.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('box.addthe') + title}
                    />
                  )}
                />
              )}
              {/* <TextInputClassic
                value={message}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={'Ajouter et cliquer sur ENTRER'}
                width={'100%'}
              /> */}
            </Grid>
            {/* <Grid item>
              {values &&
                values.map((valeur, i) => (
                  <div key={i} className="list-values">
                    {valeur}
                    <span
                      onClick={(e) => {
                        handleRemoveElement(valeur)
                      }}
                      title="supprimer ?"
                    >
                      x
                    </span>
                  </div>
                ))}
            </Grid> */}
          </Grid>
        </BoxContainer>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={role === 'entreprise' ? onSubmitCompany : onSubmit}
            >
              {t('button.save')}
            </CustomButton>
          )}
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default EditList
