import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPipe,
  faChevronRight,
  faChevronDown,
  faAddressCard,
  faUserPlus,
  faDashboard,
  faFilePdf,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCode,
  faRectangleHistoryCircleUser,
} from '@fortawesome/pro-solid-svg-icons'

export const SidebarData = [
  {
    title: 'Tableau de bord',
    path: 'dashboard',
    icon: <FontAwesomeIcon icon={faDashboard} style={{ fontSize: '20px' }} />,
    subNav: [],
  },
  {
    title: 'Gestion des entreprises',
    path: 'gestion-entreprise/liste/prospect',
    counter: 'entreprise',
    icon: <FontAwesomeIcon icon={faAddressCard} style={{ fontSize: '20px' }} />,
    iconClosed: <FontAwesomeIcon icon={faChevronRight} />,
    iconOpened: <FontAwesomeIcon icon={faChevronDown} />,
    subNav: [
      {
        title: 'Liste des prospects',
        path: 'gestion-entreprise/liste/prospect',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'prospect',
      },
      {
        title: 'Liste des clients',
        path: 'gestion-entreprise/liste/client',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'client',
      },
      {
        title: 'Liste des partenaires',
        path: 'gestion-entreprise/liste/partenaire',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'partenaire',
      },
      {
        title: 'Liste des ESN',
        path: 'gestion-entreprise/liste/esn',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'esn',
      },
      /* {
        title: "Demandes de mise en relation",
        path: "gestion-client/mise-en-relation",
        icon: <FontAwesomeIcon icon={faPipe} />
      }, */
      /* {
        title: "Liste des clients candidats",
        path: "gestion-client/liste-clients-candidats",
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: "candidat"
      }, */
      /* {
        title: "Dem. de validation de résumé",
        path: "gestion-client/validation-resume",
        icon: <FontAwesomeIcon icon={faPipe} />
      }, */
      /*  {
        title: "Fiches de poste",
        path: "gestion-client/liste-besoins-entreprises",
        icon: <FontAwesomeIcon icon={faPipe} />
      }, */
    ],
  },
  {
    title: 'Besoins des entreprises',
    path: 'besoin-entreprise/en-cours',
    counter: 'besoin',
    icon: <FontAwesomeIcon icon={faCode} style={{ fontSize: '20px' }} />,
    iconClosed: <FontAwesomeIcon icon={faChevronRight} />,
    iconOpened: <FontAwesomeIcon icon={faChevronDown} />,
    subNav: [
      {
        title: 'En cours',
        path: 'besoin-entreprise/en-cours',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'encours',
      },
      {
        title: 'Á venir',
        path: 'besoin-entreprise/a-venir',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'avenir',
      },
      {
        title: 'Signé',
        path: 'besoin-entreprise/signer',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'signer',
      },
      {
        title: 'Perdu',
        path: 'besoin-entreprise/perdu',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'perdu',
      },
    ],
  },
  {
    title: 'Gestion des candidats',
    path: 'gestion-prospect/liste-prospects-candidats/scope',
    counter: 'candidat',
    icon: <FontAwesomeIcon icon={faUserPlus} style={{ fontSize: '20px' }} />,
    iconClosed: <FontAwesomeIcon icon={faChevronRight} />,
    iconOpened: <FontAwesomeIcon icon={faChevronDown} />,
    subNav: [
      /* {
        title: "Liste des prospects entreprises",
        path: "gestion-prospect/liste-prospects-entreprises",
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: "entreprise"
      }, */
      {
        title: 'Liste des candidats scope',
        path: 'gestion-prospect/liste-prospects-candidats/scope',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'prospect-scope',
      },
      {
        title: 'Liste des candidats hors scope',
        path: 'gestion-prospect/liste-prospects-candidats/hors-scope',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'prospect-hors-scope',
      },
      {
        title: 'Liste des collaborateurs',
        path: 'gestion-client/liste-clients-candidats',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'client',
      } /* 
      {
        title: "Demandes de rendez-vous",
        path: "gestion-prospect/demandes-de-rendez-vous",
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: "demande"
      }, */,
      ,
    ],
  },
  {
    title: 'Documentation',
    path: 'documentation/commercial',
    counter: 'candidat',
    icon: <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: '20px' }} />,
    iconClosed: <FontAwesomeIcon icon={faChevronRight} />,
    iconOpened: <FontAwesomeIcon icon={faChevronDown} />,
    subNav: [
      /* {
        title: "Liste des prospects entreprises",
        path: "gestion-prospect/liste-prospects-entreprises",
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: "entreprise"
      }, */
      {
        title: 'Commercial',
        path: 'documentation/commercial',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'commercial',
      },
      {
        title: 'Recrutement',
        path: 'documentation/recrutement',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'recrutement',
      },
      {
        title: 'RH',
        path: 'documentation/rh',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'rh',
      },
      ,
      ,
    ],
  },
  {
    title: 'Historique',
    path: 'hitorique',
    icon: (
      <FontAwesomeIcon
        icon={faRectangleHistoryCircleUser}
        style={{ fontSize: '20px' }}
      />
    ),
  },
]
