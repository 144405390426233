import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Button,
  FormControlLabel,
  Switch,
} from '@mui/material'
import { BorderButton, BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import { SelectInputBasic, TextInputClassic } from '../inputs'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { phoneRegExp } from '../../config'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faRemove,
} from '@fortawesome/pro-regular-svg-icons'
import { userLogout } from '../../store/slices/userAuthReducer'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import {
  canalList,
  countries,
  disponibilitesList,
  disponibleSousList,
  domainesActivites,
  LANGUES,
  niveauLanguesList,
  prioriteEntrepriselList,
  statusList,
  statusListEntreprise,
  statusMaritalList,
  typeEntrepriselList,
} from '../../data'
import TextareaInputClassic from '../inputs/TextareaInputClassic'
import {
  faDeleteLeft,
  faSquarePlus,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import ButtonLogoUpload from '../inputs/ButtonLogoUpload'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  minHeight: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  /*  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8), */
}))

const GroupItems = styled('ul')({
  padding: 0,
})

const EditInfos = ({
  open,
  onClose,
  onSave,
  handleOpenPassword,
  id,
  role,
  type,
}) => {
  const userData = useSelector((state) => state.userAuth.user)
  const { t, i18n } = useTranslation()
  const proSchema = yup.object({
    /* prenom: yup.string().required(t('errorMessage.required')),
    nom: yup.string().required(t('errorMessage.required')),
    email: yup
      .string()
      .email(t('errorMessage.emailValid'))
      .required(t('errorMessage.required')),
    telephone: yup
      .string()
      .required(t('errorMessage.required'))
      .matches(phoneRegExp, t('errorMessage.phone'))
      .nullable(), */
    entreprise: yup.object({
      raisonSocial: yup.string().required(t('errorMessage.required')),
      //fonctions: yup.mixed().required(t('errorMessage.required')),
    }),
    /* dateNaissance: yup.string('Ce champ doit être une date valide.'), */
    /* statusMarital: yup.string().required(t('errorMessage.required')), */
    /* codePostal: yup.number().required(t('errorMessage.zipCodeValid')), */
  })

  const parSchema = yup.object({
    prenom: yup.string().required(t('errorMessage.required')),
    nom: yup.string().required(t('errorMessage.required')),
    email: yup
      .string()
      .email(t('errorMessage.emailValid'))
      .required(t('errorMessage.required')),
    telephone: yup
      .string()
      .required(t('errorMessage.required'))
      .matches(phoneRegExp, t('errorMessage.phone'))
      .nullable(),
    /*entreprise.raisonSocial: yup.string().required("Merci de renseigner ce champ."),*/
    /* dateNaissance: yup.string('Ce champ doit être une date valide.'), */
    /* statusMarital: yup.string().required(t('errorMessage.required')), */
    /* codePostal: yup.number().required(t('errorMessage.zipCodeValid')), */
  })
  const [loadingInfos, setLoadingInfos] = useState(true)
  const [loading, setLoading] = useState(false)
  const [loadingPoste, setLoadingPoste] = useState(false)
  const [loadingCommerc, setLoadingCommerc] = useState(false)
  const [snapShot, setSnapShot] = useState(null)
  const [statusMarital, setStatusMarital] = useState('celibataire')
  const dispatch = useDispatch()
  const [fonctions, setFonctions] = useState([])
  const [fonctionSelect, setFonctionSelect] = useState('1')
  const [statusSelect, setStatusSelect] = useState(
    type === 'ROLE_CANDIDAT' ? statusList[0] : statusListEntreprise[0]
  )
  const [dateValue, setDateValue] = useState(
    dayjs(new Date()).format('DD/MM/YYYY')
  )
  const [langues, setLangues] = useState([])
  const [dateChange, setDateChange] = useState(false)
  const [visible, setVisible] = useState(false)
  const [postes, setPostes] = useState([])
  const [posteSelect, setPosteSelect] = useState()
  const [posteOpen, setPosteOpen] = React.useState(false)
  const [errorsJob, setErrorsJob] = useState(false)
  const [openLangues, setOpenLangues] = useState(false)
  const [disponibilitesSelect, setDisponibilitesSelect] = useState(
    disponibilitesList[0]
  )
  const [disponibleSousSelect, setDisponibleSousSelect] = useState(
    disponibleSousList[0]
  )
  const [canalSelect, setCanalSelect] = useState()
  const [dateDisponibleValue, setDateDisponibleValue] = useState(
    dayjs(new Date())
  )
  let typingTimer //timer identifier
  let doneTypingInterval = 800 //time in ms (5 seconds)
  const [selectedCountry, setSelectedCountry] = useState({
    label: 'France',
  })
  const [cities, setCities] = useState([])
  const [selectedCity, setSelectedCity] = useState('Paris')
  const [refresh, setRefresh] = useState(1)
  const [domaineActivites, setDomaineActivites] = useState()

  const [typeSelect, setTypeSelect] = useState()
  const [prioriteSelect, setPrioriteSelect] = useState(
    prioriteEntrepriselList[0]
  )
  const [previewUrl, setPreviewUrl] = useState(null)
  const [file, setFile] = useState('')
  const [errorFiles, setErrorFiles] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(type === 'ROLE_ENTREPRISE' ? proSchema : parSchema),
  })

  useEffect(() => {
    type === 'ROLE_ENTREPRISE' ? getCommerciaux() : getPostes()
    handleCountryChange(true, selectedCountry, '')
  }, [id])

  /*  useEffect(() => {
    console.log('langues', langues)
  }, [refresh]) */

  const handleCountryChange = async (fromFetch, country, city) => {
    setSelectedCountry(country)
    try {
      const result = await Api.countriesCities.getCitiesByCountry(country.label)

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setCities(res.data.data)
        if (fromFetch) {
          fetchData()
        }
        if (city === '') {
          //setSelectedCity(res.data.data[0])
        } else {
          setSelectedCity(city)
        }
      }
    } catch (error) {
      console.bebug(error)
    }
  }
  const [commercials, setCommercials] = useState([])
  const [commercialSelect, setCommercialSelect] = useState([])

  const getCommerciaux = async () => {
    setLoadingCommerc(true)
    try {
      let result = await Api.user.listWithFilter({
        role: [
          'ROLE_COMMERCIAL',
          'ROLE_RECRUTEUR',
          'ROLE_ADMIN',
          'ROLE_SUPER_ADMIN',
        ],
        status: 'active',
      })

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingCommerc(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setCommercials(res)
        if (!loadingInfos && snapShot?.entreprises.length > 0)
          setCommercialSelect(snapShot?.entreprises)
        setLoadingCommerc(false)
      } else {
        setLoadingCommerc(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingCommerc(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const getPostes = async (searchQuery) => {
    let payload = {
      search: searchQuery,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoadingPoste(true)

    try {
      const result = await Api.poste.listWithFilter({
        status: true,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setLoadingPoste(false)
        setPostes(res.data)
        const sort = res.data.sort((a, b) => (a.date < b.date ? 1 : -1))
      }

      if (res.status === 0) {
        toast.error(t('successMessage.somethingWrong'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoadingPoste(false)
  }

  const handleStatusMaritalSelect = (event) => {
    const inputValue = event.target.value
    setStatusMarital(inputValue)
  }

  const fetchData = async () => {
    setLoadingInfos(true)
    try {
      let result = await Api.user.detail(id)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        console.log('erreur')
      }

      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        if (res.statusMarital) setStatusMarital(res.genre)
        if (res.status)
          setStatusSelect(
            type === 'ROLE_CANDIDAT'
              ? statusList.find((stat) => stat.id === res.status)
              : statusListEntreprise.find((stat) => stat.id === res.status)
          )
        if (res.entreprise?.fonctions?.id)
          setFonctionSelect(res.entreprise?.fonctions?.id)
        if (res.candidat?.poste) setPosteSelect(res.candidat?.poste)
        setVisible(res.candidat?.scope)
          
        if (res.candidat?.typeDisponibilite)
          setDisponibilitesSelect(
            disponibilitesList[res.candidat?.typeDisponibilite - 1]
          )
        if (res.candidat?.disponibleSous)
          setDisponibleSousSelect(
            disponibleSousList[res.candidat?.disponibleSous - 1]
          )

        if (res.candidat?.canalSourcing)
          setCanalSelect(canalList[res.candidat?.canalSourcing - 1])

        if (res.candidat?.disponible) {
          setDateDisponibleValue(res.candidat?.disponible)
        }
        if (res.userLangues) setLangues(res.userLangues)

        if (res.dateNaissance) {
          setDateValue(dayjs(new Date(res.dateNaissance)).format('DD/MM/YYYY'))
        }

        if (res.pays) setSelectedCountry({ label: res.pays })
        if (res.ville) setSelectedCity(res.ville)
        if (res.pays && res.ville)
          handleCountryChange(false, { label: res.pays }, res.ville)
        if (res.domainesActivites)
          setDomaineActivites(
            domainesActivites.filter((item) =>
              res.domainesActivites.includes(item.domaine)
            )
          )
        setTypeSelect({ id: res.type, label: res.type, titre: res.type })
        if (res.priorite && res.priorite !== '') {
          setPrioriteSelect(
            prioriteEntrepriselList.find((item) => item.id === res.priorite)
          )
        }

        if (!loadingCommerc && res?.entreprises.length > 0)
          setCommercialSelect(res?.entreprises)
        setPreviewUrl(res.logo)
        reset(res)
        setLoadingInfos(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleFonctionSelect = (event) => {
    const inputValue = event.target.value
    setFonctionSelect(inputValue)
  }
  const changeDate = (dateValue) => {
    setDateValue(dateValue)
    setDateChange(true)
  }
  const changeDateDisponible = (dateValue) => {
    setDateDisponibleValue(dateValue)
  }
  const getFonctions = async () => {
    try {
      let result = await Api.fonction.listWithFilter({
        status: true,
      })
      setFonctions(result)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoading(false)
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoading(false)
        setFonctions(res)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      window.scrollTo(0, 0)
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)

    const {
      candidat,
      userOffres,
      roles,
      admin,
      date,
      lang,
      offre,
      parent,
      password,
      status,
      documents,
      calendriers,
      actionsCandidat,
      besoinEntreprise,
      dateNaissance,
      commentaire,
      codePostal,
      adresses,
      actions,
      langues,
      nationalite,
      taches,
      logo,
      ...rest
    } = data

    if (dateValue)
      rest.dateNaissance = dateChange
        ? dateValue.format('YYYY')
        : dateValue.split('/')[1] +
          '/' +
          dateValue.split('/')[0] +
          '/' +
          dateValue.split('/')[2]
    //if (statusMarital) rest.statusMarital = statusMarital

    if (
      userData.data.role !== 'ROLE_ENTREPRISE' &&
      userData.data.role !== 'ROLE_CANDIDAT'
    ) {
      //rest.status = statusSelect.id
      rest.entreprises = commercialSelect.map(
        (obj) => `/api/utilisateurs/${obj.id}`
      )
    } else {
      delete rest.status
      delete rest.entreprises
    }

    if (type === 'ROLE_ENTREPRISE') {
      rest.entreprise.fonctions = `/api/fonctions/${fonctionSelect}`
      if (domaineActivites && domaineActivites.length > 0)
        rest.domainesActivites = domaineActivites.map((item) => item.domaine)
      rest.type = typeSelect.id
      rest.priorite = prioriteSelect.id
      console.log(data.entreprise.raisonSocial)
      rest.nom = data.entreprise.raisonSocial
      rest.prenom = data.entreprise.raisonSocial
      delete rest.entreprise.calendriers
      delete rest.entreprise.adresses
      delete rest.entreprise.besoinEntreprise
      delete rest.email
    } else {
      rest.candidat = {}
      rest.candidat.id = snapShot.candidat.id
      rest.candidat.poste = `/api/postes/${posteSelect.id}`
      rest.candidat.typeDisponibilite = disponibilitesSelect.id
      rest.candidat.disponibleSous = disponibleSousSelect.id
      rest.candidat.canalSourcing = canalSelect.id
      rest.candidat.disponible = dateDisponibleValue
      rest.candidat.scope = visible
      rest.candidat.salaire = parseInt(data.candidat.salaire)
    }

    rest.pays = selectedCountry.label
    rest.ville = selectedCity
    if (langues.length > 0) {
      rest.langues = langues.map((item) => item.langue)
      let lngs = []
      langues.map((lng, index) =>
        lngs.push({
          langue: lng.langue,
          niveau: lng.niveau,
          /* utilisateur: '/api/utilisateurs/' + id, */
        })
      )
      //console.log(lngs)
      rest.userLangues = lngs
    }

    delete rest.role

    try {
      const result = await Api.user.edit(id, rest)
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        dispatch(userLogout())
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoading(false)
        onSave()
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenLangues = () => {
    setOpenLangues(!openLangues)
  }

  const handleAddLangue = () => {
    langues.push({ langue: LANGUES[24], niveau: niveauLanguesList[0].id })
    setLangues(langues)
    setRefresh(Math.random())
  }

  const handleFileChange = async (event) => {
    const files = event.target.files
    // Create preview URL for the first file
    if (files.length > 0) {
      setLoading(true)

      const formData = new FormData()

      formData.append('id', id)
      Array.from(files).map((doc) => {
        formData.append('logo[]', doc)
      })

      try {
        const result = await Api.user.editLogo(formData)
        const handling = handlingErrors(result)

        if (handling.status === 401) {
          dispatch(userLogout())
          return
        }

        if (handling.ok) {
          const res = handling.data
          setLoading(false)
          setFile(files)
          setValue('files', Array.from(files))
          const fileUrl = URL.createObjectURL(files[0])
          setPreviewUrl(fileUrl)
          onSave()
        } else {
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setPreviewUrl(null)
    }
  }

  // Add this function to clear the file
  const handleClearFile = async () => {
    setLoading(true)
    try {
      const result = await Api.user.edit(id, { logo: '' })
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        dispatch(userLogout())
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoading(false)
        setFile([])
        setValue('files', [])
        setPreviewUrl(null)
        onSave()
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeVisible = (event) => {
    setVisible(!visible)
  }

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
      style={{ overflow: 'auto' }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          Modifier les informations générales
        </Typography>
        <BoxContainer>
          {loadingInfos ? (
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <form>
              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  flexDirection: {
                    xs: 'column',
                    sm: openLangues ? 'column' : 'row',
                  },
                }}
              >
                {!openLangues && (
                  <>
                    {role !== 'ROLE_ENTREPRISE' && role !== 'ROLE_CANDIDAT' && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>Statut</FormLabel>
                          <Autocomplete
                            disableClearable
                            value={statusSelect ? statusSelect : {}}
                            id="statusSelect"
                            sx={{ width: '100%', minHeight: '45px' }}
                            onChange={(event, newValue) => {
                              setStatusSelect(newValue)
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option) => option.titre}
                            options={
                              type === 'ROLE_CANDIDAT'
                                ? statusList
                                : statusListEntreprise
                            }
                            renderInput={(params) => (
                              <TextField
                                id="text-disponibilitesSelect"
                                {...params}
                                sx={{ backgroundColor: '#fff' }}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    {type === 'ROLE_ENTREPRISE' && (
                      <>
                        <Grid item xs={12} sm={3}></Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                            textAlign: 'center',
                          }}
                        >
                          <FormLabel className={'labelForm'}>Logo</FormLabel>
                          {previewUrl ? (
                            <Box mt={2}>
                              <img
                                src={previewUrl}
                                alt="Logo preview"
                                style={{ maxWidth: '100%', maxHeight: '50px' }}
                              />{' '}
                              <br></br>
                              <Button
                                onClick={handleClearFile}
                                startIcon={
                                  <FontAwesomeIcon
                                    icon={faRemove}
                                    fontSize={25}
                                    color={lightTheme.palette.success.main}
                                  />
                                }
                              >
                                Supprimer
                              </Button>
                            </Box>
                          ) : (
                            <ButtonLogoUpload onChange={handleFileChange} />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={3}></Grid>
                        {userData.data.role !== 'ROLE_COMMERCIAL' &&
                          userData.data.role !== 'ROLE_RECRUTEUR' && (
                            <Grid item xs={12} sm={12} lg={12}>
                              <FormLabel className={'labelForm'}>
                                Commercial(s)
                              </FormLabel>
                              {loadingCommerc ? (
                                <>
                                  <br></br>
                                  <CircularProgress />
                                </>
                              ) : (
                                <>
                                  <Autocomplete
                                    sx={{
                                      width: '100%',
                                      backgroundColor: '#fff',
                                    }}
                                    multiple
                                    defaultValue={commercialSelect}
                                    options={commercials}
                                    getOptionLabel={(option) =>
                                      option.nom +
                                      ' ' +
                                      option.prenom +
                                      ' (' +
                                      option.email +
                                      ')'
                                    }
                                    onChange={(event, newValue) => {
                                      setCommercialSelect(newValue)
                                    }}
                                    renderTags={(commercials, getTagProps) =>
                                      commercials.map((commercial, index) => (
                                        <Chip
                                          variant="outlined"
                                          label={
                                            commercial.nom +
                                            ' ' +
                                            commercial.prenom
                                          }
                                          {...getTagProps({ index })}
                                        />
                                      ))
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={' '}
                                      />
                                    )}
                                  />
                                </>
                              )}
                            </Grid>
                          )}
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('box.companyName')}
                          </FormLabel>
                          <TextInputClassic
                            placeholder={t('box.companyName')}
                            type={'text'}
                            register={{
                              ...register('entreprise.raisonSocial'),
                            }}
                          />
                          {errors.entreprise?.raisonSocial && (
                            <Typography className={'error-message'}>
                              {errors.entreprise?.raisonSocial?.message}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            Priorité
                          </FormLabel>
                          <Autocomplete
                            disableClearable
                            value={prioriteSelect}
                            id="perioriteSelect"
                            sx={{ width: '100%', minHeight: '45px' }}
                            onChange={(event, newValue) => {
                              setPrioriteSelect(newValue)
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option) => option.titre}
                            options={prioriteEntrepriselList}
                            renderInput={(params) => (
                              <TextField
                                id="text-perioriteSelect"
                                {...params}
                                sx={{ backgroundColor: '#fff' }}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>Type</FormLabel>
                          <Autocomplete
                            disableClearable
                            value={typeSelect ? typeSelect : {}}
                            id="statusSelect"
                            sx={{ width: '100%', minHeight: '45px' }}
                            onChange={(event, newValue) => {
                              setTypeSelect(newValue)
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option) => option.titre}
                            options={typeEntrepriselList}
                            renderInput={(params) => (
                              <TextField
                                id="text-disponibilitesSelect"
                                {...params}
                                sx={{ backgroundColor: '#fff' }}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>CA</FormLabel>
                          <TextInputClassic
                            placeholder={"Chiffre d'affaire"}
                            type={'text'}
                            register={{
                              ...register('entreprise.ca'),
                            }}
                          />
                          {errors.entreprise?.ca && (
                            <Typography className={'error-message'}>
                              {errors.entreprise?.ca?.message}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            Site web
                          </FormLabel>
                          <TextInputClassic
                            placeholder={'Site web'}
                            type={'text'}
                            register={{
                              ...register('entreprise.siteweb'),
                            }}
                          />
                          {errors.entreprise?.siteweb && (
                            <Typography className={'error-message'}>
                              {errors.entreprise?.siteweb?.message}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            Societe.com
                          </FormLabel>
                          <TextInputClassic
                            placeholder={'Societe.com'}
                            type={'text'}
                            register={{
                              ...register('entreprise.siteinfo'),
                            }}
                          />
                          {errors.entreprise?.siteinfo && (
                            <Typography className={'error-message'}>
                              {errors.entreprise?.siteinfo?.message}
                            </Typography>
                          )}
                        </Grid>
                      </>
                    )}

                    {type === 'ROLE_CANDIDAT' && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                            alignContent: 'left',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            Scope
                          </FormLabel>
                          <FormLabel>
                            Hors Scope
                            {'   '}
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={handleChangeVisible}
                                  defaultChecked={visible}
                                />
                              }
                            />{' '}
                            Scope
                          </FormLabel>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            Canal de Sourcing
                          </FormLabel>
                          <Autocomplete
                            disableClearable
                            value={canalSelect ? canalSelect : {}}
                            id="canalSourcingSelect"
                            sx={{ width: '100%', minHeight: '45px' }}
                            onChange={(event, newValue) => {
                              setCanalSelect(newValue)
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option) => option.titre}
                            options={canalList}
                            renderInput={(params) => (
                              <TextField
                                id="text-canalSourcingSelect"
                                {...params}
                                sx={{ backgroundColor: '#fff' }}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('contact.lastName')}
                          </FormLabel>
                          <TextInputClassic
                            placeholder={t('contact.lastName')}
                            width={'100%'}
                            register={{ ...register('nom') }}
                          />
                          {errors.nom && (
                            <Typography className={'error-message'}>
                              {errors.nom.message}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('contact.firstName')}
                          </FormLabel>
                          <TextInputClassic
                            placeholder={t('contact.firstName')}
                            width={'100%'}
                            register={{ ...register('prenom') }}
                          />
                          {errors.prenom && (
                            <Typography className={'error-message'}>
                              {errors.prenom.message}
                            </Typography>
                          )}
                        </Grid>
                      </>
                    )}

                    {type !== 'ROLE_ENTREPRISE' && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('contact.emailAdress')}
                          </FormLabel>
                          <TextInputClassic
                            placeholder={t('contact.emailAdress')}
                            width={'100%'}
                            register={{ ...register('email') }}
                          />
                          {errors.email && (
                            <Typography className={'error-message'}>
                              {errors.email.message}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('contact.number')}
                          </FormLabel>
                          <TextInputClassic
                            placeholder={t('contact.number')}
                            width={'100%'}
                            register={{ ...register('telephone') }}
                          />
                          {errors.telephone && (
                            <Typography className={'error-message'}>
                              {errors.telephone.message}
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {snapShot?.candidat?.typeProfil ===
                              'Candidat Partenaire ESN' ||
                            snapShot?.candidat?.typeProfil === 'Indépendant'
                              ? 'TJM'
                              : 'Salaire annuel'}
                          </FormLabel>
                          <TextInputClassic
                            placeholder={
                              snapShot?.candidat?.typeProfil ===
                                'Candidat Partenaire ESN' ||
                              snapShot?.candidat?.typeProfil === 'Indépendant'
                                ? '200'
                                : '10000'
                            }
                            type={'text'}
                            register={{ ...register('candidat.salaire') }}
                          />
                          {errors.salaire && (
                            <Typography className={'error-message'}>
                              {errors.salaire.message}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('box.birthYear')}
                          </FormLabel>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18n.language}
                          >
                            <DatePicker
                              /* openTo="year"
                              views={['year']} */
                              onChange={changeDate}
                              value={dateValue}
                              inputFormat={'DD/MM/YYYY'}
                              register={{ ...register('dateNaissance') }}
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: 45,
                                    backgroundColor:
                                      lightTheme.palette.primary.b2,
                                    borderRadius: '5px',

                                    '& .css-1laqsz7-MuiInputAdornment-root, & .MuiInputAdornment-root':
                                      {
                                        height: '100%',
                                        width: '50px',
                                        margin: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',

                                        '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root, & .MuiButtonBase-root.MuiIconButton-root':
                                          {
                                            margin: 0,
                                            color: '#FFFFFF',
                                          },
                                      },
                                  }}
                                >
                                  <input
                                    ref={inputRef}
                                    {...inputProps}
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      borderRadius: '5px 0 0 5px',
                                      border: '1px solid #9E9E9E',
                                      padding: '10px 15px',
                                      fontFamily: 'Century Gothic',
                                      fontSize: 14,
                                      lineHeight: '140%',
                                      letterSpacing: '0.2px',
                                      color: lightTheme.palette.text.primary,
                                      backgroundColor: '#FFFFFF',
                                    }}
                                    register={{ ...register('dateNaissance') }}
                                  />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>
                          {errors.dateNaissance && (
                            <Typography className={'error-message'}>
                              {errors.dateNaissance.message}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('contact.address')}
                          </FormLabel>
                          <TextInputClassic
                            placeholder={t('contact.address')}
                            width={'100%'}
                            register={{ ...register('adresse') }}
                          />
                          {errors.adresse && (
                            <Typography className={'error-message'}>
                              {errors.adresse.message}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('contact.country')}
                          </FormLabel>
                          <Autocomplete
                            disableClearable
                            sx={{
                              width: '100%',
                              backgroundColor: '#fff',
                            }}
                            value={selectedCountry ? selectedCountry : {}}
                            onChange={(event, newValue) => {
                              handleCountryChange(false, newValue, '')
                            }}
                            id="country-select"
                            options={countries}
                            autoHighlight
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                <img
                                  loading="lazy"
                                  width="20"
                                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                  alt=""
                                />
                                {option.label} ({option.code})
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('contact.city')}
                          </FormLabel>
                          <Autocomplete
                            disableClearable
                            sx={{
                              width: '100%',
                              backgroundColor: '#fff',
                            }}
                            value={selectedCity ? selectedCity : {}}
                            onChange={(event, newValue) => {
                              setSelectedCity(newValue)
                            }}
                            freeSolo={true}
                            id="country-select"
                            options={cities}
                            autoHighlight
                            //getOptionLabel={(option) => option.label}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                {option}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                onChange={(event) => {
                                  setSelectedCity(event.target.value)
                                }}
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('contact.zipCode')}
                          </FormLabel>
                          <TextInputClassic
                            placeholder={t('contact.zipCode')}
                            width={'100%'}
                            register={{ ...register('codePostal') }}
                          />
                          {errors.codePostal && (
                            <Typography className={'error-message'}>
                              {errors.codePostal.message}
                            </Typography>
                          )}
                        </Grid>
                      </>
                    )}
                    {role !== 'ROLE_ENTREPRISE' && false && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('box.maritalStatus')}
                          </FormLabel>

                          <SelectInputBasic
                            items={statusMaritalList}
                            value={statusMarital}
                            handleChange={handleStatusMaritalSelect}
                            //register={{ ...register('statusMarital') }}
                            type={'border'}
                          />
                        </Grid>
                      </>
                    )}

                    {type === 'ROLE_ENTREPRISE' && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            Domaines d'activités
                          </FormLabel>
                          <Autocomplete
                            sx={{ width: '100%' }}
                            multiple
                            options={domainesActivites}
                            defaultValue={domaineActivites}
                            groupBy={(option) => option.title} // Grouper par "titre"
                            getOptionLabel={(option) => option.domaine}
                            onChange={(event, newValue) => {
                              setDomaineActivites(newValue)
                            }}
                            renderTags={(competences, getTagProps) =>
                              competences.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option?.domaine}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                sx={{ backgroundColor: '#fff' }}
                                {...params}
                                placeholder="Ajouter les domaines d'activité"
                              />
                            )}
                            renderGroup={(params) => (
                              <li key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                              </li>
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('box.businessPresentation')}
                          </FormLabel>
                          <TextareaInputClassic
                            placeholder={t('box.businessPresentationText')}
                            width={'100%'}
                            register={{
                              ...register('entreprise.presentation'),
                            }}
                          />
                        </Grid>
                      </>
                    )}

                    {type !== 'ROLE_ENTREPRISE' && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('box.nationality')}
                          </FormLabel>
                          <TextInputClassic
                            placeholder={t('box.nationality')}
                            width={'100%'}
                            register={{ ...register('nationalite') }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('filter.poste')}
                          </FormLabel>
                          <Autocomplete
                            disableClearable
                            value={posteSelect ? posteSelect : {}}
                            id="asynchronous-postes"
                            sx={{ width: '100%', minHeight: '45px' }}
                            open={posteOpen}
                            onChange={(event, newValue) => {
                              setPosteSelect(newValue)
                            }}
                            onKeyDown={(event, newInputValue) => {
                              clearTimeout(typingTimer)
                              if (event.target.value) {
                                typingTimer = setTimeout(function () {
                                  getPostes(event.target.value)
                                }, doneTypingInterval)
                              }
                            }}
                            onOpen={() => {
                              setPosteOpen(true)
                            }}
                            onClose={() => {
                              setPosteOpen(false)
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.titre === value.titre
                            }
                            getOptionLabel={(option) => option.titre}
                            options={postes}
                            loading={loadingPoste}
                            renderInput={(params) => (
                              <TextField
                                id="text-asynchronous-postes"
                                {...params}
                                sx={{ backgroundColor: '#fff' }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {loadingPoste ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                          {errorsJob && (
                            <Typography className={'error-message'}>
                              {t('errorMessage.poste')}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('box.available')}
                          </FormLabel>
                          <Autocomplete
                            disableClearable
                            value={
                              disponibilitesSelect ? disponibilitesSelect : {}
                            }
                            id="disponibilitesSelect"
                            sx={{ width: '100%', minHeight: '45px' }}
                            onChange={(event, newValue) => {
                              setDisponibilitesSelect(newValue)
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.titre === value.titre
                            }
                            getOptionLabel={(option) => option.titre}
                            options={disponibilitesList}
                            renderInput={(params) => (
                              <TextField
                                id="text-disponibilitesSelect"
                                {...params}
                                sx={{ backgroundColor: '#fff' }}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                        </Grid>
                        {disponibilitesSelect.id === 2 && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '5px',
                            }}
                          >
                            <FormLabel className={'labelForm'}>
                              {t('box.availableWhen')}
                            </FormLabel>
                            <Autocomplete
                              disableClearable
                              value={
                                disponibleSousSelect ? disponibleSousSelect : {}
                              }
                              id="disponibleSousSelect"
                              sx={{ width: '100%', minHeight: '45px' }}
                              onChange={(event, newValue) => {
                                setDisponibleSousSelect(newValue)
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.titre === value.titre
                              }
                              getOptionLabel={(option) => option.titre}
                              options={disponibleSousList}
                              renderInput={(params) => (
                                <TextField
                                  id="text-disponibleSousSelect"
                                  {...params}
                                  sx={{ backgroundColor: '#fff' }}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        {disponibilitesSelect.id === 4 && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '5px',
                            }}
                          >
                            <FormLabel className={'labelForm'}>
                              {t('box.availableWhen')}
                            </FormLabel>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={'fr'}
                            >
                              <DatePicker
                                /* openTo="year"
                            views={['year']} */
                                onChange={changeDateDisponible}
                                value={dateDisponibleValue}
                                inputFormat={'DD/MM/YYYY'}
                                register={{ ...register('disponible') }}
                                renderInput={({
                                  inputRef,
                                  inputProps,
                                  InputProps,
                                }) => (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      width: '100%',
                                      height: 45,
                                      backgroundColor:
                                        lightTheme.palette.primary.b2,
                                      borderRadius: '5px',

                                      '& .css-1laqsz7-MuiInputAdornment-root, & .MuiInputAdornment-root':
                                        {
                                          height: '100%',
                                          width: '50px',
                                          margin: 0,
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',

                                          '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root, & .MuiButtonBase-root.MuiIconButton-root':
                                            {
                                              margin: 0,
                                              color: '#FFFFFF',
                                            },
                                        },
                                    }}
                                  >
                                    <input
                                      ref={inputRef}
                                      {...inputProps}
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '5px 0 0 5px',
                                        border: '1px solid #9E9E9E',
                                        padding: '10px 15px',
                                        fontFamily: 'Century Gothic',
                                        fontSize: 14,
                                        lineHeight: '140%',
                                        letterSpacing: '0.2px',
                                        color: lightTheme.palette.text.primary,
                                        backgroundColor: '#FFFFFF',
                                      }}
                                      register={{ ...register('disponible') }}
                                    />
                                    {InputProps?.endAdornment}
                                  </Box>
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                )}
                {openLangues && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '5px',
                        minHeight: '400px',
                      }}
                    >
                      <FormLabel className={'labelForm'}>
                        {t('filter.languages')}
                      </FormLabel>
                      {langues.map((lng, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            sx={{
                              padding: 1,
                            }}
                          >
                            <Autocomplete
                              sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                              }}
                              options={LANGUES}
                              value={lng.langue}
                              freeSolo
                              onChange={(event, newValue) => {
                                langues[index].langue = newValue
                                setLangues(langues)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={t('box.addLanguages')}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={7}
                            sx={{
                              padding: 1,
                            }}
                          >
                            <Autocomplete
                              disableClearable
                              value={niveauLanguesList[lng.niveau - 1]}
                              sx={{ width: '100%', minHeight: '45px' }}
                              onChange={(event, newValue) => {
                                langues[index].niveau = newValue.id
                                setLangues(langues)
                                setRefresh(Math.random())
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.titre === value.titre
                              }
                              getOptionLabel={(option) => option.titre}
                              options={niveauLanguesList}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{ backgroundColor: '#fff' }}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={1}
                            sx={{
                              padding: 1,
                              alignContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              fontSize={20}
                              style={{ marginTop: '10px', cursor: 'pointer' }}
                              onClick={(e) => {
                                e.preventDefault()
                                langues.splice(index, 1)
                                setLangues(langues)
                                setRefresh(Math.random())
                              }}
                            />
                          </Grid>
                        </Grid>
                      ))}
                      <Box
                        width={'100%'}
                        border={'1px dotted #666'}
                        padding={1}
                        borderRadius={1}
                        sx={{ cursor: 'pointer' }}
                        onClick={handleAddLangue}
                      >
                        <FontAwesomeIcon
                          icon={faSquarePlus}
                          fontSize={20}
                          color={'#666'}
                          style={{ margin: '0 5px -2px 0' }}
                        />
                        {t('box.addOtherLanguage')}
                      </Box>
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {type !== 'ROLE_ENTREPRISE' && (
                    <BorderButtonPurple
                      width={{ xs: '100%', sm: 240, lg: 300 }}
                      handleClick={handleOpenLangues}
                    >
                      {openLangues
                        ? t('button.back')
                        : t('button.editLanguages')}
                    </BorderButtonPurple>
                  )}
                  {/* <BorderButtonPurple
                    width={{ xs: '100%', sm: 240, lg: 300 }}
                    handleClick={handleOpenPassword}
                  >
                    {t('button.editPassword')}
                  </BorderButtonPurple> */}
                </Grid>
              </Grid>
            </form>
          )}
        </BoxContainer>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={handleSubmit(onSubmit)}
            >
              {t('button.save')}
            </CustomButton>
          )}
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default EditInfos
