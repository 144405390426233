import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell, TableBody
} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDownToLine, faCheck} from "@fortawesome/pro-solid-svg-icons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const BodyTab = styled(Tab)(({theme}) => ({
  color: lightTheme.palette.primary.main,
  fontFamily: "Century Gothic Bold",
  fontSize: 12,
  letterSpacing: "0.2px",
  lineHeight: "140%",

  "&.Mui-selected": {
    background: lightTheme.palette.grey.light,
    color: lightTheme.palette.grey.main,
  }
}));

const TablePanelBox = styled(TabPanel)(({theme}) => ({
  height: 280,
  backgroundColor: "rgb(227, 230, 255, 0.5)",
  "& .MuiBox-root": {
    padding: 0
  }
}));

const HeaderTab = styled(TableHead)(({theme}) => ({
  height: 50,
  backgroundColor: lightTheme.palette.primary.b6
}));

const TitleHead = styled(TableCell)(({theme}) => ({
  fontFamily: "Century Gothic Bold",
  fontSize: 10,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up("sm")]: {
    fontSize: 12
  },
}))

const BodyTable = styled(TableBody)(({theme}) => ({
  backgroundColor: "#FFFFFF"
}));

const StyledTableCell = styled(TableCell)(({theme}) => ({
  borderBottom: "2px solid rgb(227, 230, 255, 0.5)",
  fontFamily: "Century Gothic",
  fontSize: 10,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up("sm")]: {
    fontSize: 12
  },
}));

const IconBox = styled(Box)(({theme}) => ({
  width: 30,
  height: 30,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: "3px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  margin: "0 auto",

  "&:hover": {
    borderWidth: "2px",
  },
  "&:active": {
    borderWidth: "1px",
  }
}));


export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: "100%" }}>
      <AppBar position="static" sx={{boxShadow: "none"}}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="none"

          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            backgroundColor: "#FFFFFF",
          }}
        >
          <BodyTab label="En cours" {...a11yProps(0)} />
          <BodyTab label="Finalisé" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        sx={{
          "&::-webkit-scrollbar": {
            width: 10
          },
          "&::-webkit-scrollbar-track": {
            background: lightTheme.palette.primary.b5
          },
          "&::-webkit-scrollbar-thumb": {
            background: lightTheme.palette.primary.b3
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: lightTheme.palette.primary.b2
          }
        }}
      >
        <TablePanelBox value={value} index={0} dir={theme.direction}>
          <TableContainer>
            <Table>
              <HeaderTab>
                <TableRow>
                  <TitleHead>Nom du document</TitleHead>
                  <TitleHead>Extensions</TitleHead>
                  <TitleHead>Ajout</TitleHead>
                  <TableCell/>
                  <TableCell/>
                </TableRow>
              </HeaderTab>
              <BodyTable>
                <TableRow>
                  <StyledTableCell>Besoin DevOps</StyledTableCell>
                  <StyledTableCell>.docx</StyledTableCell>
                  <StyledTableCell>27/11/22</StyledTableCell>
                  <StyledTableCell sx={{padding: "10px", width: 50,}}>
                    <IconBox>
                      <FontAwesomeIcon icon={faArrowDownToLine} color={lightTheme.palette.primary.b2}/>
                    </IconBox>
                  </StyledTableCell>
                  <StyledTableCell sx={{padding: "10px", width: 50}}>
                    <IconBox>
                      <FontAwesomeIcon icon={faCheck} color={lightTheme.palette.primary.b2}/>
                    </IconBox>
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>Besoin dev. PHP</StyledTableCell>
                  <StyledTableCell>.pdf</StyledTableCell>
                  <StyledTableCell>27/11/22</StyledTableCell>
                  <StyledTableCell sx={{padding: "10px", width: 50}}>
                    <IconBox>
                      <FontAwesomeIcon icon={faArrowDownToLine} color={lightTheme.palette.primary.b2}/>
                    </IconBox>
                  </StyledTableCell>
                  <StyledTableCell sx={{padding: "10px", width: 50}}>
                    <IconBox>
                      <FontAwesomeIcon icon={faCheck} color={lightTheme.palette.primary.b2}/>
                    </IconBox>
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>Besoin lead Python</StyledTableCell>
                  <StyledTableCell>.docx</StyledTableCell>
                  <StyledTableCell>27/11/22</StyledTableCell>
                  <StyledTableCell sx={{padding: "10px", width: 50}}>
                    <IconBox>
                      <FontAwesomeIcon icon={faArrowDownToLine} color={lightTheme.palette.primary.b2}/>
                    </IconBox>
                  </StyledTableCell>
                  <StyledTableCell sx={{padding: "10px", width: 50}}>
                    <IconBox>
                      <FontAwesomeIcon icon={faCheck} color={lightTheme.palette.primary.b2}/>
                    </IconBox>
                  </StyledTableCell>
                </TableRow>
              </BodyTable>
            </Table>
          </TableContainer>
        </TablePanelBox>
        <TablePanelBox value={value} index={1} dir={theme.direction}>
          <TableContainer>
            <Table>
              <HeaderTab>
                <TableRow>
                  <TitleHead>Nom du document</TitleHead>
                  <TitleHead>Extensions</TitleHead>
                  <TitleHead>Ajout</TitleHead>
                </TableRow>
              </HeaderTab>
              <BodyTable>
                <TableRow>
                  <StyledTableCell>Besoin dev. PHP</StyledTableCell>
                  <StyledTableCell>.pdf</StyledTableCell>
                  <StyledTableCell>27/11/22</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>Besoin lead Python</StyledTableCell>
                  <StyledTableCell>.docx</StyledTableCell>
                  <StyledTableCell>27/11/22</StyledTableCell>
                </TableRow>
              </BodyTable>
            </Table>
          </TableContainer>
        </TablePanelBox>
      </SwipeableViews>
    </Box>
  );
}