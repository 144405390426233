import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import { TextInputClassic } from '../inputs'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { phoneRegExp } from '../../config'
import { userLogout } from '../../store/slices/userAuthReducer'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { environnementsTechniques } from '../../data'
import TextareaInputClassic from '../inputs/TextareaInputClassic'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  minHeight: 250,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: 200,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  /*  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8), */
}))

const GroupItems = styled('ul')({
  padding: 0,
})

const EditInfosContact = ({ open, onClose, onSave, id, idCompany }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const proSchema = yup.object({
    prenom: yup.string().required(t('errorMessage.required')),
    nom: yup.string().required(t('errorMessage.required')),
    /* email: yup
      .string()
      .email(t('errorMessage.emailValid'))
      .required(t('errorMessage.required')),
    telephone: yup
      .string()
      .required(t('errorMessage.required'))
      .matches(phoneRegExp, t('errorMessage.phone'))
      .nullable(),
    fixe: yup
      .string()
      .required(t('errorMessage.required'))
      .matches(phoneRegExp, t('errorMessage.phone'))
      .nullable(), */
  })

  const [loadingInfos, setLoadingInfos] = useState(true)
  const [loading, setLoading] = useState(false)
  const [snapShot, setSnapShot] = useState(null)
  const [loadingPoste, setLoadingPoste] = useState(false)
  const [loadingAdresse, setLoadingAdresse] = useState(false)
  const [postes, setPostes] = useState([])
  const [adresses, setAdresses] = useState([])
  const [posteSelect, setPosteSelect] = useState()
  const [adresseSelect, setAdresseSelect] = useState()
  const [posteOpen, setPosteOpen] = React.useState(false)
  const [adresseOpen, setAdresseOpen] = React.useState(false)
  const [competences, setCompetences] = useState([])
  const [selectedTypeValue, setSelectedTypeValue] = useState('')
  const [competenceOpen, setCompetenceOpen] = useState(false)
  const [loadingCompetence, setLoadingCompetence] = useState(false)
  const [environnements, setEnvironnements] = useState([])
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(proSchema),
  })

  console.log(idCompany)

  useEffect(() => {
    getPostes()
    getAdresses()
    getEnvironnements()
    if (id) {
      fetchData()
    } else {
      setLoadingInfos(false)
    }
  }, [id])

  const [isLoadingCompetence, setIsLoadingCompetence] = useState(true)
  useEffect(() => {
    if (
      isLoadingCompetence &&
      snapShot &&
      snapShot?.environnementsTechniques &&
      environnements.length > 0
    ) {
      setCompetences(
        snapShot?.environnementsTechniques
          ? environnements.filter((item) =>
              snapShot?.environnementsTechniques.includes(item.technology)
            )
          : []
      )
      setIsLoadingCompetence(false)
    }
  }, [snapShot, environnements])

  const handleChangeType = (event) => {
    const inputValue = event.target.value
    setSelectedTypeValue(inputValue)
  }

  const fetchData = async () => {
    setLoadingInfos(true)
    try {
      let result = await Api.contact.detail(id)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        console.log('erreur')
      }

      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        reset(res)
        /*setCompetences(
          environnements.filter((item) =>
            res.environnementsTechniques.includes(item.technology)
          )
        )*/
        setPosteSelect(res.poste)
        setAdresseSelect(res.entite)
        setSelectedTypeValue(res.civilite)
        setLoadingInfos(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getPostes = async (searchQuery) => {
    let payload = {
      search: searchQuery,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoadingPoste(true)

    try {
      const result = await Api.poste.listWithFilter({
        status: true,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setLoadingPoste(false)
        setPostes(res.data)

        //setPosteSelect(res.data[0])
        const sort = res.data.sort((a, b) => (a.date < b.date ? 1 : -1))
      }

      if (res.status === 0) {
        toast.error(t('successMessage.somethingWrong'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoadingPoste(false)
  }

  const getEnvironnements = async (searchQuery) => {
    let payload = {
      search: searchQuery,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoadingCompetence(true)

    try {
      const result = await Api.environnement.listWithFilter({
        status: true,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setEnvironnements(res.data)
        const sort = res.data.sort((a, b) => (a.date < b.date ? 1 : -1))

        /*  if (besoin) {
          setPosteSelect(besoin.poste)
        } else {
          setPosteSelect(res.data[0])
        } */
      }

      if (res.status === 0) {
        toast.error(t('successMessage.somethingWrong'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoadingCompetence(false)
  }

  const getAdresses = async (searchQuery) => {
    let payload = {
      search: searchQuery,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoadingAdresse(true)

    try {
      const result = await Api.adresse.listSimpleWithFilter({
        entreprise: idCompany,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setLoadingAdresse(false)
        setAdresses(res.data)
        //setAdresseSelect(res.data[0])
        /* const sort = res.data.sort((a, b) => (a.date < b.date ? 1 : -1)) */
      }

      if (res.status === 0) {
        toast.error(t('successMessage.somethingWrong'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoadingAdresse(false)
  }

  const onEdit = async (data) => {
    setLoading(true)

    const { entite, id, actions, besoinEntreprises, taches, entreprise, ...rest } = data

    rest.civilite = selectedTypeValue
    if(competences.length > 0) rest.environnementsTechniques = competences.map((item) => item.technology)
    if(posteSelect) rest.poste = '/api/postes/' + posteSelect.id
    if (adresseSelect && adresseSelect.id !== null)
      rest.entite = '/api/adresses/' + adresseSelect.id

    try {
      const result = await Api.contact.edit(id, rest)
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        dispatch(userLogout())
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoading(false)
        onSave()
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)

    data.civilite = selectedTypeValue
    if(competences.length > 0) data.environnementsTechniques = competences.map((item) => item.technology)
    if (posteSelect && posteSelect.id !== null) data.poste = '/api/postes/' + posteSelect.id
    if (adresseSelect && adresseSelect.id !== null) data.entite = '/api/adresses/' + adresseSelect.id
    data.entreprise = '/api/entreprises/' + idCompany

    try {
      const result = await Api.contact.add(data)
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        dispatch(userLogout())
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoading(false)
        onSave()
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  let typingTimer //timer identifier
  let doneTypingInterval = 800 //time in ms (5 seconds)

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
      style={{ overflow: 'auto' }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {id
            ? 'Modifier les informations générales du contact'
            : 'Ajouter un contact'}
        </Typography>
        <BoxContainer>
          {loadingInfos ? (
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <form>
              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Civilité</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel
                      onChange={handleChangeType}
                      checked={selectedTypeValue === 'Madame'}
                      value="Madame"
                      control={<Radio />}
                      label="Madame"
                    />
                    <FormControlLabel
                      onChange={handleChangeType}
                      checked={selectedTypeValue === 'Monsieur'}
                      value="Monsieur"
                      control={<Radio />}
                      label="Monsieur"
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Adresse</FormLabel>
                  <Autocomplete
                    disableClearable
                    value={adresseSelect ? adresseSelect : { id: null, nomSite: 'Aucune adresse' }}
                    id="asynchronous-adresses"
                    sx={{ width: '100%', minHeight: '45px' }}
                    open={adresseOpen}
                    onChange={(event, newValue) => {
                      setAdresseSelect(newValue)
                    }}
                    onKeyDown={(event, newInputValue) => {
                      clearTimeout(typingTimer)
                      if (event.target.value) {
                        typingTimer = setTimeout(function () {
                          getAdresses(event.target.value)
                        }, doneTypingInterval)
                      }
                    }}
                    onOpen={() => {
                      setAdresseOpen(true)
                    }}
                    onClose={() => {
                      setAdresseOpen(false)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.nomSite === value.nomSite
                    }
                    getOptionLabel={(option) => option.nomSite}
                    options={[
                      { id: null, nomSite: 'Aucune adresse' },
                      ...adresses,
                    ]}
                    loading={loadingAdresse}
                    renderInput={(params) => (
                      <TextField
                        id="text-asynchronous-adresses"
                        {...params}
                        sx={{ backgroundColor: '#fff' }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingAdresse ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {/* {errorsJob && (
                            <Typography className={'error-message'}>
                              {t('errorMessage.poste')}
                            </Typography>
                          )} */}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>
                    {t('contact.lastName')}
                  </FormLabel>
                  <TextInputClassic
                    placeholder={t('contact.lastName')}
                    width={'100%'}
                    register={{ ...register('nom') }}
                  />
                  {errors.nom && (
                    <Typography className={'error-message'}>
                      {errors.nom.message}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>
                    {t('contact.firstName')}
                  </FormLabel>
                  <TextInputClassic
                    placeholder={t('contact.firstName')}
                    width={'100%'}
                    register={{ ...register('prenom') }}
                  />
                  {errors.prenom && (
                    <Typography className={'error-message'}>
                      {errors.prenom.message}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>
                    {t('filter.poste')}
                  </FormLabel>
                  <Autocomplete
                    disableClearable
                    value={posteSelect ? posteSelect : { titre: '' }}
                    id="asynchronous-postes"
                    sx={{ width: '100%', minHeight: '45px' }}
                    open={posteOpen}
                    onChange={(event, newValue) => {
                      setPosteSelect(newValue)
                    }}
                    onKeyDown={(event, newInputValue) => {
                      clearTimeout(typingTimer)
                      if (event.target.value) {
                        typingTimer = setTimeout(function () {
                          getPostes(event.target.value)
                        }, doneTypingInterval)
                      }
                    }}
                    onOpen={() => {
                      setPosteOpen(true)
                    }}
                    onClose={() => {
                      setPosteOpen(false)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.titre === value.titre
                    }
                    getOptionLabel={(option) => option.titre}
                    options={postes}
                    loading={loadingPoste}
                    renderInput={(params) => (
                      <TextField
                        id="text-asynchronous-postes"
                        {...params}
                        sx={{ backgroundColor: '#fff' }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingPoste ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {/* {errorsJob && (
                            <Typography className={'error-message'}>
                              {t('errorMessage.poste')}
                            </Typography>
                          )} */}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>
                    {t('contact.emailAdress')}
                  </FormLabel>
                  <TextInputClassic
                    placeholder={t('contact.emailAdress')}
                    width={'100%'}
                    register={{ ...register('email') }}
                  />
                  {errors.email && (
                    <Typography className={'error-message'}>
                      {errors.email.message}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Portable</FormLabel>
                  <TextInputClassic
                    placeholder={t('contact.number')}
                    width={'100%'}
                    register={{ ...register('telephone') }}
                  />
                  {errors.telephone && (
                    <Typography className={'error-message'}>
                      {errors.telephone.message}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Fixe</FormLabel>
                  <TextInputClassic
                    placeholder={t('contact.number')}
                    width={'100%'}
                    register={{ ...register('fixe') }}
                  />
                  {errors.fixe && (
                    <Typography className={'error-message'}>
                      {errors.fixe.message}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Profil Linkedin</FormLabel>
                  <TextInputClassic
                    placeholder={'Profil Linkedin'}
                    width={'100%'}
                    register={{ ...register('linkedin') }}
                  />
                  {errors.linkedin && (
                    <Typography className={'error-message'}>
                      {errors.linkedin.message}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                  xs={12}
                  sm={12}
                  lg={12}
                >
                  <FormLabel className={'labelForm'}>
                    Environnements Techniques
                  </FormLabel>

                  <Autocomplete
                    disableClearable
                    multiple
                    value={competences ? competences : {}}
                    id="asynchronous-environnements"
                    sx={{ width: '100%', minHeight: '45px' }}
                    open={competenceOpen}
                    onChange={(event, newValue) => {
                      setCompetences(newValue)
                    }}
                    onKeyDown={(event, newInputValue) => {
                      clearTimeout(typingTimer)
                      if (event.target.value) {
                        typingTimer = setTimeout(function () {
                          getEnvironnements(event.target.value)
                        }, doneTypingInterval)
                      }
                    }}
                    onOpen={() => {
                      setCompetenceOpen(true)
                    }}
                    onClose={() => {
                      setCompetenceOpen(false)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.technology === value.technology
                    }
                    groupBy={(option) => option.title} // Grouper par "titre"
                    getOptionLabel={(option) => option.technology}
                    options={environnements}
                    loading={loadingCompetence}
                    renderTags={(environnements, getTagProps) =>
                      environnements.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.technology}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        id="text-asynchronous-environnements"
                        {...params}
                        sx={{ backgroundColor: '#fff' }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingCompetence ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />

                  {/* <Autocomplete
                    sx={{ width: '100%' }}
                    multiple
                    disableCloseOnSelect
                    options={environnementsTechniques}
                    defaultValue={competences}
                    groupBy={(option) => option.title} // Grouper par "titre"
                    getOptionLabel={(option) => option.technology}
                    onChange={(event, newValue) => {
                      setCompetences(newValue)
                    }}
                    renderTags={(competences, getTagProps) =>
                      competences.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option?.technology}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        sx={{ backgroundColor: '#fff' }}
                        {...params}
                        placeholder="Ajouter les environnements techniques"
                      />
                    )}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                  /> */}
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                  xs={12}
                  sm={12}
                  lg={12}
                >
                  <FormLabel className={'labelForm'}>Commentaires</FormLabel>
                  <TextareaInputClassic
                    placeholder={'Commentaires'}
                    register={{ ...register('commentaire') }}
                    type={'border'}
                  />
                  {errors.commentaire && (
                    <Typography className={'error-message'}>
                      {errors.commentaire.message}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </form>
          )}
        </BoxContainer>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={handleSubmit(id ? onEdit : onSubmit)}
            >
              {t('button.save')}
            </CustomButton>
          )}
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default EditInfosContact
